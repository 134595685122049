<template>
  <div>
    <b-card v-if="adList.length !== 0" class="ads-container" no-body>
      <b-carousel
        id="carousel-1"
        v-model="index"
        :interval="15000"
        controls
        indicators
        background="#ababab"
        style="text-shadow: 1px 1px 2px #333"
      >
        <a
          href=""
          v-for="ad in adList"
          :key="ad.val"
          @click="openInNewTab(ad.link)"
        >
          <b-carousel-slide :img-src="ad.url"></b-carousel-slide>
        </a>
      </b-carousel>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AdvertiseCard",
  component: {},
  data() {
    return {
      index: 0,
    };
  },
  computed: {
    ...mapGetters({
      getAds: "moduleMaster/getAds",
    }),
    displayAd() {
      return this.adList[this.index];
    },
    adList() {
      let formattedList = this.formatAdList(this.getAds) || [];
      this.shuffleArray(formattedList);
      return formattedList;
    },
  },
  methods: {
    ...mapActions({}),
    openInNewTab(url) {
      window.open(url, "_blank", "noreferrer");
    },
    formatAdList(list) {
      if (list?.length < 1) return;
      return list
        .filter((i) => i.desc)
        .map((i) => {
          return { ...i, url: i.desc, link: i.label };
        });
    },
    shuffleArray(array) {
      let currentIndex = array.length,
        randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex > 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      return array;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
.my-ads {
  max-height: 320px;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 767px) {
  .my-ads {
    max-height: 200px;
    height: 100%;
    object-fit: contain;
  }
}
</style>>