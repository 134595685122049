<template>
  <div>
    <!-- prop tabindex ต้องใส่เพื่อให้ เป็น interactive ไม่เช่นนั้นจะ trigger blur ไม่ได้ -->
    <i
      ref="favoriteNote"
      :tabindex="-1"
      class="fa fa-star text-muted"
      aria-hidden="true"
    ></i>

    <b-popover
      :target="() => $refs.favoriteNote"
      triggers="click blur"
      custom-class="p-0"
    >
      <b-button class="" size="xs" @click="openEditModal" block
        ><i class="fas fa-edit">
          {{
            arrayList.length === 0 ? "เพิ่มโน๊ตใช้ประจำ" : "แก้ไขโน๊ตใช้ประจำ"
          }}</i
        ></b-button
      >
      <b-list-group class="h-100">
        <b-list-group-item
          v-show="arrayList.length < 1"
          class="p-1 description ws-pre"
          button
          disabled
          >โน๊ตใช้ประจำ...</b-list-group-item
        >
        <b-list-group-item
          class="p-1 description ws-pre"
          v-for="(list, index) in arrayList"
          :key="index"
          button
          @click="handleClicked(list)"
          >{{ list }}</b-list-group-item
        >
      </b-list-group>
    </b-popover>
    <EditFavNoteModal
      ref="editFavNoteModal"
      :storageName="storageName"
      @updateList="arrayList = $event"
    />
  </div>
</template>

<script>
import EditFavNoteModal from "@/components/modal/EditFavNote";

export default {
  name: "FavouriteNoteButton",
  components: { EditFavNoteModal },
  props: {
    storageName: String,
  },

  data() {
    return {
      selected: "",
      arrayList: [],
    };
  },
  computed: {
    parentStyle() {
      return `height: ${this.height}px`;
    },
  },
  methods: {
    handleClicked(e) {
      this.$emit("listClicked", e);
    },
    openEditModal() {
      this.$refs.editFavNoteModal.show();
    },
  },
  mounted() {
    let list = localStorage.getItem(this.storageName);
    this.arrayList = list ? JSON.parse(list) : [];
  },
};
</script>

<style>
</style>