<template>
  <b-modal
    modal-class="component-modal-check-in-list"
    id="AllCheckinModal"
    ref="AllCheckinModal"
    size="ul fluid"
    hide-footer
    no-close-on-backdrop
    @close="close"
    @hide="close"
    @hidden="setDefaultModal"
  >
    <template #modal-title>
      <b-row>
        <h5 class="ml-4 mt-2">คิวรอตรวจทั้งหมด</h5>
        <span>
          <b-form-checkbox
            class="text-muted pt-2 ml-3"
            switch
            size="sm"
            v-model="workingDoctors"
            >ดูเฉพาะแพทย์ที่ออกตรวจวันนี้</b-form-checkbox
          ></span
        >
      </b-row>
    </template>
    <div class="content">
      <div class="row no-gutters">
        <div
          class="col-6 col-lg-4 col-xl-3 px-1 d-flex"
          v-for="(checkIn, index) in filteredCheckInList"
          :key="'doctor-' + index"
        >
          <!-- table queue treatment -->
          <b-card no-body class="w-100">
            <b-card-header
              :style="{ backgroundColor: checkIn.doctor.doctorColor }"
            >
              <h6 class="text-center mb-0">{{ checkIn.doctor.fullName }}</h6>
            </b-card-header>
            <b-table
              class="mb-0 tx-queue-table"
              hover
              small
              sort-icon-left
              sort-by="checkinDt"
              sticky-header="100%"
              :items="getTableCheckInList(checkIn.appointments)"
              :fields="tableHeaders"
              head-variant="light"
              @row-clicked="onTableCheckInClick"
            >
              <template v-slot:cell(index)="row">{{ row.index + 1 }}.</template>
              <template v-slot:cell(start)="row">{{
                checkTimeAppointment(row.item)
              }}</template>
              <template v-slot:cell(checkinDt)="row">{{
                formatTime(row.item.checkinDt)
              }}</template>
              <template v-slot:cell(checkinStatus)="row">
                <span
                  :class="{
                    onTime: row.value == 1,
                    early: row.value == 2,
                    late: row.value == 3,
                    walkIn: row.value == 4,
                  }"
                >
                  {{ row.item.checkinStatusName }}
                </span>
              </template>
            </b-table>
            <div
              v-if="getTableCheckInList(checkIn.appointments).length == 0"
              class="text-muted text-center p-5 m-auto"
            >
              ไม่มีคิวรอตรวจ
            </div>
          </b-card>
        </div>
      </div>
    </div>
    <Loading v-if="isLoading"></Loading>
  </b-modal>
</template>

<script>
import { eventBus } from "@/main";
import Loading from "@/components/Loading";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { datetime, RRule, RRuleSet, rrulestr } from "rrule";
import "@/theme/modal/CheckInList.scss";

export default {
  name: "AllCheckinModal",
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      dateDefault: moment().format("YYYY-MM-DD"),
      tableHeaders: [
        {
          key: "index",
          label: "",
          class: "text-center",
        },
        {
          label: "คิวรอตรวจ",
          sortable: false,
          key: "title",
          thStyle: { width: "46%" },
        },
        {
          label: "สถานะ",
          sortable: true,
          key: "checkinStatus",
          thStyle: { minWidth: "50px" },
        },
        {
          label: "เวลานัด",
          sortable: true,
          key: "start",
          thStyle: { minWidth: "35px" },
        },
        {
          label: "เวลามา",
          sortable: true,
          key: "checkinDt",
          thStyle: { minWidth: "35px" },
        },
      ],
      doctorsList: [],
      sortedCheckInList: [],
      workingDoctors: false,
    };
  },
  watch: {
    dateDefault: function (date) {
      this.loadAllCheckInList();
    },
  },
  computed: {
    ...mapGetters({
      allCheckInList: "moduleAppointment/getAllCheckInList",
      appointmentBy: "moduleAppointment/getDoctorAppointmentBy",
      isAccessMenu: "moduleUser/getIsAccessMenu",
      getDoctorList: "moduleUser/getDoctorList",
    }),
    filteredCheckInList() {
      if (!this.workingDoctors) return this.allCheckInList;
      let doctorsUid = this.getDoctorList
        .map(({ uid, doctorWorkingHours }) => {
          if (
            doctorWorkingHours?.some((i) => {
              return this.checkRruleIfSameDay(i.rruleStr);
            })
          ) {
            return uid;
          } else {
            return;
          }
        })
        .filter((i) => i);
      return this.allCheckInList.filter((i) => {
        return doctorsUid.includes(i.doctor.uid);
      });
    },
  },
  methods: {
    ...mapActions({
      fetchAllCheckInList: "moduleAppointment/fetchAllCheckInList",
    }),
    checkTimeAppointment(data) {
      return data.checkinStatus != "04" ? this.formatTime(data.start) : "-";
    },
    formatTime(date) {
      return moment(date).format("HH:mm");
    },
    loadAllCheckInList(callback) {
      this.isLoading = true;
      this.fetchAllCheckInList({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        date: this.dateDefault,
      })
        .then((res) => {
          this.setBgColor();

          if (callback) {
            callback();
          }
        })
        .catch((err) => {
          console.err(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getTableCheckInList(appointments) {
      if (appointments.length === 0) {
        return [];
      } else {
        return appointments.map((item) => {
          return {
            id: item.id,
            title:
              item.extendedProps.patientName +
              "\n" +
              item.extendedProps.treatmentTitle,
            nameTh: item.extendedProps.patientName,
            dn: item.extendedProps.dn,
            txTitle: item.extendedProps.treatmentTitle,
            start: item.start,
            checkinDt: item.extendedProps.checkin.checkinDt,
            checkinStatus: item.extendedProps.checkin.checkinStatus,
            checkinStatusName: item.extendedProps.checkin.checkinStatusName,
            checkinNote: item.extendedProps.checkin.checkinNote,
            appointment: item,
            _rowVariant: item._rowVariant,
          };
        });
      }
    },
    onTableCheckInClick(row, index) {
      eventBus.$emit("openPaymentHistoryModal", row.appointment);
    },
    show(doctorsList) {
      if (
        this.isAccessMenu(["ASSISTANT", "COUNTER", "MANAGER", "HEADCOUNTER"])
      ) {
        this.doctorsList = doctorsList;
        this.loadAllCheckInList(() => {
          this.$bvModal.show("AllCheckinModal");
        });
      } else {
        console.log("Not allow to use this feature !");
      }
    },
    close() {
      this.setDefaultModal();
    },
    setDefaultModal() {
      this.loading = false;
    },
    setBgColor() {
      this.allCheckInList.forEach((doctor) => {
        doctor.appointments.forEach((apt) => {
          let diff = moment().diff(
            apt.extendedProps.checkin.checkinDt,
            "minutes"
          );
          if (diff <= 8) {
            apt._rowVariant = "green";
          } else if (diff <= 15) {
            apt._rowVariant = "lightGreen";
          } else if (diff <= 30) {
            apt._rowVariant = "yellow";
          } else if (diff <= 40) {
            apt._rowVariant = "lightRed";
          } else if (diff <= 45) {
            apt._rowVariant = "red";
          } else {
            apt._rowVariant = "purple";
          }
        });
      });
    },
    checkRruleIfSameDay(rruleStr) {
      let rule = RRule.fromString(rruleStr);
      let now = moment();

      let y = now.year();
      let m = now.month() + 1;
      let d = now.date();
      let checkDate = datetime(y, m, d);

      let nextOccurrence = rule.after(checkDate, true); // next rule date including today
      let match = moment(nextOccurrence).isSame(now, "day"); // check if 'DAY' is same

      return match;
    },
  },
};
</script>

<style scoped>
@media (min-width: 1400px) {
  .col-ul-2 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
</style>