<template>
  <b-modal
    modal-class="component-modal-cancel-payement"
    id="CancelPayment"
    ref="CancelPayment"
    title="ยกเลิกส่งชำระเงิน / ส่งให้แพทย์ท่านอื่นรักษาต่อ"
    size="md fluid"
    hide-footer
    @hidden="defaultValue"
  >
    <div class="row">
      <!-- <div
        class="
          col-12 col-lg-4
          divider-lg-right divider-bottom divider-lg-bottom-0
          mb-3 mb-lg-0
        "
      >
        <PatientProfile :patient="patient" />
      </div> -->
      <div class="col">
        <b-row>
          <b-col>
            <b-form-group label="แพทย์:" label-align="right" label-cols="3">
              <b-form-select
                class=""
                :class="{ 'is-invalid': $v.doctor.$error }"
                id="doctor"
                v-model="$v.doctor.$model"
                :options="getDoctorList"
                value-field="uid"
                text-field="fullName"
                @change="valueChanged"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled
                    >เลือกแพทย์</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <div v-if="!isSameDoctor">
          <b-row>
            <b-col>
              <b-form-group label="ห้อง:" label-align="right" label-cols="3">
                <b-form-select
                  class=""
                  :class="{ 'is-invalid': $v.roomId.$error }"
                  id="resource"
                  v-model="$v.roomId.$model"
                  :options="getRoomList"
                  value-field="id"
                  text-field="title"
                  @change="valueChanged"
                  :disabled="isSameDoctor"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled
                      >เลือกห้อง</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="เพื่อ:" label-align="right" label-cols="3">
                <h6
                  v-if="appointment.treatment"
                  class="pt-2"
                  v-html="appointment.treatment"
                ></h6>
                <h6 v-else class="pt-2">-</h6>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-textarea
            id="textarea"
            class="mb-3"
            v-model="note"
            :disabled="isSameDoctor"
            :placeholder="
              isSameDoctor ? '' : 'ส่งข้อความให้ห้องตรวจ เช่น สียาง'
            "
            rows="3"
            max-rows="3"
          ></b-form-textarea>
        </div>

        <b-row>
          <b-col class="d-flex justify-content-end">
            <b-button
              v-if="isSameDoctor"
              class=""
              variant="warning"
              @click="cancelPayment"
              >ยกเลิกส่งชำระเงิน</b-button
            >
            <b-button
              v-else
              class=""
              variant="warning"
              @click="handleReferClicked"
              >ส่งให้แพทย์ท่านอื่น</b-button
            >
          </b-col>
        </b-row>
      </div>
    </div>
    <template v-slot:modal-footer> </template>
    <Loading v-if="isLoading"></Loading>
    <Dialog ref="Dialog"></Dialog>
  </b-modal>
</template>

<script>
import Loading from "@/components/Loading";
import Dialog from "@/components/modal/Dialog";

import moment from "moment";

import { required } from "vuelidate/lib/validators";

import { mapActions, mapGetters } from "vuex";
import $ from "jquery";

export default {
  name: "CancelPayment",
  components: {
    Loading,
    Dialog,
  },
  data() {
    return {
      isLoading: false,
      patient: {},
      appointment: {},
      doctor: null,
      roomId: null,
      note: "",
      indent: "130px",
      edited: false,

      rawAppointment: null,
    };
  },
  validations: {
    doctor: { required },
    roomId: { required },
  },
  computed: {
    ...mapGetters({
      appointmentList: "moduleAppointment/getQueueAppointmentList",
      getBranchInfo: "moduleUser/getBranchInfo",
      getRoomList: "moduleUser/getRoomList",
      getRoomListBy: "moduleUser/getRoomListBy",
      getDoctorList: "moduleUser/getDoctorList",
      viewAvatar: "moduleContent/viewAvatar",
      getDoctorListBy: "moduleUser/getDoctorListBy",
    }),
    isSameDoctor() {
      return this.appointment.doctorId === this.doctor;
    },
  },
  created() {
    this.init();
  },
  watch: {
    // appointmentDetail() {
    //   this.appointment = {
    //     id: this.appointmentDetail.id,
    //     doctorName: this.appointmentDetail.extendedProps.doctorName,
    //     patientName: this.formatPtFullname(this.patient),
    //     roomId: this.appointmentDetail.extendedProps.roomId,
    //     treatment: this.appointmentDetail.extendedProps.treatmentTitle,
    //     callback: (data) => {
    //       if (data) {
    //         const fcTime = $("#appointment-temp .fc-time")[0];
    //         if (fcTime) {
    //           fcTime.style.backgroundColor = data.statusColor;
    //         }
    //       }
    //     },
    //   };
    // },
  },
  methods: {
    ...mapActions({
      cancelWaitingForPaymentAppointment:
        "moduleAppointment/cancelWaitingForPaymentAppointment",
      confirmQueueCheckIn: "moduleAppointment/confirmQueueCheckIn",
      cancelQueueCheckIn: "moduleAppointment/cancelQueueCheckIn",
      referAppointment: "moduleAppointment/referAppointment",
    }),
    init() {},
    show(appointment) {
      if (!appointment) return;

      let aptDetail = appointment.extendedProps;
      this.rawAppointment = appointment;
      this.patient = aptDetail.patient;
      // if (index === null && !appointment?.id) {
      //   this.patient = patient;
      // this.roomId = aptDetail?.roomId ?? null;
      this.doctor = aptDetail?.doctor ?? null;
      // } else {
      //   let eventDetail;
      //   typeof index === "number"
      //     ? (eventDetail = this.appointmentList[index])
      //     : (eventDetail = appointment);
      // this.appointmentDetail = eventDetail;
      //   let eventProps = eventDetail.extendedProps;
      //   this.patient = eventProps.patient;

      this.appointment = {
        id: appointment.id,
        doctorId: aptDetail.doctor,
        doctorName: aptDetail.doctorName,
        patientName: this.formatPtFullname(this.patient),
        roomId: aptDetail.roomId,
        treatment: aptDetail.treatmentTitle,
        callback: (data) => {
          if (data) {
            const fcTime = $("#appointment-temp .fc-time")[0];
            if (fcTime) {
              fcTime.style.backgroundColor = data.statusColor;
            }
          }
        },
      };

      //   this.roomId = this.appointmentDetail.extendedProps.roomId;
      //   this.doctor = this.appointmentDetail.extendedProps.doctor;
      // }
      this.$bvModal.show("CancelPayment");
      // this.$v.$reset();
    },
    formatPtFullname(patient) {
      return patient
        ? `${patient.titleNameTh} ${patient.firstNameTh} ${patient.lastNameTh}`
        : "-";
    },
    cancelPayment() {
      this.isLoading = true;
      this.cancelWaitingForPaymentAppointment({
        id: this.appointment.id,
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
      }).then(() => {
        this.isLoading = false;
        this.$bvModal.hide("CancelPayment");
      });
    },
    handleReferClicked() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$refs.Dialog.showAlertConfirm(
        "ส่งคนไข้ต่อ ?",
        `ส่ง ${this.formatPtFullname(this.patient)} ให้แพทย์ ${
          this.getDoctorListBy(this.doctor, "uid")?.fullName
        }`
      ).then((result) => {
        if (result.isConfirmed) {
          this.referPatient();
        }
      });
    },
    async referPatient() {
      this.isLoading = true;
      let event = {
        id: Number(this.appointment.id),
        doctorUid: this.doctor,
        roomId: this.roomId,
        startDt: this.rawAppointment.start,
        endDt: this.rawAppointment.end,
        note: this.note,
      };
      try {
        await this.referAppointment({
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
          event,
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
        this.$bvModal.hide("CancelPayment");
      }
    },
    // async 

    valueChanged() {
      this.edited = true;
    },
    defaultValue() {
      this.patient = {};
      this.appointment = {};
      this.doctor = null;
      this.roomId = null;
      this.note = "";
      this.edited = false;
      this.rawAppointment = null;
    },

    //Utils
  },
  mounted() {},
};
</script>

<style>
</style>
