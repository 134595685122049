<template>
  <b-modal
    id="verifyDeleteOverdue"
    ref="verifyDeleteOverdue"
    size="sm"
    centered
    hide-header
    title="ลบรายการค้างชำระ"
    variant="danger"
    @hidden="defaultValue"
  >
    <div>
      <b-form
        id="verifyForm"
        ref="verifyForm"
        @submit.stop.prevent="handleDeleteOverdue"
      >
        <b-form-group label="Password:">
          <b-form-input
            placeholder="กรุณาใส่พาสเวิร์ด"
            required
            type="password"
            autocomplete="new-password"
            v-model="password"
          ></b-form-input>
        </b-form-group>
      </b-form>
    </div>
    <template #modal-footer>
      <b-button
        variant="danger"
        class="float-right"
        type="submit"
        form="verifyForm"
      >
        ลบรายการค้างชำระ
      </b-button>
    </template>
    <Dialog ref="Dialog"></Dialog>

    <Loading v-if="isLoading"></Loading>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loading from "@/components/Loading";
import Dialog from "@/components/modal/Dialog";

export default {
  name: "VerifyDeleteOverdue",
  components: {
    Loading,
    Dialog,
  },
  props: {
    afterDelete: Function,
  },
  data() {
    return {
      isLoading: false,
      overdueObj: {},
      password: null,
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "moduleUser/getUserInfo",
    }),
  },
  watch: {},
  methods: {
    ...mapActions({
      verifyUser: "moduleUser/verifyUser",
      deleteOverdueItem: "moduleTreatment/deleteOverdueItem",
    }),
    show(overdueObj) {
      this.overdueObj = overdueObj ?? {};
      this.$bvModal.show("verifyDeleteOverdue");
    },
    close() {
      this.$bvModal.hide("verifyDeleteOverdue");
    },
    handleVerify() {
      this.isLoading = true;
      this.verifyUser({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        username: this.userInfo.email,
        password: this.password,
      })
        .then((res) => {
          this.isLoading = false;
          if (res.data.success) {
            this.handleDeleteOverdue();
          } else {
            this.$refs.Dialog.showToast("danger", "รหัสผ่านไม่ถูกต้อง");
          }
        })
        .catch((err) => {
          console.error(err);
          this.isLoading = false;
        });
    },
    handleDeleteOverdue() {
      const clinicUrl = this.$route.params.clinicUrl;
      const branchUrl = this.$route.params.branchUrl;
      const event = this.overdueObj;
      this.isLoading = true;
      this.deleteOverdueItem({
        event,
        clinicUrl,
        branchUrl,
        password: this.password,
        reason: this.reason,
      })
        .then((res) => {
          if (this.afterDelete) {
            this.close();
            this.afterDelete();
          }
        })
        .catch((err) => {
          if (err.response.data.errorMessage === "incorrect password") {
            this.$refs.Dialog.showToast("danger", "รหัสผ่านไม่ถูกต้อง");
            return;
          }
          console.error("Error:", err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    defaultValue() {
      this.password = null;
      this.overdueObj = {};
      this.reason = "";
    },
  },
};
</script>

<style>
</style>