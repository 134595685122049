import moment from "moment";
import { datetime, RRule, RRuleSet, rrulestr } from "rrule";

const checkRruelIfSameDay = ({ dateObj, rruleStr }) => {
  if (!dateObj._isAMomentObject) throw new Error("Required Moment Object");
  let rule = RRule.fromString(rruleStr);

  let y = dateObj.year();
  let m = dateObj.month() + 1;
  let d = dateObj.date();
  let checkDate = datetime(y, m, d);

  let nextOccurrence = rule.after(checkDate, true); // next rule date including today
  let match = moment(nextOccurrence).isSame(dateObj, "day"); // check if 'DAY' is same

  return match;
};

export default checkRruelIfSameDay;
