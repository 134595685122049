<template>
  <div>
    <b-table
      id="treatmentTable"
      head-variant="light"
      hover
      small
      :busy="tableBusy"
      :items="items"
      :fields="fields"
      v-model="tableItems"
      @row-clicked="handleRowClicked"
      responsive
    >
      <template v-slot:cell(index)="row"> {{ row.index + 1 }}. </template>
      <template v-slot:cell(buttons)="data">
        <b-button
          v-if="mode !== 'edit'"
          class="show-when-hovered"
          size="xs"
          variant="outline-danger"
          @click="deleteItem(data.index, data.item)"
        >
          <i class="fas fa-trash-alt"></i>
        </b-button>
      </template>
      <template v-slot:cell(actionUid)="data">{{
        getUserListBy(data.item.actionUid, "uid").fullName
      }}</template>
      <template v-slot:table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
    </b-table>
    <b-table
      v-if="overdueList.length > 0"
      class="small-text"
      table-variant="warning"
      small
      :items="overdueList"
      :fields="overdueFields"
      responsive
    >
      <template v-slot:cell(index)="row"> {{ row.index + 1 }}. </template>
      <template v-slot:cell(name)="row"
        ><div class="font-weight-bold">
          {{ formatDate(row.item.creationDt) }}
        </div>
        <div>
          {{ row.value }}
        </div>
      </template>
      <template #head(price)
        ><span>ราคา</span>
        <span class="text-danger">(ค้าง)</span>
      </template>
      <template v-slot:cell(price)="row">
        <div>{{ row.value }}</div>
        <div class="text-danger">({{ row.item.price - row.item.paid }})</div>
      </template>
      <template v-slot:cell(actionUid)="data">{{
        getUserListBy(data.item.actionUid, "uid").fullName
      }}</template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/main";
import numeral from "numeral";
import moment from "moment";

import "@/theme/modal/PaymentHistory.scss";

export default {
  name: "SummaryTable",
  components: {},
  props: ["items", "indexEdit", "tableBusy", "overdueList", "mode"],
  data() {
    return {
      tableItems: [],

      fields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "name",
          label: "รายการวันนี้",
          sortable: false,
          class: "narrow-spacing",
        },
        // {
        //   key: "quantity",
        //   label: "จำนวน",
        //   sortable: false
        // },
        {
          key: "price",
          label: "ราคา",
          sortable: false,
          class: "text-right narrow-spacing",
        },
        {
          key: "actionUid",
          label: "แพทย์",
          sortable: false,
          class: "narrow-spacing",
        },
        {
          key: "buttons",
          label: "",
          sortable: false,
        },
      ],
      overdueFields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "name",
          label: "รายการค้างชำระ",
          sortable: false,
          class: "narrow-spacing",
        },
        // {
        //   key: "quantity",
        //   label: "จำนวน",
        //   sortable: false
        // },
        {
          key: "price",
          label: "ราคา(ค้าง)",
          sortable: false,
          class: "text-right narrow-spacing",
        },
        {
          key: "actionUid",
          label: "แพทย์",
          sortable: false,
          class: "narrow-spacing",
        },
      ],
    };
  },

  created() {
    this.init();
  },
  mounted() {},
  watch: {
    indexEdit(val) {
      if (val == -1) this.resetRowVariant();
    },
  },
  computed: {
    ...mapGetters({
      getDoctorList: "moduleUser/getDoctorList",
      getDoctorListBy: "moduleUser/getDoctorListBy",
      getUserInfo: "moduleUser/getUserInfo",
      getUserList: "moduleUser/getUserList",
      getUserListBy: "moduleUser/getUserListBy",
      isAccessMenu: "moduleUser/getIsAccessMenu",

      authorization: "moduleUser/getAuthorization",
      isDoctor: "moduleUser/isDoctor",

      getPatientOrthoPackageItemBy:
        "moduleTreatment/getPatientOrthoPackageItemBy",

    }),
  },
  methods: {
    ...mapActions({
      fetchOrthoPackageUserList: "moduleTreatment/fetchOrthoPackageUserList",
      fetchTreatmentByApptId: "moduleTreatment/fetchTreatmentByApptId",
      updateStateOrthoPackageItem:
        "moduleTreatment/updateStateOrthoPackageItem",
    }),
    init() {},
    // Utils
    checkDoctorColumn() {
      var index = this.fields.findIndex(function (event) {
        return event.key == "actionUid";
      });
      if (index >= 0) {
        this.fields[index].thClass = "d-none";
        this.fields[index].tdClass = "d-none";
      } else {
        this.fields[index].thClass = "";
        this.fields[index].tdClass = "";
      }
    },
    getToothSurfacesPopover(toothSurfaces) {
      this.toothSurfaceList = [];
      if (toothSurfaces.length > 0) {
        toothSurfaces.forEach((res) => {
          this.toothSurfaceList.push(this.getToothSurfaceBy(res, "id"));
        });
      }
    },
    hideToothSurfacesPopover() {
      this.$root.$emit("bv::hide::popover", "toothSurfacesPop");
    },
    vReset() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$v.$reset();
        });
      });
    },
    formatNumber(val) {
      return numeral(val).format("0,0");
    },
    openProfileCustomerModal() {
      this.$parent.openProfileCustomerModal(
        this.appointment,
        this.patient,
        true //hide-footer
      );
    },
    deleteItem(index, data) {
      if (data.typeId == 2) {
        this.setUpdateOrthoPackageItem(data.orthoPackageItemId, false, null);
      }
      this.tableItems.splice(index, 1);
      this.$emit("update:items", this.tableItems);
      this.resetEditItem();
    },
    setUpdateOrthoPackageItem(id, status, balance) {
      let item = this.getPatientOrthoPackageItemBy(id, "id");
      if (!item) return;
      item.disabled = status;
      item.balance = balance;
      this.updateStateOrthoPackageItem(item);
    },
    handleRowClicked(row, index) {
      if (this.indexEdit === index) {
        this.resetEditItem();
        return;
      }
      this.resetRowVariant();
      this.tableItems[index]._rowVariant = "info";
      this.emitEditItem(row, index);
    },

    resetRowVariant() {
      this.tableItems.forEach((item) => this.$set(item, "_rowVariant", ""));
    },
    resetEditItem() {
      this.resetRowVariant();
      this.resetIndexEdit();
    },
    resetIndexEdit() {
      this.$emit("update:indexEdit", -1);
    },
    emitEditItem(item, index) {
      this.$emit("editItem", item, index);
    },

    formatDate(date) {
      if (date) {
        return moment(date).locale("th").format("D MMM YY");
      } else {
        return "";
      }
    },
  },
};
</script>

<style>
</style>