<template>
  <b-modal
    modal-class="component-modal-payment-history"
    id="PaymentHistoryModal"
    ref="PaymentHistoryModal"
    size="fluid"
    header-class="py-2"
    no-close-on-backdrop
    no-close-on-esc
    scrollable
    hide-footer
    @close="close"
    @hide="enableBodyScroll"
    static
  >
    <template #modal-title>
      บันทึกการรักษา
      <span>
        <b-button
          v-if="isTypingMode"
          variant="outline-info"
          size="sm"
          @click="switchMode"
        >
          <i class="fas fa-keyboard"></i> Typing Mode
        </b-button>
        <b-button
          v-else
          size="sm"
          variant="outline-primary"
          @click="switchMode"
        >
          <i class="fas fa-pencil-alt"></i> Writing Mode
        </b-button>
      </span>
    </template>
    <b-collapse v-model="isTypingMode" class>
      <div v-if="isTypingMode" class="row">
        <div class="col-12 col-lg-2">
          <PatientProfile
            :patient="patient"
            button-name="ประวัติคนไข้"
            :button-click="
              () => {
                openProfileCustomerModal();
              }
            "
          />
        </div>
        <div class="col-12 d-block d-lg-none">
          <b-card v-if="checkinData.id" no-body class="p-2">
            <b-table-simple
              class="mb-0"
              small
              borderless
              head-variant="light"
              responsive
            >
              <b-thead>
                <b-tr>
                  <b-th>ข้อมูลส่งตรวจ</b-th>
                  <b-th>สถานะ</b-th>
                  <b-th>เวลานัด</b-th>
                  <b-th>เวลามาถึง</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td>{{ checkinData.checkinNote }}</b-td>
                  <b-td>
                    <span
                      :class="{
                        onTime: checkinData.checkinStatus == 1,
                        early: checkinData.checkinStatus == 2,
                        late: checkinData.checkinStatus == 3,
                        walkIn: checkinData.checkinStatus == 4,
                      }"
                      >{{ checkinData.checkinStatusName }}</span
                    >
                  </b-td>
                  <b-td>
                    {{ checkinData.start ? formatTime(checkinData.start) : "" }}
                  </b-td>
                  <b-td>
                    {{
                      checkinData.checkinDt
                        ? formatTime(checkinData.checkinDt)
                        : ""
                    }}
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card>
        </div>
        <div class="col">
          <b-card class="mb-0" no-body :bg-variant="bgVariant">
            <b-tabs v-model="tabIndex" card>
              <!-- tab การรักษา -->
              <b-tab title="การรักษา" no-body @click="indexEdit = -1">
                <TreatmentInputs
                  ref="treatmentTab"
                  :appointment="appointment"
                  :indexEdit.sync="indexEdit"
                  :mode="mode"
                  @addItem="handleAddItem"
                />
              </b-tab>

              <!-- tab จัดฟัน -->
              <b-tab
                :disabled="isNhso"
                title="แพ็กเกจจัดฟัน"
                no-body
                @click="indexEdit = -1"
              >
                <OrthoPackageInputs
                  ref="orthoPackageTab"
                  :appointment="appointment"
                  :patientData="patient"
                  :indexEdit.sync="indexEdit"
                  :items.sync="treatment.items"
                  :mode="mode"
                  @assignTabindex="handleAssignTabIndex"
                  @addItem="handleAddItem"
                />
              </b-tab>

              <!-- tab ยาและสินค้า  -->
              <b-tab
                title="ยา และสินค้า"
                :disabled="isNhso"
                no-body
                @click="indexEdit = -1"
              >
                <DrugsAndGoodsInputs
                  ref="DrugsAndGoodsTab"
                  :appointment="appointment"
                  :indexEdit.sync="indexEdit"
                  :mode="mode"
                  @addItem="handleAddItem"
                />
              </b-tab>
            </b-tabs>
          </b-card>
        </div>

        <!-- รายการรักษาที่แอดเข้ามา -->
        <div class="col-12 col-lg-4">
          <b-card v-if="checkinData.id" no-body class="p-2 d-none d-lg-block">
            <b-table-simple
              class="mb-0"
              small
              borderless
              head-variant="light"
              responsive
            >
              <b-thead>
                <b-tr>
                  <b-th>ข้อมูลส่งตรวจ</b-th>
                  <b-th>สถานะ</b-th>
                  <b-th>เวลานัด</b-th>
                  <b-th>เวลามาถึง</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td>{{ checkinData.checkinNote }}</b-td>
                  <b-td>
                    <span
                      :class="{
                        onTime: checkinData.checkinStatus == 1,
                        early: checkinData.checkinStatus == 2,
                        late: checkinData.checkinStatus == 3,
                        walkIn: checkinData.checkinStatus == 4,
                      }"
                      >{{ checkinData.checkinStatusName }}</span
                    >
                  </b-td>
                  <b-td>
                    {{ checkinData.start ? formatTime(checkinData.start) : "" }}
                  </b-td>
                  <b-td>
                    {{
                      checkinData.checkinDt
                        ? formatTime(checkinData.checkinDt)
                        : ""
                    }}
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card>
          <b-card no-body class="p-2">
            <SummaryTable
              ref="summaryTable"
              :tableBusy="summaryTableBusy"
              :items.sync="treatment.items"
              :overdueList="overdueList"
              :indexEdit.sync="indexEdit"
              :mode="mode"
              @editItem="assignEditItem"
            />

            <div id="save-button-wrapper">
              <div v-if="mode === 'edit'" class>
                <b-button
                  variant="warning"
                  block
                  :disabled="
                    summaryTableBusy || indexEdit != -1 || this.isItemsClean
                  "
                  @click="editTreatmentHistory"
                >
                  ยืนยันแก้ไขประวัติการรักษา
                </b-button>
                <p v-if="indexEdit != -1" class="description text-danger my-1">
                  *กรุณาแก้ไขรายการให้เสร็จสิ้น
                </p>
                <p v-else class="description text-warning my-1">
                  *กรุณาเลือกรายการรักษาที่ต้องการแก้ไข
                </p>
              </div>
              <b-row v-else-if="isDashboardRoute" class="px-3">
                <b-col class="px-1">
                  <b-button
                    class
                    block
                    :disabled="summaryTableBusy || indexEdit != -1"
                    size="sm"
                    @click="debouncedCreateTreatment('paymentQueue')"
                    >ส่งชำระเงิน</b-button
                  >
                  <p class="description text-muted mb-0 mx-1">
                    บันทึก และส่งชำระเงินโดยไม่ต้องนัดหมาย
                  </p>
                </b-col>
                <b-col class="px-1">
                  <b-button
                    variant="primary"
                    block
                    size="sm"
                    :disabled="summaryTableBusy || indexEdit != -1"
                    @click="debouncedCreateTreatment"
                  >
                    {{
                      treatment.items.length === 0
                        ? "ไปหน้า Next visit"
                        : "บันทึก + Next visit"
                    }}
                  </b-button>
                  <p class="description text-muted mb-0 mx-1">
                    บันทึกแล้วกำหนด Next Visit ก่อนส่งชำระเงิน
                  </p>
                </b-col>
                <b-col cols="12">
                  <p
                    v-if="indexEdit != -1"
                    class="description text-danger my-1"
                  >
                    *กรุณาแก้ไขรายการให้เสร็จสิ้น
                  </p>
                </b-col>
              </b-row>
              <div v-else class>
                <b-button
                  variant="primary"
                  block
                  :disabled="summaryTableBusy || indexEdit != -1"
                  @click="debouncedCreateTreatment"
                >
                  {{
                    treatment.items.length === 0
                      ? "ไปหน้าชำระเงิน"
                      : "บันทึก และชำระเงิน"
                  }}
                </b-button>
                <p v-if="indexEdit != -1" class="description text-danger my-1">
                  *กรุณาแก้ไขรายการให้เสร็จสิ้น
                </p>
              </div>
            </div>
          </b-card>
          <NHSOAnnouceCard :extendedProps="extendedProps" />
        </div>
      </div>
    </b-collapse>
    <b-collapse v-model="isWritingMode" class>
      <b-row v-if="isWritingMode" class="small-text">
        <b-col cols="12" lg="4">
          <b-row>
            <b-col md="6" lg="12" class="d-block d-md-none d-lg-block">
              <b-card v-if="patient" no-body class="p-2">
                <b-row>
                  <b-col cols="4">
                    <b-avatar
                      :variant="patient.contentId ? 'white' : 'info'"
                      :text="abbreviation()"
                      alt="User Image"
                      size="60px"
                      :src="viewFile(patient.contentId)"
                      square
                      rounded
                    ></b-avatar>
                  </b-col>
                  <b-col>
                    <b-button
                      class="float-right"
                      size="sm"
                      @click="openProfileCustomerModal"
                    >
                      <i class="fas fa-notes-medical"></i>
                    </b-button>
                    <p class="mb-0 text-bold narrow-spacing">
                      DN: {{ patient.dn }}
                      <CopyToClipboard :text="patient.dn" />
                    </p>
                    <p
                      v-if="patient.existDn"
                      class="mb-1 text-bold text-muted description narrow-spacing"
                    >
                      DN(เดิม): {{ patient.existDn }}
                      <CopyToClipboard :text="patient.existDn" />
                    </p>
                    <p class="mb-1">{{ fullName() }}</p>
                    <p
                      v-if="patient.congenitalDisease"
                      class="text-danger mb-1"
                    >
                      โรคประจำตัว:{{ patient.congenitalDisease }}
                    </p>
                    <p v-if="patient.allergic" class="text-danger mb-0">
                      แพ้ยา: {{ patient.allergic }}
                    </p>
                  </b-col>
                </b-row>
              </b-card>
              <b-card v-if="checkinData.id" no-body class="p-2">
                <b-table-simple
                  class="mb-0"
                  small
                  borderless
                  head-variant="light"
                  responsive
                >
                  <b-thead>
                    <b-tr>
                      <b-th>ข้อมูลส่งตรวจ</b-th>
                      <b-th>สถานะ</b-th>
                      <b-th>เวลานัด</b-th>
                      <b-th>เวลามาถึง</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-td>{{ checkinData.checkinNote }}</b-td>
                      <b-td>
                        <span
                          :class="{
                            onTime: checkinData.checkinStatus == 1,
                            early: checkinData.checkinStatus == 2,
                            late: checkinData.checkinStatus == 3,
                            walkIn: checkinData.checkinStatus == 4,
                          }"
                          >{{ checkinData.checkinStatusName }}</span
                        >
                      </b-td>
                      <b-td>
                        {{
                          checkinData.start ? formatTime(checkinData.start) : ""
                        }}
                      </b-td>
                      <b-td>
                        {{
                          checkinData.checkinDt
                            ? formatTime(checkinData.checkinDt)
                            : ""
                        }}
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-card>
            </b-col>
            <b-col md="6" lg="12">
              <b-card no-body :bg-variant="bgVariant">
                <b-tabs v-model="tabIndex" card nav-wrapper-class="pt-2">
                  <!-- tab การรักษา -->
                  <b-tab title="การรักษา" no-body @click="indexEdit = -1">
                    <TreatmentInputs
                      ref="treatmentTab"
                      :appointment="appointment"
                      :indexEdit.sync="indexEdit"
                      @addItem="handleAddItem"
                      :writingMode="true"
                      :mode="mode"
                      size="sm"
                    />
                  </b-tab>

                  <!-- tab จัดฟัน -->
                  <b-tab
                    :disabled="isNhso"
                    s
                    title="แพ็กเกจจัดฟัน"
                    no-body
                    @click="indexEdit = -1"
                  >
                    <OrthoPackageInputs
                      ref="orthoPackageTab"
                      :appointment="appointment"
                      :patientData="patient"
                      :indexEdit.sync="indexEdit"
                      :items.sync="treatment.items"
                      @assignTabindex="handleAssignTabIndex"
                      @addItem="handleAddItem"
                      :writingMode="true"
                      :mode="mode"
                      size="sm"
                    />
                  </b-tab>

                  <!-- tab ยาและสินค้า  -->
                  <b-tab
                    :disabled="isNhso"
                    title="ยา และสินค้า"
                    no-body
                    @click="indexEdit = -1"
                  >
                    <DrugsAndGoodsInputs
                      ref="DrugsAndGoodsTab"
                      :appointment="appointment"
                      :indexEdit.sync="indexEdit"
                      @addItem="handleAddItem"
                      :writingMode="true"
                      :mode="mode"
                      size="sm"
                    />
                  </b-tab>
                </b-tabs>
              </b-card>
            </b-col>
            <b-col md="6" lg="12" order="3" order-lg="3">
              <b-card
                v-if="patient"
                class="d-none d-md-block d-lg-none p-2"
                no-body
              >
                <b-row>
                  <b-col cols="4">
                    <b-avatar
                      :variant="patient.contentId ? 'white' : 'info'"
                      :text="abbreviation()"
                      alt="User Image"
                      size="60px"
                      :src="viewFile(patient.contentId)"
                      square
                      rounded
                    ></b-avatar>
                  </b-col>
                  <b-col>
                    <b-button
                      class="float-right"
                      size="sm"
                      @click="openProfileCustomerModal"
                    >
                      <i class="fas fa-notes-medical"></i>
                    </b-button>
                    <p class="mb-0 text-bold narrow-spacing">
                      DN: {{ patient.dn }}
                      <CopyToClipboard :text="patient.dn" />
                    </p>
                    <p
                      v-if="patient.existDn"
                      class="mb-1 text-bold text-muted description narrow-spacing"
                    >
                      DN(เดิม): {{ patient.existDn }}
                      <CopyToClipboard :text="patient.existDn" />
                    </p>
                    <p class="mb-1">{{ fullName() }}</p>
                    <p
                      v-if="patient.congenitalDisease"
                      class="text-danger mb-1"
                    >
                      โรคประจำตัว:{{ patient.congenitalDisease }}
                    </p>
                    <p v-if="patient.allergic" class="text-danger mb-0">
                      แพ้ยา: {{ patient.allergic }}
                    </p>
                  </b-col>
                </b-row>
              </b-card>
              <b-card
                v-if="checkinData.id"
                no-body
                class="p-2 d-none d-md-block d-lg-none"
              >
                <b-table-simple
                  class="mb-0"
                  small
                  borderless
                  head-variant="light"
                  responsive
                >
                  <b-thead>
                    <b-tr>
                      <b-th>ข้อมูลส่งตรวจ</b-th>
                      <b-th>สถานะ</b-th>
                      <b-th>เวลานัด</b-th>
                      <b-th>เวลามาถึง</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-td>{{ checkinData.checkinNote }}</b-td>
                      <b-td>
                        <span
                          :class="{
                            onTime: checkinData.checkinStatus == 1,
                            early: checkinData.checkinStatus == 2,
                            late: checkinData.checkinStatus == 3,
                            walkIn: checkinData.checkinStatus == 4,
                          }"
                          >{{ checkinData.checkinStatusName }}</span
                        >
                      </b-td>
                      <b-td>
                        {{
                          checkinData.start ? formatTime(checkinData.start) : ""
                        }}
                      </b-td>
                      <b-td>
                        {{
                          checkinData.checkinDt
                            ? formatTime(checkinData.checkinDt)
                            : ""
                        }}
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-card>
              <b-card no-body class="p-2">
                <SummaryTable
                  ref="summaryTable"
                  :tableBusy="summaryTableBusy"
                  :items.sync="treatment.items"
                  :indexEdit.sync="indexEdit"
                  :overdueList="overdueList"
                  :mode="mode"
                  @editItem="assignEditItem"
                  size="sm"
                />
                <div id="save-button-wrapper">
                  <div v-if="mode === 'edit'" class>
                    <b-button
                      variant="warning"
                      block
                      :disabled="
                        summaryTableBusy || indexEdit != -1 || this.isItemsClean
                      "
                      @click="editTreatmentHistory"
                    >
                      ยืนยันแก้ไขประวัติการรักษา
                    </b-button>
                    <p
                      v-if="indexEdit != -1"
                      class="description text-danger my-1"
                    >
                      *กรุณาแก้ไขรายการให้เสร็จสิ้น
                    </p>
                  </div>
                  <div v-else-if="isDashboardRoute" class>
                    <b-row class="mx-1">
                      <b-col class="px-1">
                        <b-button
                          class
                          block
                          :disabled="summaryTableBusy || indexEdit != -1"
                          size="sm"
                          @click="debouncedCreateTreatment('paymentQueue')"
                          >ส่งชำระเงิน</b-button
                        >
                        <p class="description text-muted mb-0 mx-1">
                          บันทึก และส่งชำระเงินโดยไม่ต้องนัดหมาย
                        </p>
                      </b-col>
                      <b-col class="px-1">
                        <b-button
                          variant="primary"
                          block
                          size="sm"
                          :disabled="summaryTableBusy || indexEdit != -1"
                          @click="debouncedCreateTreatment"
                        >
                          {{
                            treatment.items.length === 0
                              ? "ไปหน้า Next visit"
                              : "บันทึก + Next visit"
                          }}
                        </b-button>
                        <p class="description text-muted mb-0 mx-1">
                          บันทึกแล้วกำหนด Next Visit ก่อนส่งชำระเงิน
                        </p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <p
                          v-if="indexEdit != -1"
                          class="description text-danger my-1 mx-1"
                        >
                          *กรุณาแก้ไขรายการให้เสร็จสิ้น
                        </p>
                      </b-col>
                    </b-row>
                  </div>
                  <div v-else class>
                    <b-button
                      variant="primary"
                      block
                      size="sm"
                      :disabled="summaryTableBusy || indexEdit != -1"
                      @click="debouncedCreateTreatment"
                    >
                      {{
                        treatment.items.length === 0
                          ? "ไปหน้าชำระเงิน"
                          : "บันทึก และชำระเงิน"
                      }}
                    </b-button>
                    <p
                      v-if="indexEdit != -1"
                      class="description text-danger my-1 mx-1"
                    >
                      *กรุณาแก้ไขรายการให้เสร็จสิ้น
                    </p>
                  </div>
                </div>
              </b-card>
              <NHSOAnnouceCard :extendedProps="extendedProps" />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" lg="8">
          <b-card id="drawing-card" no-body>
            <Drawing
              ref="drawingCard"
              :patient="patient"
              :drawNote.sync="drawNote"
              :summaryTableBusy="summaryTableBusy"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-collapse>
    <Loading v-if="isLoading"></Loading>
    <Dialog ref="Dialog"></Dialog>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Loading from "@/components/Loading";
import Dialog from "@/components/modal/Dialog";

import TreatmentInputs from "@/components/modal/PaymentHistory/Treatment";
import OrthoPackageInputs from "@/components/modal/PaymentHistory/OrthoPackage";
import DrugsAndGoodsInputs from "@/components/modal/PaymentHistory/DrugsAndGoods";
import SummaryTable from "@/components/modal/PaymentHistory/SummaryTable";
import Drawing from "@/components/modal/PaymentHistory/Drawing.vue";
import NHSOAnnouceCard from "@/components/modal/PaymentHistory/NHSOAnnouceCard.vue";
import CopyToClipboard from "@/components/CopyToClipboard";

// import UploadFiles from "@/components/form/UploadFiles";
// import Gallery from "@/components/card/Gallery";

import moment from "moment";
import debounce from "@/utils/debounce";

import "@/theme/modal/PaymentHistory.scss";
import { eventBus } from "../../main";
import { ID_COMPREHENSIVE_TREATMENT } from "@/constants";

export default {
  name: "PaymentHistory",
  components: {
    Loading,
    Dialog,
    TreatmentInputs,
    OrthoPackageInputs,
    DrugsAndGoodsInputs,
    SummaryTable,
    Drawing,
    CopyToClipboard,
    NHSOAnnouceCard,
    // UploadFiles,
    // Gallery,
  },
  props: {
    tableCheckinList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isLoading: false,

      patient: {},
      appointment: null,
      appointmentId: null,
      treatment: {
        id: null,
        appointmentId: null,
        items: [],
      },
      drawNote: {
        text: "",
        lines: [],
      },

      checkinData: {},

      uneditedDrawNote: {},
      uneditedItems: [],

      indexEdit: -1,
      summaryTableBusy: false,

      tabIndex: 0,
      extendedProps: {},
      nhsoVisitNo: null,

      isTypingMode: false,

      overdueList: [],
      mode: null,

      scrollY: 0,
      debouncedCreateTreatment: null,
      fileCategories: [
        { text: "Intra-Oral", value: "IntraOralPhoto" },
        { text: "Extra-Oral", value: "ExtraOralPhoto" },
        { text: "X-Ray", value: "XRay" },
        { text: "OPD Card", value: "OpdCard" },
        // { text: "Invoice", value: "Invoice" },
        // { text: "Document", value: "Document" },
        // { text: "Model3D", value: "Model3d" },
        // { text: "Video", value: "Video" },
      ],
      // uploadFileRefs: [],
    };
  },

  created() {
    this.init();
  },
  mounted() {
    eventBus.$on(
      "openPaymentHistoryModalInEditingMode",
      this.showInEditingMode
    );
    this.debouncedCreateTreatment = debounce(this.createTreatment);
  },
  watch: {},
  computed: {
    ...mapGetters({
      viewFile: "moduleContent/viewFile",
      branchLinkedWithNhso: "moduleUser/getLinkedWithNhso",
      authorization: "moduleUser/getAuthorization",
      isDoctor: "moduleUser/isDoctor",
      isFileStorageExpired: "moduleUser/isFileStorageExpired",
    }),
    isDashboardRoute() {
      return this.$route.name === "Dashboard";
    },
    onQueueRoute() {
      return (
        this.$route.name === "QueueManage" || this.$route.name === "QueueList"
      );
    },
    isWritingMode: {
      get() {
        return !this.isTypingMode;
      },
      set(val) {},
    },
    isItemsClean() {
      return (
        JSON.stringify(this.uneditedItems) ===
          JSON.stringify(this.treatment.items) &&
        JSON.stringify(this.uneditedDrawNote) === JSON.stringify(this.drawNote)
      );
    },
    bgVariant() {
      if (this.indexEdit !== -1) return "lightBlue";
      if (this.extendedProps?.nhsoVisitNo) return "sky";
      return "";
    },
    isNhso() {
      return Boolean(this.extendedProps?.nhsoVisitNo);
    },
  },
  methods: {
    ...mapActions({
      fetchPatientById: "modulePatient/fetchPatientById",
      fetchTreatmentByApptId: "moduleTreatment/fetchTreatmentByApptId",
      fetchOverdueTreatmentByPatientId:
        "moduleTreatment/fetchOverdueTreatmentByPatientId",
      fetchTreatmentPlans: "moduleTreatmentPlan/fetchTreatmentPlans",
      // fecthMediaFiles: "moduleMediaFile/fecthMediaFiles",
    }),
    init() {},
    async show(appointment) {
      // default vaule ตอนshow modal
      this.setDefaultValue();
      let clinicUrl = this.$route.params.clinicUrl;
      let branchUrl = this.$route.params.branchUrl;
      if (appointment) {
        this.appointment = Object.assign({}, this.appointment, appointment);
        this.extendedProps = Object.assign(
          {},
          this.extendedProps,
          appointment.extendedProps
        );

        eventBus.$emit("appLoadingStatus", true);
        // await, in case of expired token will get new token only once
        this.summaryTableBusy = true;
        await this.fetchTreatmentByApptId({
          clinicUrl,
          branchUrl,
          appointmentId: appointment.id,
        })
          .then(res => {
            if (res.data) {
              let tx = res.data;
              this.treatment = Object.assign({
                id: tx.id,
                appointmentId: appointment.id,
                items: tx.items,
              });

              if (res.data.drawNote) {
                this.uneditedDrawNote = JSON.parse(
                  JSON.stringify(res.data.drawNote)
                );
                this.drawNote = res.data.drawNote;
              }

              if (res.data.items) {
                this.uneditedItems = JSON.parse(JSON.stringify(res.data.items)); //deep clone reference of unedit data
              }
            }
            this.$refs.drawingCard?.assignDrawNote(this.drawNote); //ถ้า drawingCard collapse ขึ้น error
          })
          .finally(() => {
            this.summaryTableBusy = false;
          });
      }
      this.fetchPatientById({
        clinicUrl,
        branchUrl,
        id: appointment.extendedProps.patient.id,
      })
        .then(async res => {
          if (res.status == 200) {
            this.patient = res.data;
          }
          await this.$nextTick();
          await this.$nextTick();
          this.$refs.orthoPackageTab.initOrthoComp();
          await this.$nextTick();
          this.$refs.drawingCard?.insertText();
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          eventBus.$emit("appLoadingStatus", false);
        });
      this.appointmentId = appointment.id;
      this.treatment.appointmentId = appointment.id;

      this.fetchOverdueTreatmentByPatientId({
        patientId: appointment.extendedProps.patient.id,
        clinicUrl,
        branchUrl,
      }).then(res => {
        this.overdueList = res.data;
      });
      this.fetchTreatmentPlans({
        patientId: this.appointment.extendedProps.patient.id,
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
      });
      // this.fecthMediaFiles({
      //   clinicUrl: this.$route.params.clinicUrl,
      //   branchUrl: this.$route.params.branchUrl,
      //   params: { treatmentId: this.treatment.id, limit: 100 },
      // });

      if (/iPad/i.test(navigator.userAgent)) {
        // เช็คว่าเป็น iPad ให้เข้า writingmode
        this.isTypingMode = false;
      } else {
        this.isTypingMode = true;
      }
      if (localStorage.getItem("isTypingMode")) {
        this.isTypingMode = JSON.parse(localStorage.getItem("isTypingMode"));
      }
      this.checkinData =
        this.tableCheckinList.find(({ id }) => id === this.appointmentId) || {};

      await this.$nextTick();

      this.$bvModal.show("PaymentHistoryModal");
      this.disableBodyScroll();
    },

    async createTreatment(arg) {
      let isDismissed = await this.validateTxItems();
      if (isDismissed) return;

      this.isLoading = true;
      this.treatment.drawNote = this.drawNote;

      this.treatment.items.forEach(i => {
        i.treatItems = String(i.treatItems || "");
        i.diagItems = String(i.diagItems || "");
      });

      if (!this.treatment.id) {
        this.treatment.items.forEach(i => {
          delete i.id;
          delete i.treatId;
          delete i.branchId;
          delete i._rowVariant;
          if (i.typeId != 3) delete i.itemId;
        });

        this.$store
          .dispatch("moduleTreatment/createTreatment", {
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
            event: this.treatment,
          })
          .then(async res => {
            if (res.status == 200) {
              this.isLoading = false;
              // this.treatment.id = res.data.id;
              // this.uploadFiles();
              this.$bvModal.hide("PaymentHistoryModal");
              if (this.isDashboardRoute) {
                if (arg === "paymentQueue") {
                  this.sendToPaymentQueue();
                  return;
                }
                this.$parent.showDialogToast("success", `บันทึกการรักษาสำเร็จ`);
                this.$parent.openNextVisitModal(this.appointment, this.patient);
              } else {
                await this.sendToPaymentQueue();
                this.$parent.openPaymentModal(this.appointment);
              }
            } else {
              this.isLoading = false;
              this.$parent.showDialogToast(
                "warning",
                "บันทึกการรักษาไม่สำเร็จ"
              );
            }
          })
          .catch(err => {
            if (err.response.data === "TREAT-EXISTED") {
              this.$refs.Dialog.showAlertInfo(
                "ไม่สามารถบันทึกการรักษาได้ เนื่องจากเป็นการบันทึกซ้ำ",
                "กด 'OK' เพื่อรีเฟรชหน้าเพจ ให้เป็นข้อมูลล่าสุด",
                "error"
              ).then(() => {
                location.reload();
              });
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        try {
          let res;
          if (this.isItemsClean) {
            res = { status: 200 };
          } else {
            const cleanedItems = this.treatment.items.map(item => {
              const {
                treatId,
                treatmentId,
                branchId,
                overdueFlag,
                creationDt,
                paymentFlag,
                overdueDelFlag,
                overdueDelDate,
                overdueDelUid,
                overdueDeletedReason,
                _rowVariant,
                id,
                ...cleanedItem
              } = item;

              if (item.typeId !== 3) {
                delete cleanedItem.itemId;
              }

              return cleanedItem;
            });

            this.treatment.items = cleanedItems;
            res = await this.$store.dispatch(
              "moduleTreatment/updateTreatment",
              {
                clinicUrl: this.$route.params.clinicUrl,
                branchUrl: this.$route.params.branchUrl,
                event: this.treatment,
              }
            );
          }

          if (res?.status === 200) {
            this.$bvModal.hide("PaymentHistoryModal");

            if (this.isDashboardRoute) {
              if (arg === "paymentQueue") {
                return this.sendToPaymentQueue();
              }
              this.$parent.showDialogToast("success", "บันทึกการรักษาสำเร็จ");
              return this.$parent.openNextVisitModal(
                this.appointment,
                this.patient
              );
            } else {
              await this.sendToPaymentQueue();
              return this.$parent.openPaymentModal(this.appointment);
            }
          } else {
            throw new Error("บันทึกการรักษาไม่สำเร็จ");
          }
        } catch (err) {
          if (err.response?.data === "PAYMENT-COMPLETED") {
            await this.$refs.Dialog.showAlertInfo(
              "ไม่สามารถบันทึกรายการรักษาได้ เนื่องจากคนไข้ชำระเงินแล้ว",
              "กด 'OK' เพื่อรีเฟรชหน้าเพจ ให้เป็นข้อมูลล่าสุด",
              "error"
            );
            location.reload();
          } else {
            this.$parent.showDialogToast("warning", err.message);
          }
        }
      }
    },
    async sendToPaymentQueue() {
      this.$emit("isLoading", true);

      await this.$store
        .dispatch("moduleAppointment/waitingForPaymentAppointment", {
          id: this.appointmentId,
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
        })
        .finally(() => {
          this.isLoading = false;
          this.$emit("isLoading", false);
          if (this.isDashboardRoute) {
            this.$parent.showDialogToast("success", "ส่งชำระเงินเรียบร้อย");
          }
        });
    },
    setDefaultValue() {
      this.patient = {};
      this.appointment = {};
      this.appointmentId = null;
      this.extendedProps = {};
      this.treatment = Object.assign({
        appointmentId: null,
        items: [],
      });
      this.uneditedItems = [];
      this.indexEdit = -1;
      this.drawNote = {
        text: "",
        lines: [],
      };
      this.uneditedDrawNote = this.drawNote;
      this.checkinData = {};
      this.overdueList = [];
      this.mode = null;
      // this.uploadFileRefs = [];
      eventBus.$emit("setDefaultValuePaymentHistroy");
    },

    deleteTreatmentItem(index, data) {
      if (data.typeId == 2) {
        this.setUpdateOrthoPackageItem(data.orthoPackageItemId, false, null);
        this.findOrthoPackageItemActive("delete");
      }
      this.treatment.items.splice(index, 1);
      this.indexEdit = -1; // ยกเลิกการ edit
    },
    // Utils

    openProfileCustomerModal() {
      this.$parent.openProfileCustomerModal(
        this.appointment,
        this.patient,
        true //hide-footer
      );
    },
    close(bvModalEvt) {
      if (this.mode === "edit") {
        this.closeForEditMode(bvModalEvt);
        return;
      }
      if (this.isItemsClean) return;
      bvModalEvt.preventDefault();
      this.$refs.Dialog.show3ButtonsConfirm({
        title: "คุณต้องการบันทึกรายการรักษา หรือไม่",
        text: "ข้อมูลจะสูญหาย ถ้าไม่มีการบันทึก",
        icon: "warning",
        confirmMessage: "บันทึก",
        denyMessage: "ไม่บันทึก",
        cancelMessege: "ยกเลิก",
      }).then(async res => {
        if (res.isDismissed) return;
        if (res.isConfirmed) {
          this.isLoading = true;
          this.treatment.drawNote = this.drawNote;
          this.treatment.items.forEach(i => {
            i.treatItems = String(i.treatItems || "");
            i.diagItems = String(i.diagItems || "");
          });
          if (!this.treatment.id) {
            this.treatment.items.forEach(i => {
              delete i.id;
              delete i.treatId;
              delete i.branchId;
              delete i._rowVariant;
              if (i.typeId != 3) delete i.itemId;
            });
            await this.$store
              .dispatch("moduleTreatment/createTreatment", {
                clinicUrl: this.$route.params.clinicUrl,
                branchUrl: this.$route.params.branchUrl,
                event: this.treatment,
              })
              .then(async res => {
                if (res.status == 200) {
                  this.$parent.showDialogToast(
                    "warning",
                    "บันทึกการรักษาแล้ว รอส่งชำระเงิน"
                  );
                }
              })
              .finally(() => {
                this.isLoading = false;
              });
          } else {
            this.treatment.items.forEach(i => {
              delete i.treatId;
              delete i.treatmentId;
              delete i.branchId;
              delete i.overdueFlag;
              delete i.creationDt;
              delete i.paymentFlag;
              delete i.overdueDelFlag;
              delete i.overdueDelDate;
              delete i.overdueDelUid;
              delete i.overdueDeletedReason;
              delete i._rowVariant;
              delete i.id;
              if (i.typeId != 3) delete i.itemId;
              delete i._rowVariant;

              // Quick hack ในกรณีที่ไม่มีการแก้ไขข้อมูลเลย ตัว sequelize จะไม่ update row ทำให้เกิด error 500 "Query was empty"
              // i.note += " ";
            });
            await this.$store
              .dispatch("moduleTreatment/updateTreatment", {
                clinicUrl: this.$route.params.clinicUrl,
                branchUrl: this.$route.params.branchUrl,
                event: this.treatment,
              })
              .then(async res => {
                if (res.status == 200) {
                  this.$parent.showDialogToast(
                    "warning",
                    "บันทึกการรักษาแล้ว รอส่งชำระเงิน"
                  );
                }
              })
              .finally(() => {
                this.isLoading = false;
              });
          }

          this.$bvModal.hide("PaymentHistoryModal");
        } else if (res.isDenied) {
          this.$bvModal.hide("PaymentHistoryModal");
        }
      });
    },

    handleAddItem(obj) {
      if (this.indexEdit === -1) {
        this.treatment.items.push(obj);
      }
      if (this.indexEdit >= 0) {
        Object.assign(this.treatment.items[this.indexEdit], obj);
      }
    },
    async assignEditItem(item, index) {
      this.tabIndex = item.typeId - 1;
      this.indexEdit = index;
      await this.$nextTick();

      if (item.typeId == 1) {
        this.$refs.treatmentTab.assignTreatmentInput(item);
      }
      if (item.typeId == 2) {
        this.$refs.orthoPackageTab.assignEditOrthoInputs(item);
      }
      if (item.typeId == 3) {
        this.$refs.DrugsAndGoodsTab.assignDrugsInputs(item);
      }
    },
    async validateTxItems() {
      //NHSO
      const includesId = this.treatment.items.some(obj => {
        return Number(obj.treatItems) == ID_COMPREHENSIVE_TREATMENT;
      });
      const itemLength = this.treatment.items.length;
      const moreThanOne = itemLength > 1;
      if (
        this.extendedProps?.nhsoVisitNo === 1 &&
        (!includesId || !moreThanOne)
      ) {
        return await this.$refs.Dialog.showAlertInfo(
          "ลงการรักษาไม่ครบ!",
          `คนไข้มาครั้งแรก ต้องทำการตรวจสุขภาพช่องปาก (Comprehensive oral evaluation) \n
          และทำการรักษาตาม Cheif Complaint \n
          (ลงการรักษาอย่างน้อย 2 รายการ)`,
          "warning"
        ).then(() => {
          return true;
        });
      }

      if (this.extendedProps?.nhsoVisitNo > 1 && itemLength < 1) {
        return await this.$refs.Dialog.showAlertInfo(
          "ลงการรักษาไม่ครบ!",
          "กรุณาลงบันทึกการรักษาอย่างน้อย 1 รายการ",
          "warning"
        ).then(() => {
          return true;
        });
      }

      //Normal
      if (this.treatment.items.length === 0) {
        return await this.$refs.Dialog.showAlertConfirm(
          "ไม่มี การลงบันทึกรายการรักษา",
          "คุณต้องการ ดำเนินการต่อ?",
          "warning",
          "ตกลง",
          "กลับไปลงรายการรักษา"
        ).then(res => {
          return res.isDismissed;
        });
      } else if (this.treatment.items.length > 10) {
        return await this.$refs.Dialog.showAlertInfo(
          "ลงรายการรักษาได้ไม่เกิน 10 รายการ",
          "กรุณาลบรายการรักษา บางรายการออก"
        ).then(() => {
          return true;
        });
      } else {
        return false;
      }
    },

    async handleAssignTabIndex(index) {
      await this.$nextTick();
      this.tabIndex = index;
    },
    async switchMode() {
      this.isTypingMode = !this.isTypingMode;
      localStorage.setItem("isTypingMode", this.isTypingMode);
      await this.$nextTick();
      await this.$nextTick();
      this.$refs.orthoPackageTab.initOrthoComp();
      if (!this.isTypingMode) {
        this.$refs.drawingCard?.assignDrawNote(this.drawNote); //ถ้า drawingCard collapse ขึ้น error
        this.$refs.drawingCard?.insertText();
      }
    },

    //Editing Mode
    async showInEditingMode(appointmentId) {
      this.setDefaultValue();
      this.$bvModal.show("PaymentHistoryModal");
      this.isLoading = true;
      this.mode = "edit";
      let clinicUrl = this.$route.params.clinicUrl;
      let branchUrl = this.$route.params.branchUrl;

      this.fetchTreatmentByApptId({
        clinicUrl,
        branchUrl,
        appointmentId,
      })
        .then(res => {
          if (res.data) {
            this.fetchPatientById({
              clinicUrl,
              branchUrl,
              id: res.data.patientId,
            })
              .then(async res => {
                if (res.status == 200) {
                  this.patient = res.data;
                }
                await this.$nextTick();
                await this.$nextTick();
                await this.$refs.orthoPackageTab.initOrthoComp();
              })
              .catch(err => {
                console.error(err);
              })
              .finally(() => {
                this.isLoading = false;
              });
            let tx = res.data;
            this.treatment = Object.assign({
              id: tx.id,
              appointmentId,
              items: tx.items,
            });

            if (res.data.drawNote) {
              this.uneditedDrawNote = JSON.parse(
                JSON.stringify(res.data.drawNote)
              );
              this.drawNote = res.data.drawNote;
            }

            if (res.data.items) {
              this.uneditedItems = JSON.parse(JSON.stringify(res.data.items)); //deep clone reference of unedit data
            }
          }
          this.$refs.drawingCard?.assignDrawNote(this.drawNote); //ถ้า drawingCard collapse ขึ้น error
        })
        .finally(() => {
          this.summaryTableBusy = false;
        });
      this.disableBodyScroll();
    },
    async editTreatmentHistory() {
      try {
        if (this.isItemsClean) {
          this.$bvModal.hide("PaymentHistoryModal");
        }

        const isDismissed = await this.validateTxItems();
        if (isDismissed) return;

        this.isLoading = true;
        this.treatment.drawNote = this.drawNote;

        this.treatment.items.forEach(i => {
          i.treatItems = String(i.treatItems || "");
          i.diagItems = String(i.diagItems || "");
          i.itemId = parseInt(i.itemId) || null;
          delete i.treatId;
          delete i.treatmentId;
          delete i.branchId;
          delete i.overdueFlag;
          delete i.creationDt;
          delete i.paymentFlag;
          delete i.overdueDelFlag;
          delete i.overdueDelDate;
          delete i.overdueDelUid;
          delete i.overdueDeletedReason;
          delete i._rowVariant;

          // Quick hack ในกรณีที่ไม่มีการแก้ไขข้อมูลเลย ตัว sequelize จะไม่ update row ทำให้เกิด error 500 "Query was empty"
          // i.note += " ";
        });

        const res = await this.$store.dispatch(
          "moduleTreatment/updateTreatment",
          {
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
            event: this.treatment,
          }
        );
        if (res.status == 200) {
          eventBus.$emit("fecthTreatmentHistory");
          this.$bvModal.hide("PaymentHistoryModal");
        } else {
          this.$parent.showDialogToast("warning", "แก้ไข");
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
    closeForEditMode(bvModalEvt) {
      if (this.isItemsClean) return;

      bvModalEvt.preventDefault();
      this.$refs.Dialog.show3ButtonsConfirm({
        title: "คุณต้องการแก้ไขรายการรักษา หรือไม่",
        text: "ข้อมูลจะสูญหาย ถ้าไม่มีการบันทึก",
        icon: "warning",
        confirmMessage: "แก้ไข",
        denyMessage: "ไม่แก้ไข",
        cancelMessege: "ยกเลิก",
      }).then(async res => {
        if (res.isDismissed) return;
        if (res.isConfirmed) {
          this.isLoading = true;
          await this.editTreatmentHistory();
          this.$bvModal.hide("PaymentHistoryModal");
        } else if (res.isDenied) {
          this.$bvModal.hide("PaymentHistoryModal");
        }
      });
    },
    // uploadFiles() {
    //   this.uploadFileRefs.forEach(ref => {
    //     if (ref) {
    //       ref.upLoadFileWithoutPolling();
    //     }
    //   });
    // },
    // setUploadFileRef(index) {
    //   return el => {
    //     if (el) {
    //       this.$set(this.uploadFileRefs, index, el);
    //     }
    //   };
    // },

    // Utils
    fullName() {
      if (this.patient) {
        var tilte = this.patient.titleNameTh
          ? this.patient.titleNameTh
          : this.patient.titleNameEn
          ? this.patient.titleNameEn
          : "";
        var firstName = this.patient.firstNameTh
          ? this.patient.firstNameTh
          : this.patient.firstNameEn
          ? this.patient.firstNameEn
          : "";
        var lastName = this.patient.lastNameTh
          ? this.patient.lastNameTh
          : this.patient.lastNameEn
          ? this.patient.lastNameEn
          : "";
        var fullName = [tilte, firstName, lastName].join(" ");
        return fullName;
      } else {
        return "";
      }
    },
    abbreviation() {
      if (
        this.patient &&
        (this.patient.firstNameEn || this.patient.lastNameEn)
      ) {
        var firstName = "";
        var lastName = "";
        if (this.patient.firstNameEn) {
          firstName = this.patient.firstNameEn.charAt(0);
        }
        if (this.patient.lastNameEn) {
          lastName = this.patient.lastNameEn.charAt(0);
        }
        return `${firstName}${lastName}`;
      } else {
        return "";
      }
    },
    formatTime(date) {
      return moment(date).format("HH:mm");
    },
    disableBodyScroll() {
      this.scrollY = window.scrollY;
      document.body.style.position = "fixed";
      document.body.style.right = "0px";
      document.body.style.left = "0px";
    },
    enableBodyScroll() {
      document.body.style.right = "unset";
      document.body.style.left = "unset";
      document.body.style.position = "unset";
      document.body.style.top = "unset";
      window.scrollTo(0, parseInt(this.scrollY || "0"));
    },
  },
  destroyed() {
    this.enableBodyScroll();
  },
};
</script>

<style>
</style>