<template>
  <b-modal
    modal-class="component-modal-event"
    id="EventModal"
    ref="EventModal"
    size="xl fluid"
    title="นัดหมาย"
    hide-footer
    no-close-on-backdrop
    @close="close"
    @hide="close"
    @hidden="setDefaultEvent"
  >
    <div class="row">
      <div
        class="col-12 col-lg-3 divider-lg-right divider-bottom divider-lg-bottom-0 mb-3 mb-lg-0"
      >
        <div class="row" v-if="patient.id === 1">
          <div class="d-flex justify-content-center col-12 col-sm-6 col-lg-12">
            <div class="d-inline-block img-thumbnail mb-3">
              <b-avatar
                variant="danger"
                alt="User Image"
                size="120px"
                square
                rounded
              ></b-avatar>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-12">
            <div class="text-danger small-text mb-3">
              <span class="font-weight-bold"
                >"สร้างนัดหมาย โดยยังไม่ลงประวัติคนไข้"
              </span>
              <br />
              ใช้ในกรณียังไม่มีประวัติโดยละเอียดของคนไข้
              หรือยังไม่แน่ใจว่าคนไข้จะเข้ามารักษา หรือไม่
            </div>

            <b-row
              ><b-col lg="12">
                <b-button block class="mb-2" size="sm" @click="createPatient"
                  >สร้างคนไข้ใหม่</b-button
                > </b-col
              ><b-col lg="12">
                <b-button block class="mb-2" size="sm" @click="selectPatient">
                  เลือกคนไข้เก่า</b-button
                >
              </b-col>
              <b-col lg="12">
                <b-button
                  block
                  class="mb-2"
                  size="sm"
                  variant="info"
                  @click="openUnnamedAptHxModal"
                >
                  ประวัตินัด</b-button
                >
              </b-col>
            </b-row>
          </div>
        </div>
        <div v-else>
          <PatientProfile :patient="patient" />
          <NextApt :patientId="patient.id" />
          <b-button size="sm" @click="openProfileCustomerModal()" block
            >ประวัตินัด</b-button
          >
        </div>
      </div>
      <div class="col">
        <b-form @submit.prevent="debouncedValidateAppointment">
          <b-form-row>
            <b-form-group
              class="col mb-2"
              label-cols-sm="2"
              label-align-sm="right"
              label="เวลานัด:"
              label-class="font-weight-bold"
            >
              <b-input-group>
                <datetime
                  class="col p-0"
                  type="datetime"
                  v-model="$v.dateTimeDefault.$model"
                  input-class="form-control my-0"
                  :input-style="dateTimeInputStyle"
                  format="cccc dd LLLL yyyy / HH:mm"
                  value-zone="UTC+7"
                  :min-datetime="startToday"
                  :phrases="{ ok: 'ตกลง', cancel: 'ยกเลิก' }"
                  :hour-step="1"
                  :minute-step="duration"
                  :week-start="7"
                  auto
                ></datetime>
                <b-input-group-append>
                  <b-button variant="info" @click="openDateTimeSelectFc"
                    >ตารางนัด</b-button
                  >
                </b-input-group-append>
              </b-input-group>
              <template v-slot:description>
                <span v-if="!$v.dateTimeDefault.isInTheFuture" class="error"
                  >ไม่สามารถสร้างนัด MorFunDee ย้อนหลังได้
                </span>
                <span
                  v-else-if="!$v.dateTimeDefault.isBetweenTime"
                  class="error"
                >
                  กรุณาเลือกภายในเวลาทำการ ({{
                    getBranchInfo.confOpeningTime
                  }}-{{ getBranchInfo.confClosingTime }})
                  แก้ไขเวลาทำการได้ในเมนู "การตั้งค่า"
                </span>
                <span v-else
                  >สามารถเลือกวันเวลา และห้องที่ว่าง จากตารางนัดได้</span
                >
              </template>
            </b-form-group>
          </b-form-row>
          <b-form-row>
            <b-form-group
              class="col mb-0"
              label-cols-sm="2"
              label="นัดเพื่อ:"
              label-align-sm="right"
              label-class="font-weight-bold"
              description="ตั้งรายการใช้ประจำได้ใน 'ลิสต์นัดหมาย'"
            >
              <b-input-group>
                <multiselect
                  class="col p-0"
                  v-model="treatmentList"
                  :options="getGroupedTxList"
                  :multiple="true"
                  group-values="libs"
                  group-label="category"
                  :group-select="false"
                  placeholder="พิมพ์ค้นหารายการนัดหมาย"
                  track-by="nameTh"
                  :custom-label="customLabel"
                  selectLabel
                  selectedLabel
                  deselectLabel="คลิกเพื่อลบออก"
                  :limit="3"
                  @input="autoInput"
                >
                  <template #option="props">
                    <span v-if="props.option.$isLabel">
                      {{ props.option.$groupLabel }}
                    </span>
                    <span v-else>
                      <b-button
                        class="mr-1 small"
                        size="xs"
                        variant="outline-info"
                        :pressed="props.option.favorite"
                        @click.stop="favAptToggle(props.option)"
                      >
                        <i class="far fa-star"></i>
                      </b-button>
                      {{
                        props.option.nameEn === props.option.nameTh
                          ? props.option.nameEn
                          : props.option.nameTh + " / " + props.option.nameEn
                      }}
                    </span>
                  </template>
                  <span slot="noResult">ไม่พบรายการ กรุณาเปลี่ยนคำค้นหา</span>
                </multiselect>
                <b-input-group-append>
                  <b-button variant="info" @click="openAptListModal"
                    >ลิสต์นัดหมาย</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-form-row>
          <hr class="my-2" />
          <b-form-row>
            <b-form-group
              class="col-6 mb-2"
              label="ระยะเวลา(นาที):"
              label-class
            >
              <b-input-group>
                <b-form-input
                  type="range"
                  v-model="timeDuration"
                  :min="duration"
                  max="180"
                  :step="duration"
                ></b-form-input>
                <b-form-input
                  class="col-2 px-0 text-center"
                  v-model="timeDuration"
                  lazy
                  type="number"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
            <b-form-group
              class="col-6 mb-0"
              label="แจ้งเตือน(สัญลักษณ์ที่กล่องนัด):"
              label-class
            >
              <b-form-select
                class="col"
                :options="getReminderList"
                value-field="val"
                text-field="label"
                v-model="rmdId"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null"
                    >-- ไม่มีแจ้งเตือน --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-form-row>
          <b-form-row>
            <b-form-group
              class="col"
              label="แพทย์ที่นัดหมาย"
              :class="{ 'is-invalid': $v.doctor.$error }"
            >
              <multiselect
                v-model="selectedDoctorObj"
                :options="groupDoctorList"
                :multiple="false"
                group-values="list"
                group-label="groupLabel"
                :group-select="false"
                placeholder="พิมพ์เพื่อค้นหาแพทย์"
                track-by="uid"
                label="fullName"
                selectLabel=""
                deselectLabel=""
                selectedLabel=""
                ><span slot="noResult">ไม่พบแพทย์ที่ต้องการค้นหา</span>
                <template #noOptions>
                  กรุณาเพิ่มแพทย์ในเมนู "จัดการผู้ใช้"
                </template></multiselect
              >

              <!-- <b-form-select
                :class="{ 'is-invalid': $v.doctor.$error }"
                id="doctor"
                v-model="$v.doctor.$model"
                :options="getDoctorList"
                value-field="uid"
                text-field="fullName"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled
                    >รายชื่อแพทย์</b-form-select-option
                  >
                </template>
              </b-form-select> -->
            </b-form-group>
            <b-form-group class="col" label="ห้องที่นัดหมาย">
              <b-form-select
                :class="{ 'is-invalid': $v.roomId.$error }"
                id="resource"
                v-model="$v.roomId.$model"
                :options="getRoomList"
                value-field="id"
                text-field="title"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >เลือกห้อง</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-form-row>

          <b-form-row class="mb-3">
            <div class="favorite-text-area-container col">
              <b-form-group
                description="บันทึกข้อมูลนัดหมายเพิ่มเติม เช่น สะดวกเวลานี้เท่านั้น, ขอนัดแทรก เป็นต้น"
                invalid-feedback="กรุณาใส่ข้อมูลคนไข้เพิ่มเติม เช่น ชื่อ และเบอร์ติดต่อ"
                :state="!$v.note.$error"
              >
                <b-form-textarea
                  id="event-appointment-note"
                  ref="eventAppointmentNote"
                  :class="{ 'is-invalid': $v.note.$error }"
                  rows="4"
                  no-resize
                  v-model="$v.note.$model"
                  trim
                  placeholder="โน๊ตนัดหมายเพิ่มเติม..."
                ></b-form-textarea>
                <FavNoteButton
                  storageName="favEventNote"
                  @listClicked="handleSelectedFavNote"
                />
              </b-form-group>
            </div>
          </b-form-row>
          <b-row class="d-flex flex-row-reverse">
            <div>
              <b-button class="ml-2 mr-3" type="submit" variant="primary">
                {{
                  appointment && appointment.id
                    ? "แก้ไขนัดหมาย"
                    : "สร้างนัดหมาย"
                }}
              </b-button>
            </div>
            <div v-if="!appointment || !appointment.id">
              <b-form-checkbox
                v-if="!isFileStorageExpired"
                v-model="linkedWithMFD"
                switch
              >
                <span style="font-weight: 400">
                  TeleDentitry ด้วย "MorFunDee"
                </span>
                <img style="width: auto; height: 18px" :src="MorFunDeeLogo" />
              </b-form-checkbox>
            </div>
            <div v-if="appointment && appointment.id">
              <b-button variant="danger" @click="deleteEventAppointment()"
                >ลบนัดหมาย</b-button
              >
            </div>
            <div class="mr-auto pl-3 small-text">
              <div class="text-muted small">
                สร้างโดย: {{ getUserListBy(creationUid, "uid").fullName }}
                {{ formatDateTime(creationDt) }}
              </div>
              <div v-if="isAptModified" class="text-muted small">
                แก้ไขโดย: {{ getUserListBy(modifyUid, "uid").fullName }}
                {{ formatDateTime(modifyDt) }}
              </div>
            </div>
          </b-row>
        </b-form>
      </div>
    </div>
    <CustomerModal ref="customerModal"></CustomerModal>
    <AppointmentListModal
      ref="appointmentListModal"
      @selectedTreatment="tableSelectedList = $event"
    ></AppointmentListModal>
    <DateTimeSelectFc
      ref="dateTimeSelectFc"
      :patient="patient"
      @dateTimeSelect="dateTimeDefault = $event"
      @roomId="roomId = $event"
      @doctorUid="doctor = $event"
      @quickSaveAppointment="quickSaveAppointment"
    ></DateTimeSelectFc>
    <UnnameAptHxModal ref="unnamedAptHxModal" />
    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
  </b-modal>
</template>

<script>
import CustomerModal from "@/components/modal/Customer";

import AppointmentListModal from "@/components/modal/AppointmentList";
import DateTimeSelectFc from "@/components/modal/DateTimeSelectFc";
import UnnameAptHxModal from "@/components/modal/UnnamedApts";
import FavNoteButton from "@/components/button/FavNote";
import NextApt from "@/components/card/NextApt";
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import moment from "moment";
import checkRruleIfSameDay from "@/utils/checkRruleIfSameDay";
import debounce from "@/utils/debounce";
import MorFunDeeLogo from "../../assets/img/morfundee-logo.png";

import { eventBus } from "@/main";

import { mapActions, mapGetters } from "vuex";

//vuelidate
import { required, requiredIf } from "vuelidate/lib/validators";

import "@/theme/modal/Event.scss";

export default {
  name: "EventModal",
  components: {
    CustomerModal,
    Dialog,
    Loading,
    AppointmentListModal,
    DateTimeSelectFc,
    FavNoteButton,
    NextApt,
    UnnameAptHxModal,
  },
  data() {
    return {
      isLoading: false,
      appointment: null,
      id: null,
      selectedDoctorObj: null,
      // doctor: null,
      patient: {},
      roomId: null,
      dateTimeDefault: "",
      aptTreatItems: [],
      rmdId: null,
      minMinute: this.duration || 15, //config data
      note: "",
      timeDuration: 15,
      treatmentList: [],
      tableSelectedList: [],
      reminderList: [],
      openFrom: null,
      creationUid: null,
      creationDt: null,
      modifyUid: null,
      modifyDt: null,
      linkedWithMFD: false,
      MorFunDeeLogo,
    };
  },
  validations: {
    dateTimeDefault: {
      required,
      isBetweenTime(dateTime) {
        const dateTimeStr = moment(dateTime).format("HH:mm");
        const convertTime = (str) => moment(str, "HH:mm");
        return moment(convertTime(dateTimeStr)).isBetween(
          convertTime(this.getBranchInfo.confOpeningTime || "09:00"),
          convertTime(this.getBranchInfo.confClosingTime || "21:00"),
          "minutes",
          "[)"
        );
      },
      isInTheFuture(dateTime) {
        if (!this.linkedWithMFD) return true;
        return moment(dateTime).isAfter();
      },
    },
    doctor: { required },
    roomId: { required },
    note: {
      required: requiredIf(function () {
        return this.patient?.id === 1;
      }),
    },
  },
  computed: {
    ...mapGetters({
      getDrSpecialBy: "moduleMaster/getDrSpecialBy",
      getBranchInfo: "moduleUser/getBranchInfo",
      isFileStorageExpired: "moduleUser/isFileStorageExpired",
      getRoomList: "moduleUser/getRoomList",
      getRoomListBy: "moduleUser/getRoomListBy",
      getDoctorList: "moduleUser/getDoctorList",
      getTreatmentList: "moduleAppointment/getTreatmentList",
      getGroupedTxList: "moduleAppointment/getGroupedTxList",
      getTreatmentBy: "moduleAppointment/getTreatmentBy",
      getTreatmentCateBy: "moduleAppointment/getTreatmentCateBy",
      appointmentList: "moduleAppointment/getQueueAppointmentList",
      getReminderList: "moduleMaster/getReminderList",
      isAccessMenu: "moduleUser/getIsAccessMenu",
      getUserListBy: "moduleUser/getUserListBy",

      viewAvatar: "moduleContent/viewAvatar",
    }),
    groupDoctorList() {
      if (this.getDoctorList.length < 1) return [];
      if (this.dateTimeDefault) {
        const dateObj = moment(this.dateTimeDefault);
        let availableList = this.getDoctorList.filter((d) =>
          d.doctorWorkingHours?.some((w) => {
            return checkRruleIfSameDay({
              dateObj,
              rruleStr: w.rruleStr,
            });
          })
        );
        return [
          { groupLabel: "แพทย์ที่ลงตรวจในวันที่เลือก", list: availableList },
          { groupLabel: "แพทย์ทั้งหมด", list: this.getDoctorList },
        ];
      }
      return [{ groupLabel: "แพทย์ทั้งหมด", list: this.getDoctorList }];
    },
    startToday() {
      return moment().startOf("day").format();
    },
    dateTimeInputStyle() {
      return this.$v.dateTimeDefault.$error
        ? "border-color: #dc3545; border-radius: 0.25rem 0 0 0.25rem"
        : "";
    },
    duration() {
      return moment
        .duration(
          this.getBranchInfo?.confApptDuration
            ? this.getBranchInfo.confApptDuration
            : "00:15"
        )
        .asMinutes();
    },
    isAptModified() {
      if (!this.modifyDt) return false;
      if (this.creationDt === this.modifyDt) return false;
      return true;
    },
    doctor: {
      get() {
        return this.selectedDoctorObj?.uid || null;
      },
      // setter
      set(uid) {
        const user = this.getUserListBy(uid, "uid");
        if (user.value === null) {
          this.selectedDoctorObj = null;
        } else {
          this.selectedDoctorObj = user;
        }
      },
    },
    debouncedValidateAppointment: function () {
      return (event) => {
        return debounce(this.validateAppointment)(event);
      };
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions({}),
    init() {},
    show(appointment, patient = null, openFrom = null) {
      this.appointment = appointment;
      this.patient = patient;
      this.openFrom = openFrom;
      if (appointment) {
        var startTime = this.roundedTime(appointment.start);
        this.dateTimeDefault = startTime ?? "";
        this.roomId = appointment.roomId ?? null;
        this.doctor = appointment.doctor ?? null;
        if (appointment.id || appointment.extendedProps) {
          var eventProps = appointment.extendedProps;
          this.id = appointment.id;

          if (eventProps.patient) this.patient = eventProps.patient;
          this.aptTreatItems = eventProps.treatmentList || [];
          this.doctor = eventProps.doctor;
          this.roomId = eventProps.roomId;
          this.note = eventProps.note;
          this.treatmentList = [];
          this.rmdId = eventProps.rmdId || null;
          this.aptTreatItems?.forEach((res) => {
            this.treatmentList.push(this.getTreatmentBy(res, "id"));
          });
          this.creationUid = eventProps.creationUid;
          this.creationDt = eventProps.creationDt;
          this.modifyUid = eventProps.modifyUid;
          this.modifyDt = eventProps.modifyDt;

          if (eventProps.rmdId) {
            this.rmdId = eventProps.rmdId;
          } else {
            let rmdArr = this.treatmentList.map((i) => i.rmdId).filter(Boolean);
            rmdArr.length < 1
              ? (this.rmdId = null)
              : (this.rmdId = Math.min(...rmdArr));
          }
        }
      } else {
        this.dateTimeDefault = this.roundedTime();
      }
      this.$nextTick(() => {
        this.timeDuration =
          appointment?.extendedProps?.timeDuration > this.duration
            ? appointment.extendedProps.timeDuration
            : this.duration;
      });
      this.$bvModal.show("EventModal");
      this.$v.$reset();
    },
    customLabel(libs) {
      return `${libs.nameTh}${libs.nameEn ? "-[" + libs.nameEn + "]" : ""}`;
    },
    saveEventAppointment() {
      const startDt = this.dateTimeDefault;
      const endDt = moment(startDt).add(this.timeDuration, "minutes").format();

      let eventData = {
        // branchId,
        id: parseInt(this.id),
        startDt: startDt,
        endDt: endDt,
        roomId: parseInt(this.roomId),
        aptTreatItems: this.aptTreatItems,
        rmdId: this.rmdId,
        doctorUid: parseInt(this.doctor),
        patientId: this.patient?.id,
        note: this.note,
        linkedWithMFD: this.linkedWithMFD,
      };
      if (!this.id) {
        delete eventData.id;
        this.isLoading = true;
        this.$store
          .dispatch("moduleAppointment/createQueueAppointment", {
            event: eventData,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .then((res) => {
            if (res.status == "200") {
              this.$emit("blinkAptId", res.data.id);
              if (this.openFrom != "paymentModal") {
                this.$emit("goToDateTime", this.dateTimeDefault);
              }
              eventBus.$emit("nextVisitCreated", res.data);
              this.$bvModal.hide("EventModal");
              this.isLoading = false;
              this.$parent.showDialogToast(
                "success",
                `สร้างนัดหมายใหม่ ${moment(startDt).locale("th").format("LLLL")}
                  ที่ ${this.getRoomListBy(eventData.roomId, "id").name}`
              );
              this.setDefaultEvent();
            } else {
              this.isLoading = false;
              this.$parent.showDialogToast("warning", "สร้างนัดหมายไม่สำเร็จ");
            }
          })
          .catch((err) => {
            console.error(err);
            this.isLoading = false;
          });
      } else {
        this.$refs.Dialog.showAlertConfirm(
          "แก้ไขข้อมูลนัดหมาย ?",
          "คุณต้องการแก้ไขข้อมูลนัดหมาย",
          null,
          "ยืนยันการแก้ไข",
          "ยกเลิก"
        ).then((result) => {
          if (result.value) {
            this.isLoading = true;
            delete eventData.linkedWithMFD;
            this.$store
              .dispatch("moduleAppointment/updateQueueAppointment", {
                event: eventData,
                clinicUrl: this.$route.params.clinicUrl,
                branchUrl: this.$route.params.branchUrl,
              })
              .then((res) => {
                if (res.status == "200") {
                  this.$emit("blinkAptId", res.data.id);
                  if (this.openFrom != "paymentModal") {
                    this.$emit("goToDateTime", this.dateTimeDefault);
                  }
                  eventBus.$emit("nextVisitEdited", res.data);
                  this.$bvModal.hide("EventModal");
                  this.isLoading = false;
                  this.$parent.showDialogToast(
                    "success",
                    "แก้ไขนัดหมายเรียบร้อย"
                  );
                  // this.$parent.showDialogToast(
                  //   "success",
                  //   `แก้ไขนัดหมายไป ${moment(startDt)
                  //     .locale("th")
                  //     .format("LLLL")}
                  // ที่ ${this.getRoomListBy(eventData.roomId, "id").name}`
                  // );
                  this.setDefaultEvent();
                } else {
                  this.isLoading = false;
                  this.$parent.showDialogToast(
                    "warning",
                    "แก้ไขนัดหมายไม่สำเร็จ"
                  );
                }
              })
              .catch(() => {
                this.isLoading = false;
              });
          }
        });
      }
    },
    quickSaveAppointment() {
      this.$v.$touch();
      if (this.$v.$error) return;
      const startDt = this.dateTimeDefault;
      const endDt = moment(startDt).add(this.timeDuration, "minutes").format();

      let eventData = {
        id: parseInt(this.id),
        startDt: startDt,
        endDt: endDt,
        roomId: parseInt(this.roomId),
        aptTreatItems: this.aptTreatItems,
        rmdId: this.rmdId,
        doctorUid: parseInt(this.doctor),
        patientId: this.patient?.id,
        note: this.note,
      };
      if (!this.id) {
        delete eventData.id;
        this.isLoading = true;
        this.$store
          .dispatch("moduleAppointment/createQueueAppointment", {
            event: eventData,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .then((res) => {
            if (res.status == "200") {
              this.$emit("goToDateTime", this.dateTimeDefault);
              if (this.openFrom != "paymentModal") {
                this.$emit("blinkAptId", res.data.id);
              }
              eventBus.$emit("aptCreated", res.data);
              this.$bvModal.hide("EventModal");
              this.isLoading = false;
              this.$parent.showDialogToast(
                "success",
                `สร้างนัดหมายใหม่ ${moment(startDt).locale("th").format("LLLL")}
                  ที่ ${this.getRoomListBy(eventData.roomId, "id").name}`
              );
              this.setDefaultEvent();
            } else {
              this.isLoading = false;
              this.$parent.showDialogToast("warning", "สร้างนัดหมายไม่สำเร็จ");
            }
          })
          .catch(() => {
            this.isLoading = false;
          });
      } else {
        this.isLoading = true;
        this.updateAppointment(eventData)
          .then((res) => {
            if (res.status == "200") {
              this.$emit("blinkAptId", res.data.id);
              if (this.openFrom != "paymentModal") {
                this.$emit("goToDateTime", this.dateTimeDefault);
              }
              eventBus.$emit("aptCreated", res.data);
              this.$bvModal.hide("EventModal");
              this.isLoading = false;
              this.$parent.showDialogToast("success", "แก้ไขนัดหมายเรียบร้อย");

              this.setDefaultEvent();
            } else {
              this.isLoading = false;
              this.$parent.showDialogToast("warning", "แก้ไขนัดหมายไม่สำเร็จ");
            }
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },
    async updateAppointment(event) {
      try {
        return await this.$store.dispatch(
          "moduleAppointment/updateQueueAppointment",
          {
            event,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          }
        );
      } catch (err) {
        console.error(err);
      }
    },
    deleteEventAppointment() {
      this.$refs.Dialog.showAlertConfirm(
        "ลบนัดหมายนี้ ?",
        "คุณต้องการลบนัดหมายนี้"
      ).then((result) => {
        if (result.value) {
          this.isLoading = true;
          let branchId = this.getBranchInfo.id;
          let eventData = {
            branchId,
            id: this.id,
          };
          this.$store
            .dispatch("moduleAppointment/deleteQueueAppointment", {
              event: eventData,
              clinicUrl: this.$route.params.clinicUrl,
              branchUrl: this.$route.params.branchUrl,
            })
            .then((res) => {
              if (res.status == "200") {
                this.$bvModal.hide("EventModal");
                this.setDefaultEvent();
              }
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
            });
        }
      });
    },
    close() {
      this.setDefaultEvent();
    },
    setDefaultEvent() {
      this.appointment = null;
      this.id = null;
      this.selectedDoctorObj = null;
      this.roomId = null;
      this.dateTimeDefault = "";
      this.aptTreatItems = [];
      this.note = "";
      this.timeDuration = this.duration;
      this.treatmentList = [];
      this.rmdId = null;
      this.openFrom = null;
      this.creationUid = null;
      this.creationDt = null;
      this.modifyUid = null;
      this.modifyDt = null;
      this.linkedWithMFD = false;
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$v.$reset();
        });
      });
    },
    openAptListModal() {
      this.$refs.appointmentListModal.show(this.treatmentList);
    },
    openDateTimeSelectFc() {
      this.$refs.dateTimeSelectFc.show(this.dateTimeDefault);
    },
    openProfileCustomerModal() {
      this.$parent.openProfileCustomerModal(
        null,
        this.patient,
        true, //hide-footer
        "aptHX"
      );
    },
    openUnnamedAptHxModal() {
      this.$refs.unnamedAptHxModal.show();
    },
    validateAppointment() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.saveEventAppointment();
    },
    roundedTime(time) {
      const mins = moment
        .duration(
          this.getBranchInfo?.confApptDuration
            ? this.getBranchInfo.confApptDuration
            : "00:15"
        )
        .asMinutes();
      const rounded = Math.ceil(moment(time).minute() / mins) * mins;
      return moment(time).clone().minute(rounded).second(0).format();
    },
    autoInput() {
      this.timeDuration = 0;
      let selectedOptions = [];
      this.treatmentList.forEach((val) => {
        this.timeDuration += val.durationMin;
        selectedOptions.push(val.id);

        if (!this.rmdId) {
          this.rmdId = val.rmdId;
        }
      });
      this.aptTreatItems = selectedOptions;

      if (this.timeDuration < this.duration) {
        this.timeDuration = this.duration;
      }
      if (this.treatmentList.length === 0) {
        this.rmdId = null;
      }
    },
    favAptToggle(data) {
      this.isLoading = true;
      if (data.favorite == false) {
        this.$store
          .dispatch("moduleAppointment/updateTreatmentFavorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else if (data.favorite == true) {
        this.$store
          .dispatch("moduleAppointment/deleteTreatmentFavorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleSelectedFavNote(e) {
      if (!this.note.trim()) {
        this.note = e;
      } else {
        this.note += "\n" + e;
      }
      this.$refs.eventAppointmentNote.focus();
    },
    formatDateTime(date) {
      if (!date) return "N/A";
      return moment(date)?.locale("th").format("llll") || "N/A";
    },
    userName(uid) {
      if (!uid) return "N/A";
      return this.getUserListBy(uid, "uid")?.fullName || "N/A";
    },
    createPatient() {
      this.$emit("createPatient", {
        patient: null,
        appointment: this.appointment,
        openFrom: "UnnamedPtApt",
      });
    },
    selectPatient() {
      this.$emit("selectPatient", {
        openFrom: "UnnamedPtApt",
      });
    },
    // checkRruleIfSameDay(rruleStr) {
    //   if (!this.dateTime) return;
    //   let rule = RRule.fromString(rruleStr);
    //   let momentDateDefault = moment(this.dateTime, "YYYY-MM-DD");

    //   let y = momentDateDefault.year();
    //   let m = momentDateDefault.month() + 1;
    //   let d = momentDateDefault.date();
    //   let checkDate = datetime(y, m, d);

    //   let nextOccurrence = rule.after(checkDate, true); // next rule date including today
    //   let match = moment(nextOccurrence).isSame(momentDateDefault, "day"); // check if 'DAY' is same

    //   return match;
    // },
  },
  watch: {
    tableSelectedList() {
      this.treatmentList = this.tableSelectedList;
    },
    timeDuration(val) {
      this.timeDuration <= 0
        ? (this.timeDuration = this.duration)
        : this.timeDuration;
    },
  },
  mounted() {
    eventBus.$on("applyPt", (e) => {
      this.patient = e;
      this.patientId = e.id;
    });
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>