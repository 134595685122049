<template>
  <b-card
    class="small-text"
    body-class="p-3"
    :border-variant="borderVariant"
    @click="emitClick"
    ><div v-if="isLoading" class="text-center">
      <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <div v-if="countFutureApt">
        <b-card-sub-title class="mb-2">มีนัดครั้งต่อไป</b-card-sub-title>
        <b-card-text
          ><div>{{ formatDateTime(nextApt.date) }}</div>
          <div>แพทย์: {{ nextApt.doctor }}</div>
          <div>เพื่อ: {{ nextApt.title }}</div>
        </b-card-text>
      </div>

      <div v-else>
        <b-card-sub-title class="mb-0 text-center"
          >ไม่มีนัดหมายครั้งต่อไป</b-card-sub-title
        >
      </div>
    </div>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
  name: "NextAppointmentCard",
  props: ["patientId"],
  data() {
    return {
      isLoading: false,
    };
  },
  created() {},
  mounted() {},
  watch: {
    patientId: {
      immediate: true,
      handler() {
        this.handleFetchAptHx();
      },
    },
  },
  computed: {
    ...mapGetters({
      getAptHxTableList: "moduleAppointment/getAptHxTableList",
    }),
    futureApts() {
      return this.getAptHxTableList
        ?.filter((i) => moment(i.date).isAfter(moment(), "day"))
        .sort(
          (a, b) => moment(a.date).format("X") - moment(b.date).format("X")
        );
    },
    countFutureApt() {
      return this.futureApts?.length;
    },
    borderVariant() {
      return this.countFutureApt ? "warning" : "";
    },
    nextApt() {
      return this.futureApts[0];
    },
  },
  methods: {
    ...mapActions({
      fetchAppointmentHistoryList:
        "moduleAppointment/fetchAppointmentHistoryList",
    }),
    async handleFetchAptHx() {
      this.isLoading = true;
      let event = {
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        patientId: this.patientId,
      };
      try {
        await this.fetchAppointmentHistoryList(event);
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
    formatDateTime(date) {
      return moment(date).locale("th").format("llll");
    },
    emitClick(e) {
      this.$emit("click", e);
    },
  },
};
</script>

<style>
</style>