<template>
  <div>
    <div
      v-if="isOrthoCompLoading"
      class="loading position-absolute tl-0 w-100 h-100 z-top"
      style="background-color: rgba(255, 255, 255, 0.5)"
      @click.stop.prevent
    >
      <div class="position-absolute d-flex tl-0 w-100 h-100">
        <div class="m-auto text-center">
          <div style="width: 10rem; height: auto" role="status">
            <b-spinner type="grow" label="Loading..."></b-spinner>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <b-form @submit.prevent>
        <fieldset :disabled="mode === 'edit'">
          <b-form-group
            label-cols="3"
            label="แพทย์:"
            label-align="right"
            label-class="font-weight-bold"
          >
            <b-form-select
              :class="{ 'is-invalid': $v.orthoItem.actionUid.$error }"
              v-model="$v.orthoItem.actionUid.$model"
              :options="orthoDoctorList"
              value-field="uid"
              text-field="fullName"
              :disabled="isDashboardRoute"
              :size="size"
            ></b-form-select>
          </b-form-group>
          <hr />

          <!-- ถ้ายังไม่เคยมี แพ็คเกจ ต้องให้เลือกแพ็กเกจก่อน -->
          <b-collapse :visible="!isPackageAssigned" class>
            <b-form-group
              label-cols-sm="3"
              label="แพ็กเกจจัดฟัน:"
              label-align-sm="right"
            >
              <b-row>
                <b-col>
                  <!-- เลือกแพ็ตเกจให้คนไข้-->
                  <b-form-select
                    :class="{
                      'is-invalid': $v.selectingPackage.packageId.$error,
                    }"
                    v-model="$v.selectingPackage.packageId.$model"
                    :options="getOrthoPackageList"
                    value-field="id"
                    text-field="nameTh"
                    :size="size"
                    :disabled="isOrthoPackageSelected"
                    @input="handleSelectingPackage"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null" disabled
                        >--กรุณาเลือกแพ็กเกจ--</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-col>
                <b-col cols="5">
                  <b-button
                    block
                    class="m-0 d-none d-md-block"
                    :size="size"
                    @click="showPackageDetail"
                    :disabled="selectingPackage.packageId === null"
                  >
                    <i class="fas fa-info-circle"></i>
                    {{ size === "sm" ? "" : "แพ็กเกจ" }}
                  </b-button>
                  <b-button
                    block
                    class="m-0 d-block d-md-none"
                    :size="size"
                    @click="showPackageDetail"
                    :disabled="selectingPackage.packageId === null"
                  >
                    <i class="fas fa-info-circle"></i>
                  </b-button>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols-sm="3"
              label="ราคาแพ็กเกจ:"
              label-align-sm="right"
              description="ราคาแพ็กเกจสามารถเปลี่ยนแปลงได้ตามแพทย์กำหนด"
            >
              <b-form-input
                :class="{
                  'is-invalid': $v.selectingPackage.finalPrice.$error,
                }"
                v-model="selectingPackage.finalPrice"
                trim
                number
                :size="size"
                :disabled="selectingPackage.packageId === null"
                lazy-formatter
                :formatter="toNumber"
                @input="handlePriceAdjustment"
              ></b-form-input>
            </b-form-group>
            <b-row class="d-flex justify-content-end mb-3">
              <b-button
                variant="info"
                class="mr-3"
                :size="size"
                @click="openPackageUserModal(null)"
                >สร้างแพ็กเกจเฉพาะบุคคล</b-button
              >
              <b-button
                variant="primary"
                class="mr-3"
                :size="size"
                @click="debouncedAssignNewPackage"
                >เริ่มแพ็กเกจ</b-button
              >
            </b-row>
          </b-collapse>
        </fieldset>
      </b-form>
      <template v-if="isPackageAssigned">
        <fieldset :disabled="mode === 'edit'">
          <b-form-group
            label-cols-sm="3"
            label="แพ็กเกจปัจจุบัน:"
            label-align-sm="right"
          >
            <b-row>
              <b-col>
                <b-form-input
                  disabled
                  v-model="patientOrthoPackage.nameTh"
                  :size="size"
                ></b-form-input>
              </b-col>
              <b-col cols="5">
                <b-button
                  block
                  class="m-0 d-none d-md-block"
                  :size="size"
                  @click="showStartedPackage(patientOrthoPackage.packageId)"
                >
                  <i class="fas fa-info-circle"></i>
                  {{ size === "sm" ? "" : "แพ็กเกจ" }}
                </b-button>
                <b-button
                  block
                  class="m-0 d-block d-md-none"
                  :size="size"
                  @click="showStartedPackage(patientOrthoPackage.packageId)"
                >
                  <i class="fas fa-info-circle"></i>
                </b-button>
              </b-col>
            </b-row>
          </b-form-group>
          <b-row>
            <b-col offset-sm="3">
              <b-form-group label="ราคาแพ็กเกจ:" :size="size">
                <b-form-input
                  disabled
                  :size="size"
                  v-model="patientOrthoPackage.finalPrice"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="คงเหลือ:" :size="size">
                <b-form-input
                  :class="{ red: getRemainPatientOrthoPackage < 0 }"
                  :size="size"
                  disabled
                  v-model="getRemainPatientOrthoPackage"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-end">
            <b-button
              variant="danger"
              class="mr-3 text-small"
              size="sm"
              @click="deletePatientPackage()"
              >ยกเลิกแพ็กเกจ</b-button
            >

            <b-button
              v-if="
                getRemainPatientOrthoPackage <= 0 &&
                patientOrthoPackage.status == 1
              "
              variant="success"
              class="mr-3"
              :size="size"
              @click="successUserOrthoPackage()"
              >เสร็จสิ้นแพ็กเกจ</b-button
            >
          </b-row>
        </fieldset>
        <hr />
        <b-form @submit.prevent="addOrthoTx">
          <fieldset class="form-row" :disabled="mode === 'edit'">
            <div class="form-group col-md-7">
              <label>การรักษาจัดฟันวันนี้</label>
              <b-form-select
                :class="{
                  'is-invalid': $v.orthoItem.orthoPackageItemId.$error,
                }"
                :size="size"
                v-model="$v.orthoItem.orthoPackageItemId.$model"
                :options="getPatientOrthoPackageItems || []"
                value-field="id"
                text-field="nameTh"
                @input="handleSelectingItem"
              ></b-form-select>
            </div>
            <div class="form-group col-md-5">
              <label>ชำระ</label>
              <b-input-group append="บาท" :size="size">
                <b-form-input
                  :class="{
                    'is-invalid': $v.orthoItem.price.$error,
                  }"
                  v-model="$v.orthoItem.price.$model"
                  number
                  lazy-formatter
                  :formatter="toNumber"
                  placeholder="฿"
                  @keydown.prevent.enter
                ></b-form-input>
              </b-input-group>
            </div>
          </fieldset>
          <b-form-row
            class="mb-2"
            :class="
              size == 'sm' ? 'limit-height-col-73' : 'limit-height-col-110'
            "
          >
            <div class="favorite-text-area-container col pr-1">
              <b-form-textarea
                ref="orthoPackageNote"
                :rows="size === 'sm' ? 3 : 4"
                :size="size"
                placeholder="พิมพ์ข้อมูลเพิ่มเติม เช่น ขนาดลวด, ขั้นตอนที่ทำอยู่..."
                v-model="orthoItem.note"
                no-resize
              ></b-form-textarea>
              <FavNoteButton
                storageName="favNoteOrthoPackage"
                @listClicked="handleSelectedFavNote"
              />
            </div>
          </b-form-row>
          <b-row class="mb-2">
            <b-col>
              <span v-if="indexEdit === -1">
                <b-button
                  type="submit"
                  variant="primary"
                  class="float-right"
                  :size="size"
                  :disabled="mode === 'edit'"
                >
                  เพิ่มรายการจัดฟัน
                  <span v-if="writingMode">
                    <span class="d-inline d-md-none d-lg-inline">
                      <i class="fas fa-angle-double-down"></i>
                    </span>
                    <span class="d-none d-md-inline d-lg-none">
                      <i class="fas fa-angle-double-right"></i>
                    </span>
                  </span>
                  <span v-else>
                    <span class="d-inline d-lg-none">
                      <i class="fas fa-angle-double-down"></i>
                    </span>
                    <span class="d-none d-lg-inline">
                      <i class="fas fa-angle-double-right"></i>
                    </span>
                  </span>
                </b-button>
              </span>
              <span v-else>
                <b-button
                  type="submit"
                  class="btn btn-warning float-right"
                  :size="size"
                  >แก้ไขรายการจัดฟัน</b-button
                >
                <b-button
                  class="btn btn-danger float-right mr-2"
                  :size="size"
                  @click="resetAll"
                  >ยกเลิกแก้ไข</b-button
                >
              </span>
            </b-col>
          </b-row>
        </b-form>
      </template>
    </div>

    <Loading v-if="isLoading"></Loading>
    <Dialog ref="Dialog"></Dialog>
    <PackageUserModal
      ref="PackageUserModal"
      @createUserOrthoPackage="createUserOrthoPackage"
    ></PackageUserModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import { eventBus } from "@/main";

import PackageUserModal from "@/components/modal/PackageUser";
import Loading from "@/components/Loading";
import Dialog from "@/components/modal/Dialog";
import FavNoteButton from "@/components/button/FavNote";
import debounce from "@/utils/debounce";

import "@/theme/modal/PaymentHistory.scss";

export default {
  name: "OrthoPackageInputs",
  components: {
    Loading,
    Dialog,
    PackageUserModal,
    FavNoteButton,
  },
  props: [
    "appointment",
    "indexEdit",
    "items",
    "size",
    "writingMode",
    "mode",
    "patientData",
  ],
  data() {
    return {
      // แพ็กเกจที่กำลังจะสร้างใหม่
      selectingPackage: {
        packageId: null,
        initialPrice: 0,
        additionalExpense: 0,
        finalPrice: null,
        items: [],
      },

      //แพ็กเกจที่คนไข้ใช้อยู่
      patientOrthoPackage: {
        id: null, //id ของแพ้็กเกจที่ assigned เรียบร้อยแล้ว
        packageId: null, //id ของแพ็กเกจที่เลือกตอนสร้าง
        uid: null, //patient ID
        name: "",
        nameEn: "",
        initialPrice: 0,
        additionalExpense: null,
        finalPrice: null,
      },

      orthoItem: {
        id: null,
        actionUid: null,
        treatId: "",
        branchId: "",
        typeId: 2,
        price: null,
        note: "",
        name: "",
        nameEn: "",
        orthoPackageId: null,
        orthoPackageItemId: null,
        df: "",
        dfType: 0,
      },

      extendedProps: {},
      isLoading: false,
      isOrthoCompLoading: false,

      debouncedAssignNewPackage: null,
    };
  },
  validations: {
    selectingPackage: {
      packageId: { required },
      finalPrice: { required },
    },
    userOrthoPackage: {
      packageId: { required },
    },
    orthoItem: {
      actionUid: { required },
      orthoPackageId: { required },
      orthoPackageItemId: { required },
      price: { required },
    },
  },
  created() {
    this.init();
  },
  mounted() {
    eventBus.$on("setDefaultValuePaymentHistroy", () => {
      this.setDefaultPatientOrthoPackage();
      this.setDefaultSelectingPackage();
      this.setDefaultOrthoItem();
      this.vReset();
    });
    this.debouncedAssignNewPackage = debounce(this.assignNewPackage);
  },
  watch: {
    appointment: {
      immediate: true,
      handler() {
        if (this.appointment?.id) {
          this.patient = Object.assign(this.appointment.extendedProps.patient);
          this.orthoItem.actionUid =
            this.appointment.extendedProps.doctor == 5
              ? null
              : this.appointment.extendedProps.doctor;
        }
      },
    },

    indexEdit(val) {
      if (this.indexEdit == -1) {
        this.setDefaultOrthoItem();
        if (this.getPatientOrthoPackageItems?.length > 0)
          this.autoAssignOrthoItemInput();
      }
    },
    "orthoItem.name"(val) {
      eventBus.$emit("treatmentName", val);
    },
  },
  computed: {
    ...mapGetters({
      getOrthoPackageList: "moduleTreatment/getOrthoPackageList",
      getOrthoPackageBy: "moduleTreatment/getOrthoPackageBy",
      getOrthoPackageItemList: "moduleTreatment/getOrthoPackageItemList",
      getOrthoPackageItemBy: "moduleTreatment/getOrthoPackageItemBy",
      getPatientOrthoPackageItems:
        "moduleTreatment/getPatientOrthoPackageItems",
      getPatientOrthoPackageItemBy:
        "moduleTreatment/getPatientOrthoPackageItemBy",

      getDoctorList: "moduleUser/getDoctorList",
      getDoctorListBy: "moduleUser/getDoctorListBy",

      authorization: "moduleUser/getAuthorization",
      isDoctor: "moduleUser/isDoctor",
    }),

    orthoDoctorList() {
      return this.getDoctorList.filter((i) => i.value != 5);
    },

    getRemainPatientOrthoPackage() {
      let remain = 0;
      if (!this.getPatientOrthoPackageItems) return remain;
      this.getPatientOrthoPackageItems.forEach((packageItem) => {
        if (packageItem.balance == null) {
          remain += parseFloat(packageItem.price);
        } else {
          remain += parseFloat(packageItem.balance);
        }
      });
      return remain;
    },

    isPackageAssigned() {
      return this.patientOrthoPackage.id ? true : false;
    },

    isDashboardRoute() {
      return this.$route.name === "Dashboard";
    },

    isOrthoPackageSelected() {
      return Boolean(this.patientOrthoPackage.id);
    },
    addedOrthoTableItemPrice() {
      let total;
      this.items.forEach((item) => {
        if (item.orthoPackageItemId == this.patientOrthoPackage.packageId) {
          total += parseFloat(item.price);
        }
      });
      return total || 0;
    },
  },
  methods: {
    ...mapActions({
      fetchOrthoPackageItemList: "moduleTreatment/fetchOrthoPackageItemList",
      getOrthoPackageUserById: "moduleTreatment/getOrthoPackageUserById",

      fetchOrthoPackageItemActiveList:
        "moduleTreatment/fetchOrthoPackageItemActiveList",
      updateStateOrthoPackageItem:
        "moduleTreatment/updateStateOrthoPackageItem",

      fetchOrthoPackageUserList: "moduleTreatment/fetchOrthoPackageUserList",
    }),
    init() {},

    async initOrthoComp() {
      try {
        this.isOrthoCompLoading = true;
        await this.getPatientOrthoPackage();
      } catch (err) {
        console.error(err);
      } finally {
        this.isOrthoCompLoading = false;
      }
    },

    async addOrthoTx() {
      await this.$nextTick();
      this.$v.$touch();
      if (
        this.$v.orthoItem.orthoPackageId.$invalid ||
        this.$v.orthoItem.orthoPackageItemId.$invalid ||
        this.$v.orthoItem.price.$invalid ||
        this.$v.orthoItem.actionUid.$invalid
      )
        return;

      let packageItemObj = this.getPatientOrthoPackageItemBy(
        this.orthoItem.orthoPackageItemId,
        "id"
      );
      let { price, balance, manyTime } = packageItemObj;

      if (manyTime == null) {
        let newBalance = 0;
        if (balance != null) {
          newBalance = parseFloat(balance) - parseFloat(this.orthoItem.price);
        } else {
          newBalance = parseFloat(price) - parseFloat(this.orthoItem.price);
        }
        this.setUpdateOrthoPackageItem(
          this.orthoItem.orthoPackageItemId,
          true,
          newBalance
        );
      } else if (manyTime == 1) {
        let newBalance = 0;
        if (balance != null) {
          newBalance = parseFloat(balance) - parseFloat(this.orthoItem.price);
        } else {
          newBalance = parseFloat(price) - parseFloat(this.orthoItem.price);
        }
        this.setUpdateOrthoPackageItem(
          this.orthoItem.orthoPackageItemId,
          false,
          newBalance
        );
      }

      this.$emit("addItem", this.orthoItem);
      this.resetAll(this.orthoItem.orthoPackageItemId);
      this.orthoItem.orthoPackageId = this.patientOrthoPackage.id;
    },

    setUpdateOrthoPackageItem(id, status, balance) {
      let item = this.getPatientOrthoPackageItemBy(id, "id");
      item.disabled = status;
      item.balance = balance;
      this.updateStateOrthoPackageItem(item);
    },
    setDefaultOrthoItem() {
      this.orthoItem = Object.assign(
        {},
        {
          id: null,
          treatId: "",
          branchId: "",
          typeId: 2,
          price: 0,
          note: "",
          name: "",
          nameEn: "",
          orthoPackageId: null,
          orthoPackageItemId: null,
          actionUid: this.orthoItem.actionUid,
          df: "",
          dfType: 0,
        }
      );
    },
    setDefaultSelectingPackage() {
      this.selectingPackage = Object.assign(
        {},
        {
          packageId: null,
          initialPrice: 0,
          additionalExpense: 0,
          finalPrice: null,
          items: [],
        }
      );
    },
    resetIndexEdit() {
      this.$emit("update:indexEdit", -1);
    },
    resetAll(itemId) {
      //itemId ส่งมาเพื่อหา next available item
      this.resetIndexEdit();
      this.setDefaultOrthoItem();
      this.autoAssignOrthoItemInput(itemId);
    },

    createUserOrthoPackage(data) {
      this.getPatientOrthoPackage();
      // this.userOrthoPackage = Object.assign({}, data);
      // this.orthoItem.orthoPackageId = this.userOrthoPackage.id;
      // this.getOrthoPackageItemActive();
    },

    deletePatientPackage() {
      this.$refs.Dialog.showAlertConfirm(
        "ยกเลิกแพ็กเกจจัดฟัน ?",
        "ข้อมูลแพ็กเกจจะถูกรีเซ็ตใหม่",
        "warning",
        "ยกเลิกแพ็กเกจ",
        "ไม่ยกเลิก"
      ).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$store
            .dispatch("moduleTreatment/deleteUserOrthoPackage", {
              clinicUrl: this.$route.params.clinicUrl,
              branchUrl: this.$route.params.branchUrl,
              id: this.patientOrthoPackage.id,
            })
            .then((res) => {
              if (res.status == 200) {
                this.$emit(
                  "update:items",
                  this.items.filter((item) => item.typeId != 2)
                );
                this.setDefaultPatientOrthoPackage();
                this.setDefaultOrthoItem();
              }
            })
            .finally(() => {
              this.vReset();
              this.isLoading = false;
            });
        }
      });
    },
    setDefaultPatientOrthoPackage() {
      this.patientOrthoPackage = {
        id: null, //id ของแพ้็กเกจที่ assigned เรียบร้อยแล้ว
        packageId: null, //id ของแพ็กเกจที่เลือกตอนสร้าง
        uid: null, //patient ID
        name: "",
        nameEn: "",
        initialPrice: 0,
        additionalExpense: null,
        finalPrice: null,
        // remain: null,
      };

      this.$store.commit("moduleTreatment/setPatientOrthoPackageItems", []);
    },
    successUserOrthoPackage() {
      this.$refs.Dialog.showAlertConfirm(
        "เสร็จสิ้นแพ็กเกจจัดฟัน ?",
        "หลังจากเสร็จสิ้นแพ็กเกจจัดฟันแล้ว จะไม่สามารถแก้ไข หรือเพิ่มรายการจัดฟัน"
      ).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$store
            .dispatch("moduleTreatment/successUserOrthoPackage", {
              clinicUrl: this.$route.params.clinicUrl,
              branchUrl: this.$route.params.branchUrl,
              patientOrthoPackage: this.patientOrthoPackage,
            })
            .then((res) => {
              if (res.status == 200) {
                this.patientOrthoPackage = Object.assign(
                  {},
                  {
                    id: null,
                    packageId: null,
                    uid: this.patient.id,
                    name: "",
                    nameEn: "",
                    initialPrice: 0,
                    additionalExpense: null,
                    finalPrice: null,
                  }
                );
                this.setDefaultOrthoItem();
                this.vReset();
              }
            })
            .finally(() => {
              this.isLoading = false;
              this.$store.commit(
                "moduleTreatment/setPatientOrthoPackageItems",
                []
              );
            });
        }
      });
    },

    async autoAssignOrthoItemInput(itemId) {
      let arrLength = this.getPatientOrthoPackageItems?.length;
      if (arrLength === 0) return;

      if (typeof itemId === "number") {
        let disabledIndex = this.getPatientOrthoPackageItems
          .map((item, index) => (item.disabled === true ? index : undefined))
          .filter(Number.isFinite);
        let nextIndex =
          this.getPatientOrthoPackageItems.findIndex(
            (item) => item.id === itemId
          ) + 1;
        while (disabledIndex.indexOf(nextIndex) > -1) {
          nextIndex += 1;
        }
        if (nextIndex > arrLength - 1) {
          nextIndex = arrLength - 1;
          await this.handleSelectingItem(itemId); //ถ้าไม่เลือกใหม่จะไม่ได้ trigger @input
        }

        this.orthoItem.orthoPackageItemId =
          this.getPatientOrthoPackageItems[nextIndex].id;
      } else {
        let itemObj = this.getPatientOrthoPackageItems?.find(
          (res) => res.disabled === false
        );
        this.orthoItem.orthoPackageItemId = itemObj?.id;
        await this.handleSelectingItem(itemObj.id);
      }
    },
    openPackageUserModal() {
      this.$refs.PackageUserModal.show(this.patient);
    },
    showPackageDetail() {
      let selectedPackage = this.getOrthoPackageList.find(
        (item) => item.id === this.selectingPackage.packageId
      );
      selectedPackage.items = this.selectingPackage.items;

      let df =
        selectedPackage.dfDoctors.find(
          (doctor) => doctor.doctorUid == this.orthoItem.actionUid
        )?.df ?? selectedPackage.df;

      this.$refs.PackageUserModal.showSelectedPackage(selectedPackage, df);
    },
    showStartedPackage(id) {
      this.isLoading = true;
      this.$store
        .dispatch("moduleTreatment/getOrthoPackageUserById", {
          id,
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
        })
        .then((res) => {
          this.$refs.PackageUserModal.showSelectedPackage(res, res.df);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    async getPatientOrthoPackage() {
      // this.isLoading = true;
      return await this.$store
        .dispatch("moduleTreatment/fetchPatientOrthoPackage", {
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
          patientId: this.patient?.id || this.patientData.id,
        })
        .then(async (res) => {
          if (res.status == 200 && res.data[0]?.orthoPackage) {
            const id = res.data[0]?.id;
            const packageId = res.data[0].orthoPackage?.id;
            const orthoPackage = res.data[0].orthoPackage;

            this.patientOrthoPackage = Object.assign(
              {},
              this.patientOrthoPackage,
              {
                ...orthoPackage,
                id,
                packageId,
              }
            );

            //คำนวนร่าใช้จ่ายทั้งหมด
            let price = 0;
            let remain = 0;
            await this.fetchOrthoPackageItemActiveList({
              clinicUrl: this.$route.params.clinicUrl,
              branchUrl: this.$route.params.branchUrl,
              patientId: this.patient?.id || this.patientData.id,
            }).then(() => {
              this.getPatientOrthoPackageItems.forEach((packageItem) => {
                if (packageItem.manyTime == null) {
                  price += parseFloat(packageItem.price);
                }
              });
            });
            let initialPrice = price;
            let additionalExpense = this.patientOrthoPackage.otherPrice || 0;
            let finalPrice = initialPrice + additionalExpense;
            this.patientOrthoPackage.initialPrice = initialPrice;
            this.patientOrthoPackage.additionalExpense = additionalExpense;
            this.patientOrthoPackage.finalPrice = finalPrice;

            this.orthoItem.orthoPackageId = this.patientOrthoPackage.id;
            this.orthoItem.df = this.patientOrthoPackage.df;
            this.orthoItem.dfType = 0;

            this.autoAssignOrthoItemInput();
          }
          if (res.status == 200 && !res.data) {
            this.userOrthoPackage = Object.assign(
              {},
              {
                id: null,
                packageId: null,
                uid: this.patient.id,
              }
            );
          }
          this.$emit("assignTabindex", this.isPackageAssigned ? 1 : 0);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleSelectingPackage() {
      if (this.selectingPackage.packageId === null) {
        this.selectingPackage.packageId = null;
        this.selectingPackage.initialPrice = 0;
        this.selectingPackage.additionalExpense = 0;
        this.selectingPackage.finalPrice = 0;
        this.selectingPackage.items = [];
        return;
      }

      this.getInitialPackagePrice(this.selectingPackage.packageId).then(
        (price) => {
          this.selectingPackage.initialPrice = price;
          this.selectingPackage.additionalExpense = 0;
          this.selectingPackage.finalPrice = price;
          this.selectingPackage.items = [...this.getOrthoPackageItemList];
        }
      );
    },
    handlePriceAdjustment() {
      if (this.selectingPackage.finalPrice < 0) {
        this.selectingPackage.finalPrice = 0;
      }
      this.selectingPackage.additionalExpense =
        this.selectingPackage.finalPrice - this.selectingPackage.initialPrice;
    },
    async handleSelectingItem(id) {
      await this.$nextTick();
      let itemObj = await this.getPatientOrthoPackageItemBy(id, "id");
      this.orthoItem.name = itemObj.nameTh;
      this.orthoItem.nameEn = itemObj.nameEn;
      this.orthoItem.df = this.patientOrthoPackage.df;

      if (itemObj.balance) {
        this.orthoItem.price = itemObj.balance;
        return;
      }
      if (itemObj.price > 0) {
        this.orthoItem.price = itemObj.price;
        return;
      }
      this.orthoItem.price = 0;
    },
    assignNewPackage() {
      this.$v.selectingPackage.$touch();
      if (this.$v.selectingPackage.$invalid) return;

      this.isLoading = true;
      let packageId = this.selectingPackage.packageId;
      let newPackage = Object.assign(this.getOrthoPackageBy(packageId, "id"));

      //New Pakcage
      //dfDoctor
      let doctorDf = newPackage.dfDoctors.find((item) => {
        return item.doctorUid == this.orthoItem.actionUid;
      });
      if (doctorDf) {
        newPackage.df = doctorDf.df;
      }

      //items
      newPackage.items = this.getOrthoPackageItemList.map((i) => {
        delete i.id;
        delete i.packageId;
        return i;
      });
      newPackage.dfDoctors = newPackage.dfDoctors.map((i) => {
        delete i.id;
        delete i.branchId;
        delete i.packageId;
        return i;
      });
      newPackage.items.push({
        nameTh: "ค่าใช้จ่ายอื่นๆ",
        nameEn: "Other expenses",
        price: this.selectingPackage.additionalExpense,
        manyTime: 1,
      });
      newPackage.otherPrice = this.selectingPackage.additionalExpense;
      delete newPackage.id;
      delete newPackage.branchId;
      delete newPackage.creationDt;
      delete newPackage.modifyDt;
      delete newPackage.del;
      delete newPackage.totalPrice;

      this.$store
        .dispatch("moduleTreatment/createOrthoPackageUser", {
          event: newPackage,
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
        })
        .then(async (res) => {
          if (res.status == 200) {
            let idObj = {
              packageId: res.data,
              uid: this.patient.id,
            };
            await this.assignPatientPackageId(idObj);
            await this.getPatientOrthoPackage();
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async assignPatientPackageId(idObj) {
      await this.$store
        .dispatch("moduleTreatment/assignPatientToOrthoPackage", {
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
          package: idObj,
        })
        .then((res) => {
          if (res.status == 200 && res.data) {
            return res.data;
          }
        })
        .finally(async () => {});
    },
    async assignEditOrthoInputs(item) {
      this.orthoItem.actionUid = item.actionUid;
      this.orthoItem.name = item.nameTh;
      this.orthoItem.nameEn = item.nameEn;
      this.orthoItem.actionUid = item.actionUid;
      this.orthoItem.id = item.id;

      this.orthoItem.orthoPackageId = item.orthoPackageId;
      this.orthoItem.orthoPackageItemId = item.orthoPackageItemId;
      await this.$nextTick();
      this.orthoItem.price = item.price;
      this.orthoItem.note = item.note;
    },
    handleSelectedFavNote(e) {
      if (!this.orthoItem.note.trim()) {
        this.orthoItem.note = e;
      } else {
        this.orthoItem.note += "\n" + e;
      }
      this.$refs.orthoPackageNote.focus();
    },

    //Utils
    async getInitialPackagePrice(packageId) {
      let price = 0;
      await this.fetchOrthoPackageItemList({
        packageId,
      });
      this.getOrthoPackageItemList.forEach((item) => {
        if (item.manyTime == null) {
          price += parseFloat(item.price);
        }
      });
      return price;
    },

    vReset() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$v.$reset();
        });
      });
    },
    toNumber(e) {
      return parseFloat(e) || 0;
    },
  },
};
</script>

<style></style>
