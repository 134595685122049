<template>
  <div>
    <b-modal
      modal-class="component-modal-payment-method"
      id="PaymentMethodModal"
      ref="PaymentMethodModal"
      title="ชำระด้วย"
      hide-footer
    >
      <b-form @submit.prevent>
        <div v-for="(methodCat, index) in paymentMethods" :key="methodCat.id">
          <div v-if="methodCat.mReceiptPayment.length > 1">
            <b-button
              class="mb-2"
              size="sm"
              block
              :variant="getVariant(methodCat.id)"
              v-b-toggle="'my-payment-method-' + index"
              >{{ methodCat.name }}</b-button
            >
            <b-collapse :id="'my-payment-method-' + index">
              <div v-for="method in methodCat.mReceiptPayment" :key="method.id">
                <b-form-group class="mb-2" label-cols="4">
                  <template #label>
                    <b-form-checkbox
                      v-model="method.checked"
                      switch
                      @change="handleCheckbox($event, method)"
                      :disabled="
                        [1, 2, 3, 4, 5, 6].includes(methodCat.id) &&
                        nhsoBookingAvailable
                      "
                      >{{ method.name }}:</b-form-checkbox
                    >
                  </template>
                  <b-row>
                    <b-input-group class="col" append="บาท">
                      <b-form-input
                        lazy
                        class="text-right"
                        type="number"
                        v-model="method.amount"
                        :disabled="!method.checked"
                        @change="checkValue(method)"
                      ></b-form-input>
                    </b-input-group>
                  </b-row>
                </b-form-group>
              </div>
            </b-collapse>
            <hr class="my-2" />
          </div>
          <div v-else>
            <div v-for="method in methodCat.mReceiptPayment" :key="method.id">
              <b-form-group class="mb-0" label-cols="4">
                <template #label>
                  <b-form-checkbox
                    v-model="method.checked"
                    switch
                    @change="handleCheckbox($event, method)"
                    :disabled="
                      (methodCat.id === 6 && !linkedWithKtbPaotang) ||
                      (methodCat.id === 7 && !nhsoBookingAvailable) ||
                      ([1, 2, 3, 4, 5, 6].includes(methodCat.id) &&
                        nhsoBookingAvailable)
                    "
                  >
                    <span>
                      <img
                        v-if="methodCat.id === 6"
                        class="mr-1"
                        style="width: auto; height: 24px"
                        src="@/assets/img/Paotang-small.png"
                      />{{ method.name }}:
                    </span>
                  </b-form-checkbox>
                </template>
                <b-row>
                  <b-input-group class="col" append="บาท">
                    <b-form-input
                      lazy
                      class="text-right"
                      type="number"
                      v-model="method.amount"
                      :disabled="!method.checked"
                      @change="checkValue(method)"
                    ></b-form-input>
                  </b-input-group>
                </b-row>
              </b-form-group>
              <b-alert
                class="mt-2"
                v-if="methodCat.id === 7 && !nhsoBookingAvailable"
                variant="warning"
                show
              >
                ไม่สามารถชำระด้วยวิธีนี้ได้ เนื่องจากไม่ใช่คนไข้ที่เบิกจ่าย
                "โครงการบัตรประชาชนใบเดียว"
              </b-alert>
              <b-alert
                class="mt-2"
                v-else-if="methodCat.id === 7"
                variant="success"
                show
              >
                ชำระด้วยวิธีนี้ได้วิธีเดียว เนื่องจากเป็นคนไข้ที่เบิกจ่ายตาม
                “โครงการบัตรประชาชนใบเดียว”
              </b-alert>
              <hr class="my-2" />
            </div>
          </div>
        </div>

        <b-form-group
          label-cols="4"
          label-align="right"
          label="รวม:"
          :state="totalState"
        >
          <b-input-group append="บาท">
            <b-form-input
              :value="totalPayment"
              :state="totalState"
              number
              readonly
            ></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group label-cols="4" label-align="right" label="ยอดชำระวันนี้:">
          <b-input-group append="บาท">
            <b-form-input class :value="receipt.paid" readonly></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-button
          class="float-right"
          type="button"
          @click="previewReceipt"
          variant="primary"
          >พิมพ์ใบเสร็จ</b-button
        >
      </b-form>
      <PaymentReceiptPreviewModal
        ref="paymentReceiptPreviewModal"
        @afterPrint="closeAllModal"
      ></PaymentReceiptPreviewModal>
      <Loading v-if="isLoading"></Loading>
      <Dialog ref="Dialog"></Dialog>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

import Loading from "@/components/Loading";
import Dialog from "@/components/modal/Dialog";
import PaymentReceiptPreviewModal from "@/components/modal/PaymentReceiptPreviewModal";
export default {
  name: "PaymentMethod",
  components: {
    Loading,
    Dialog,
    PaymentReceiptPreviewModal,
  },
  props: ["appointmentLinkedWithKtbPaotang"],
  data() {
    return {
      isLoading: false,
      paymentMethods: [],
      receipt: {},
      patient: {},
      apptId: null,
      totalPayment: 0,
      AccFee: 0,
      calItem: [],
      methodVariant: {
        1: "secondary",
        2: "danger",
        3: "info",
        4: "success",
        5: "warning",
      },
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  watch: {
    paymentMethods: {
      deep: true,
      handler() {
        this.calTotalPayment();
      },
    },
  },
  computed: {
    ...mapGetters({
      paymentMethodList: "moduleUser/getPaymentMethodList",
      getNhsoBookingNo: "moduleAppointment/getNhsoBookingNo",
    }),

    totalState() {
      return this.receipt.paid === this.totalPayment;
    },
    linkedWithKtbPaotang() {
      return (
        this.patient.linkedWithKtbPaotang &&
        this.appointmentLinkedWithKtbPaotang
      );
    },
    nhsoBookingAvailable() {
      return !!this.getNhsoBookingNo;
    },
  },
  methods: {
    ...mapActions({
      fetchPaymentMethodList: "moduleUser/fetchPaymentMethodList",
    }),
    init() {},
    show({ receipt, patient, appointment }) {
      this.setDefaultValue();
      this.$bvModal.show("PaymentMethodModal");
      this.receipt = Object.assign({}, receipt);
      this.uneditedItem = [...receipt.receiptItems];
      this.patient = Object.assign({}, patient);
      this.apptId = appointment.id;
      this.isLoading = true;
      this.fetchPaymentMethodList({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
      })
        .then(res => {
          this.paymentMethods = res.data.result;
        })
        .finally(() => {
          this.calAccFee();
          this.calProcessingFee();
          this.isLoading = false;
        });
    },
    previewReceipt() {
      if (this.totalPayment != this.receipt.paid) {
        this.$refs.Dialog.showAlertInfo(
          `กรุณาชำระให้ครบ ${this.receipt.paid} บาท`,
          "",
          "warning"
        );
        return;
      }
      this.sumPaymentMethod();
      this.receipt.receiptItems = [...this.calItem];
      this.$refs.paymentReceiptPreviewModal.show(this.receipt, this.patient);
    },
    sumPaymentMethod() {
      let paymentMethodAmount = [],
        cateAmount = [];
      this.paymentMethods.forEach(method => {
        let sum = 0;
        method.mReceiptPayment.forEach(i => {
          if (i.amount) {
            paymentMethodAmount.push({
              receiptPaymentId: i.id,
              amount: i.amount,
            });
            sum = sum + i.amount;
          }
        });
        if (sum) {
          cateAmount.push({
            cateId: method.id,
            cateName: method.name,
            cateNameEn: method.nameEn,
            amount: sum,
          });
        }
      });
      this.receipt.receiptPayment = paymentMethodAmount;
      this.receipt.displayAmount = cateAmount;
    },
    closeAllModal() {
      this.$bvModal.hide("PaymentMethodModal");
      this.$bvModal.hide("PaymentModal");
    },
    handleCheckbox(checked, method) {
      if (!checked) {
        this.$set(method, "amount", null);
        this.$set(method, "checked", false);
      } else {
        this.recalculate(method);
        this.$set(method, "checked", true);
      }
      this.$nextTick(() => {
        this.calAccFee();
        this.calProcessingFee();
      });
    },
    recalculate(method) {
      this.$set(method, "amount", 0);
      this.$nextTick(() => {
        let remain = this.receipt.paid - this.totalPayment;
        if (method?.id === 2) {
          remain = this.setMinMax(remain, 0, 900); //สำหรับกรณีประกันสังคม Set config ???
        }
        this.$set(method, "amount", remain < 0 ? 0 : remain);
      });
    },

    setMinMax(i, min, max) {
      let num = Math.min(Math.max(i, min), max) || 0;
      return +parseFloat(num).toFixed(2);
    },
    checkValue(method) {
      let calAmount = Math.max(method.amount, 0);
      this.$nextTick(() => {
        this.$set(method, "amount", calAmount);
        this.calAccFee();
        this.calProcessingFee();
      });
    },
    calTotalPayment() {
      let sum = 0;
      this.paymentMethods.forEach(method =>
        method.mReceiptPayment.forEach(i => {
          sum += i.amount ? +parseFloat(i.amount).toFixed(2) : 0;
        })
      );
      this.totalPayment = sum;
    },

    //* คำนวน fee แบบเฉลี่ย
    calAccFee() {
      let checked = this.paymentMethodList.filter(i => i.checked && i.amount);
      this.AccFee = checked.reduce((prev, cur) => {
        prev += cur.processingFee * (cur.amount / this.receipt.paid);
        return prev;
      }, 0);
    },

    //* fx ล่างเป็นคำนวนใช้ค่า fee ตัวที่มากที่สุด
    // calAccFee() {
    //   this.AccFee = 0;
    //   let checked = this.paymentMethodList.filter((i) => i.checked && i.amount);
    //   if (checked.length === 0) {
    //     this.isNotifyDfFee = false;
    //   }
    //   if (checked.length === 1) {
    //     this.isNotifyDfFee = false;
    //     this.AccFee = this.AccFee = Math.max.apply(
    //       Math,
    //       checked.map((i) => i.processingFee)
    //     );
    //   }
    //   if (checked.length > 1) {
    //     let fee = checked.filter((i) => i.processingFee);
    //     if (fee.length) {
    //       this.isNotifyDfFee = true;
    //       this.AccFee = Math.max.apply(
    //         Math,
    //         fee.map((i) => i.processingFee)
    //       );
    //     } else {
    //       this.isNotifyDfFee = false;
    //     }
    //   }
    // },
    calProcessingFee() {
      this.calItem = this.uneditedItem.map(i => {
        let processingFeeBaht = this.roundNum((i.paid * this.AccFee) / 100);
        let paidDf = this.roundNum((i.paidDf * (100 - this.AccFee)) / 100);
        return {
          ...i,
          processingFeeBaht,
          paidDf,
          paidCf: i.paid - (processingFeeBaht + paidDf),
        };
      });
    },
    roundNum(num) {
      return Math.round((num + Number.EPSILON) * 100) / 100;
    },
    getVariant(id) {
      return this.methodVariant[id];
    },

    setDefaultValue() {
      this.paymentMethods = [];
      this.receipt = {};
      this.patient = {};
      this.apptId = null;
      this.totalPayment = 0;
      this.AccFee = 0;
    },
  },
};
</script>

<style>
</style>