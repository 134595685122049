<template>
  <b-modal
    header-bg-variant="info"
    size="fluid"
    modal-class="component-modal-datetimeselectfc"
    ref="dateTimeSelectFc"
    id="dateTimeSelectFc"
    hide-footer
    hide-backdrop
    no-close-on-backdrop
    title="คลิกตาราง เพื่อเลือกเวลานัดหมาย"
    scrollable
    @hidden="setDefaultValue"
  >
    <div class="row">
      <div class="col">
        <b-card no-body>
          <b-tabs content-class="pt-0" v-model="tabIndex" small card>
            <b-tab no-body title="เรียงตามห้อง" @click="changeTab">
              <fullCalendar
                class="p-2"
                ref="roomCalendar"
                defaultView="resourceTimeGridDay"
                :themeSystem="themeSystem"
                :defaultDate="dateTime"
                :header="header"
                :titleFormat="titleFormat"
                :height="'parent'"
                :minTime="openingTime"
                :maxTime="closingTime"
                :slotDuration="slotDuration"
                :plugins="calendarPlugins"
                timeZone="Asia/Bangkok"
                :locale="locales"
                :slotLabelFormat="slotLabelFormat"
                :resources="roomList"
                :events="appointmentList"
                :selectable="true"
                :eventLimit="false"
                :nowIndicator="true"
                :schedulerLicenseKey="schedulerLicenseKey"
                :customButtons="customButtons"
                :allDaySlot="false"
                :eventRender="renderCalendarEvents"
                :viewSkeletonRender="onViewSkeletonRender"
                @select="fcResourceClick"
              ></fullCalendar>
            </b-tab>
            <b-tab no-body title="เรียงตามแพทย์" @click="changeTab">
              <fullCalendar
                class="p-2"
                ref="doctorCalendar"
                defaultView="resourceTimeGridDay"
                :themeSystem="themeSystem"
                :defaultDate="dateTime"
                :header="header"
                :titleFormat="titleFormat"
                :height="'parent'"
                :minTime="openingTime"
                :maxTime="closingTime"
                :slotDuration="slotDuration"
                :plugins="calendarPlugins"
                timeZone="Asia/Bangkok"
                :locale="locales"
                :slotLabelFormat="slotLabelFormat"
                :resources="doctorsList"
                :events="appointmentListByDoctors"
                :selectable="true"
                :eventLimit="false"
                :nowIndicator="true"
                :schedulerLicenseKey="schedulerLicenseKey"
                :customButtons="customButtons"
                :allDaySlot="false"
                :eventRender="renderCalendarEvents"
                :viewSkeletonRender="onViewSkeletonRender"
                @select="fcDoctorResourceClick"
              ></fullCalendar>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
      <!-- <b-collapse id="dateTimeCalendar" v-model="isCalendarOpen"> -->
      <div class="col-xl-3 col-lg-4 col-md-5 h100-lg sticky-top">
        <!-- <b-card v-if="patient" body-class="p-2">
          <b-row>
            <b-col cols="3">
              <b-avatar
                class="mb-1"
                :variant="patient.contentId ? 'white' : 'info'"
                :text="abbreviation()"
                alt="User Image"
                size="60px"
                :src="viewFile(patient.contentId)"
                square
                rounded
              ></b-avatar>
            </b-col>
            <b-col cols="9">
              <p class="mb-0 text-bold narrow-spacing">DN: {{ patient.dn }}</p>
              <p
                v-if="patient.existDn"
                class="mb-1 text-bold text-muted description narrow-spacing"
              >
                DN(เดิม): {{ patient.existDn }}
              </p>
              <p class="mb-1">{{ fullName() }}</p>
              <p v-if="patient.congenitalDisease" class="text-danger mb-1">
                โรคประจำตัว:{{ patient.congenitalDisease }}
              </p>
              <p v-if="patient.allergic" class="text-danger mb-1">
                แพ้ยา: {{ patient.allergic }}
              </p>
            </b-col>
          </b-row>
        </b-card> -->

        <b-card body-class="p-2">
          <b-calendar
            class="border rounded"
            v-model="dateTime"
            locale="th-TH"
            :date-disabled-fn="dateDisabled"
            :hide-header="true"
            block
          ></b-calendar>
          <b-form-group
            label="แพทย์:"
            label-cols="2"
            label-cols-md="3"
            label-align-sm="right"
            description="เลือกเพื่อดูวันที่แพทย์ลงตรวจ"
            class="mb-1 mt-2"
          >
            <multiselect
              v-model="doctors"
              :options="getDoctorList"
              multiple
              :close-on-select="false"
              placeholder="ค้นหาแพทย์"
              track-by="uid"
              label="fullName"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              ><span slot="noResult">ไม่พบแพทย์ที่ต้องการค้นหา</span>
              <template slot="clear" slot-scope="">
                <div
                  class="multiselect__clear"
                  v-if="doctors.length"
                  @mousedown.prevent.stop="doctors = []"
                ></div></template></multiselect
          ></b-form-group>
        </b-card>
      </div>

      <!-- <div class="col-3 p-0 text-center sticky-top"></div> -->
      <!-- </b-collapse> -->
    </div>
    <Loading v-if="isLoading"></Loading>
    <Dialog ref="Dialog"></Dialog>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import Loading from "@/components/Loading";
import Dialog from "@/components/modal/Dialog";

import fullCalendar from "@fullcalendar/vue";
import interaction from "@fullcalendar/interaction";
import fcBootstrap from "@fullcalendar/bootstrap";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import locales from "@fullcalendar/core/locales/th";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";

import { datetime, RRule, RRuleSet, rrulestr } from "rrule";
import checkRruleIfSameDay from "../../utils/checkRruleIfSameDay";

import $ from "jquery";

import "@/theme/modal/DateTimeSelectFc.scss";

export default {
  name: "DateTimeSelectFc",
  components: {
    fullCalendar,
    Loading,
    Dialog,
  },
  props: ["patient"],
  data() {
    return {
      isLoading: false,
      themeSystem: "bootstrap",
      dateTime: null,
      schedulerLicenseKey: "0650622495-fcs-1679632464",
      locales: locales,
      slotLabelFormat: {
        hour: "2-digit",
        minute: "2-digit",
        omitZeroMinute: false,
        meridiem: "short",
      },
      header: {
        left: "",
        center: "title",
        right: "prevDay,todayButton,nextDay",
      },
      titleFormat: {
        month: "long",
        year: "2-digit",
        day: "numeric",
        weekday: "short",
      },
      calendarPlugins: [
        interaction,
        resourceTimeGridPlugin,
        fcBootstrap,
        momentTimezonePlugin,
      ],
      appointmentList: [],
      isCalendarOpen: true,
      tabIndex: null,
      doctors: [],
    };
  },
  created() {
    this.init();
  },
  watch: {
    dateTime: function (date) {
      this.getAppointmentListByDate(date);
      this.$nextTick(() => {
        this.getRoomCalendarApi().gotoDate(date);
        this.getDoctorCalendarAPi().gotoDate(date);
      });
    },
  },
  computed: {
    ...mapGetters({
      viewFile: "moduleContent/viewFile",
      roomList: "moduleUser/getRoomList",
      branchInfo: "moduleUser/getBranchInfo",
      getReminderList: "moduleMaster/getReminderList",
      getDoctorList: "moduleUser/getDoctorList",
    }),
    slotDuration() {
      return this.branchInfo?.confApptDuration
        ? this.branchInfo.confApptDuration
        : "00:15";
    },
    openingTime() {
      return this.branchInfo?.confOpeningTime
        ? this.branchInfo.confOpeningTime
        : "09:00";
    },
    closingTime() {
      return this.branchInfo?.confClosingTime
        ? this.branchInfo.confClosingTime
        : "21:00";
    },

    customButtons() {
      return {
        prevDay: {
          text: "prev",
          bootstrapFontAwesome: "fa-chevron-left",
          click: () => {
            this.dateTime = moment(this.dateTime)
              .add(-1, "days")
              .format("YYYY-MM-DD");
          },
        },
        todayButton: {
          text: "วันนี้",
          click: () => {
            this.dateTime = moment().format("YYYY-MM-DD");
          },
        },
        nextDay: {
          text: "next",
          bootstrapFontAwesome: "fa-chevron-right",
          click: () => {
            this.dateTime = moment(this.dateTime)
              .add(1, "days")
              .format("YYYY-MM-DD");
          },
        },
        collapseCalendar: {
          text: "Collapse",
          bootstrapFontAwesome: "angle-double-up",
          click: () => {
            this.isCalendarOpen = !this.isCalendarOpen;
          },
        },
      };
    },
    appointmentListByDoctors() {
      return this.appointmentList.map((apt) => {
        return { ...apt, resourceId: apt.extendedProps.doctor };
      });
    },
    doctorsList() {
      let startTime, endTime;
      let list = this.getDoctorList
        .map(({ uid, fullName, drApptColor, doctorWorkingHours }) => {
          if (
            doctorWorkingHours?.some((i) => {
              startTime = i.startTime;
              endTime = i.endTime;
              // return this.checkRruleIfSameDayMethod(i.rruleStr);
              if (!this.dateTime) return;
              return checkRruleIfSameDay({
                dateObj: moment(this.dateTime, "YYYY-MM-DD"),
                rruleStr: i.rruleStr,
              });
            })
          ) {
            return {
              id: uid,
              title: fullName,
              businessHours: {
                daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // include 0,6 for SUN, SAT
                startTime,
                endTime,
              },
            };
          } else {
            return;
          }
        })
        .filter((i) => i);
      if (list.length === 0) list = [{ id: null, title: "ไม่มีแพทย์ลงตรวจ" }];
      return list;
    },
    doctorWorkingHours() {
      return this.doctors
        ?.map((d) => d.doctorWorkingHours?.map((wh) => wh.rruleStr))
        .flat(2);
    },
  },
  methods: {
    ...mapActions({
      fetchQueueAppointmentList: "moduleAppointment/fetchQueueAppointmentList",
    }),
    init() {},
    show(dateTimedefault) {
      this.$bvModal.show("dateTimeSelectFc");
      this.dateTime = moment(dateTimedefault).format("YYYY-MM-DD");
      this.getAppointmentListByDate(this.dateTime);
    },

    getAppointmentListByDate(date) {
      if (!date) {
        date = this.dateTime;
      }
      this.appointmentList = [];
      this.isLoading = true;
      this.fetchQueueAppointmentList({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        date: date,
        store: false,
      })
        .then(async (res) => {
          this.isLoading = false;
          this.appointmentList = res.data;
          this.fcUpdateSize();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    changeTab() {
      this.saveTabIndex();
      this.fcUpdateSize();
    },
    async saveTabIndex() {
      await this.$nextTick();
      localStorage.setItem("dateTimeSelectTabIndex", this.tabIndex);
    },
    async fcUpdateSize() {
      await this.$nextTick();
      await this.$nextTick();

      this.getRoomCalendarApi().updateSize(); //bug on 1st load
      this.getDoctorCalendarAPi().updateSize(); //bug on 1st load
    },

    renderCalendarEvents(info) {
      const fcTime = info.el.getElementsByClassName("fc-time")[0];
      let eventProps = info.event.extendedProps;

      if (eventProps.patient?.id) {
        fcTime.classList.add("sideStatus");
        info.el.style.borderColor = eventProps.doctorColorBorder;
        info.el.style.backgroundColor = eventProps.doctorColor;
        fcTime.style.backgroundColor = eventProps.statusColor;
      } else {
        info.el.classList.add("fc-note-event");
        info.el.style.backgroundColor = "#5a6268";
        fcTime.style.display = "none";
      }

      if (info.event.id == this.blinkAptId) {
        info.el.classList.add("blink");
        setTimeout(() => info.el.classList.remove("blink"), 6000);
      }
      if (eventProps.rmdId) {
        let rmdObj = this.findReminderObj(eventProps.rmdId);
        $(info.el.querySelector(".fc-title")).prepend(
          `<span class="mr-1 px-1 rounded" style="color: white; background-color: ${rmdObj.desc}">${rmdObj.code}</span>`
        );
      }
    },
    onViewSkeletonRender() {
      $("#calendar .fc-view-container").css({ "overflow-x": "auto" });
      $("#calendar .table-bordered").css(
        "min-width",
        $(".fc-resource-cell").length * 110
      );
    },
    findReminderObj(rmdId) {
      return this.getReminderList.find(function (item) {
        return item["val"] == rmdId;
      });
    },
    fcResourceClick(info) {
      this.$refs.Dialog.show3ButtonsConfirm({
        title: "เลือกเวลา ?",
        text: `${moment(info.startStr).locale("th").format("LLLL")}
        ที่ ${info.resource.title}?`,
        icon: "question",
        confirmMessage: "บันทึกนัดหมายทันที",
        denyMessage: "เลือก",
        confirmButtonColor: "#3085d6",
        denyButtonColor: "#28a745",
        footer:
          "*บันทึกนัดหมายทันที เพื่อบันทึกนัดหมายโดยใข้วัน/เวลา และห้องที่เลือก โดยไม่ต้องการแก้ไขข้อมูลอื่น",
      }).then((res) => {
        if (res.isDismissed) return;
        if (res.isDenied) {
          //กรณีเลือกวันที่
          this.$emit("dateTimeSelect", info.startStr);
          this.$emit("roomId", info.resource.id);
          this.$bvModal.hide("dateTimeSelectFc");
          return;
        }
        if (res.isConfirmed) {
          this.$emit("dateTimeSelect", info.startStr);
          this.$emit("roomId", info.resource.id);
          this.$emit("quickSaveAppointment");
          this.$bvModal.hide("dateTimeSelectFc");
          return;
        }
      });
    },
    fcDoctorResourceClick(info) {
      this.$refs.Dialog.show3ButtonsConfirm({
        title: "เลือกเวลา ?",
        text: `${moment(info.startStr).locale("th").format("LLLL")}
        ที่ ${info.resource.title}?`,
        icon: "question",
        confirmMessage: "บันทึกนัดหมายทันที",
        denyMessage: "เลือก",
        confirmButtonColor: "#3085d6",
        denyButtonColor: "#28a745",
        footer:
          "*บันทึกนัดหมายทันที เพื่อบันทึกนัดหมายโดยใข้วัน/เวลา และแพทย์ที่เลือก โดยไม่ต้องการแก้ไขข้อมูลอื่น",
      }).then((res) => {
        if (res.isDismissed) return;
        if (res.isDenied) {
          //กรณีเลือกวันที่
          this.$emit("dateTimeSelect", info.startStr);
          this.$emit("doctorUid", info.resource.id);
          this.$bvModal.hide("dateTimeSelectFc");
          return;
        }
        if (res.isConfirmed) {
          this.$emit("dateTimeSelect", info.startStr);
          this.$emit("doctorUid", info.resource.id);
          this.$emit("quickSaveAppointment");
          this.$bvModal.hide("dateTimeSelectFc");
          return;
        }
      });
    },

    getRoomCalendarApi() {
      return this.$refs.roomCalendar.getApi();
    },
    getDoctorCalendarAPi() {
      return this.$refs.doctorCalendar.getApi();
    },
    fullName() {
      if (this.patient) {
        var tilte = this.patient.titleNameTh
          ? this.patient.titleNameTh
          : this.patient.titleNameEn
          ? this.patient.titleNameEn
          : "";
        var firstName = this.patient.firstNameTh
          ? this.patient.firstNameTh
          : this.patient.firstNameEn
          ? this.patient.firstNameEn
          : "";
        var lastName = this.patient.lastNameTh
          ? this.patient.lastNameTh
          : this.patient.lastNameEn
          ? this.patient.lastNameEn
          : "";
        var fullName = [tilte, firstName, lastName].join(" ");
        return fullName;
      } else {
        return "";
      }
    },
    abbreviation() {
      if (
        this.patient &&
        (this.patient.firstNameEn || this.patient.lastNameEn)
      ) {
        var firstName = "";
        var lastName = "";
        if (this.patient.firstNameEn) {
          firstName = this.patient.firstNameEn.charAt(0);
        }
        if (this.patient.lastNameEn) {
          lastName = this.patient.lastNameEn.charAt(0);
        }
        return `${firstName}${lastName}`;
      } else {
        return "";
      }
    },
    // checkRruleIfSameDayMethod(rruleStr) {
    //   if (!this.dateTime) return;
    //   let rule = RRule.fromString(rruleStr);
    //   let momentDateDefault = moment(this.dateTime, "YYYY-MM-DD");

    //   let y = momentDateDefault.year();
    //   let m = momentDateDefault.month() + 1;
    //   let d = momentDateDefault.date();
    //   let checkDate = datetime(y, m, d);

    //   let nextOccurrence = rule.after(checkDate, true); // next rule date including today
    //   let match = moment(nextOccurrence).isSame(momentDateDefault, "day"); // check if 'DAY' is same

    //   return match;
    // },
    dateDisabled(ymd, date) {
      if (this.doctors.length === 0) return false;
      const dateObj = moment(date);
      return !this.doctorWorkingHours.some((rruleStr) => {
        return checkRruleIfSameDay({
          dateObj,
          rruleStr,
        });
      });
    },
    setDefaultValue() {
      this.doctors = [];
    },
  },
  async mounted() {
    await this.$nextTick();
    this.tabIndex =
      parseInt(localStorage.getItem("dateTimeSelectTabIndex")) || 0;
  },
  updated() {
    //bug on 1st load
    // this.getRoomCalendarApi().updateSize();
  },
};
</script>

<style>
</style>