<template>
  <b-list-group
    v-if="recentPatientsList.length > 0"
    id="recent-patient"
    class="small text-small ml-1"
  >
    <div class="description text-bold">เรียกดูล่าสุด:</div>
    <b-list-group-item
      class="recent-patient-item p-1"
      v-for="(patient, key) in recentPatientsList"
      :key="key"
      :data-id="patient.id"
      button
      ><span :data-id="patient.id"> {{ patient.fullName }} </span>
      <span
        :data-id="patient.id"
        class="badge badge-primary badge-pill float-right"
        >{{ patient.diffTime }}</span
      >
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/main";
import moment from "moment";
import $ from "jquery";

export default {
  name: "RecentPatients",
  components: {},
  data() {
    return {
      refreshKey: 0,
    };
  },
  computed: {
    ...mapGetters({
      getRecentPatients: "modulePatient/getRecentPatients",
    }),
    recentPatientsList() {
      this.refreshKey; // force update data
      let result = new Array(5);
      return this.getRecentPatients.map((i) => {
        return { diffTime: this.compareTime(i.timestamp), ...i };
      });
    },
  },
  methods: {
    ...mapActions({
      addRecentPatient: "modulePatient/addRecentPatient",
    }),
    compareTime(momentObj) {
      if (!momentObj) return;
      let diffDurarion = moment.duration(moment().diff(momentObj));
      const days = diffDurarion.days();
      if (days) {
        return `${days} วัน`;
      }
      const hours = diffDurarion.hours();
      if (hours) {
        return `${hours} ชั่วโมง`;
      }
      const minutes = diffDurarion.minutes();
      if (minutes) {
        return `${minutes} นาที`;
      }
      const seconds = diffDurarion.seconds();
      if (seconds) {
        return `${seconds} วินาที`;
      }
      return "";
    },
    updateTime() {
      this.refreshKey++;
    },
    onJQuery() {
      const self = this;
      $(document).on("click", ".recent-patient-item", async (e) => {
        const patientId = e.target.dataset.id;
        eventBus.$emit("openProfileCustomerModal", null, { id: patientId });
        await this.$nextTick();
        eventBus.$emit("hidePopover");
      });
    },

    offJQuery() {
      $(document).off("click", ".recent-patient-item");
    },
  },
  mounted() {
    this.onJQuery();
  },
  destroyed() {
    this.offJQuery();
  },
};
</script>

<style>
</style>