<template>
  <b-modal
    modal-class="component-modal-calendarNote"
    id="CalendarNoteModal"
    ref="CalendarNoteModal"
    size="lg"
    title="โน๊ตปฏิทิน"
    hide-footer
    no-close-on-backdrop
    @hidden="setDefaultEvent"
  >
    <div class="row">
      <div class="col">
        <b-form @submit.prevent="validateApp()">
          <b-form-row>
            <b-form-group
              class="col mb-2"
              label-cols-sm="2"
              label-align-sm="right"
              label="เวลา:"
              label-class="font-weight-bold"
            >
              <b-input-group>
                <datetime
                  class="col p-0"
                  type="datetime"
                  v-model="$v.dateTimeDefault.$model"
                  input-class="form-control my-0"
                  :input-style="dateTimeInputStyle"
                  format="cccc dd LLLL yyyy / HH:mm"
                  value-zone="UTC+7"
                  :min-datetime="startToday"
                  :phrases="{ ok: 'ตกลง', cancel: 'ยกเลิก' }"
                  :hour-step="1"
                  :minute-step="duration"
                  :week-start="7"
                  auto
                ></datetime>
                <b-input-group-append>
                  <b-button variant="info" @click="openDateTimeSelectFc"
                    >ตารางนัด</b-button
                  >
                </b-input-group-append>
              </b-input-group>

              <template v-slot:description>
                <span v-if="$v.dateTimeDefault.$error" class="error"
                  >กรุณาเลือกภายในเวลาทำการ ({{
                    getBranchInfo.confOpeningTime
                  }}-{{ getBranchInfo.confClosingTime }})
                  แก้ไขเวลาทำการได้ในเมนู "การตั้งค่า"</span
                >
                <span v-else
                  >สามารถเลือกวันเวลา และห้องที่ว่าง จากตารางนัดได้</span
                >
              </template>
            </b-form-group>
          </b-form-row>
          <b-form-row>
            <b-form-group
              class="col mb-2"
              label-cols-sm="2"
              label-align-sm="right"
              label="ระยะเวลา:"
              label-class="font-weight-bold"
            >
              <b-input-group append="นาที">
                <b-form-input
                  type="range"
                  v-model="timeDuration"
                  :min="duration"
                  max="360"
                  :step="duration"
                ></b-form-input>
                <b-form-input
                  class="col-2 px-0 text-center"
                  v-model="timeDuration"
                  lazy
                  type="number"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-form-row>

          <hr class="my-2" />

          <b-form-row class="">
            <b-form-group class="col" label="แพทย์">
              <b-form-select
                :class="{ 'is-invalid': $v.doctor.$error }"
                id="doctor"
                v-model="$v.doctor.$model"
                :options="getDoctorList"
                value-field="uid"
                text-field="fullName"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null"
                    >รายชื่อแพทย์</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group class="col" label="ห้อง">
              <b-form-select
                :class="{ 'is-invalid': $v.roomId.$error }"
                id="resource"
                v-model="$v.roomId.$model"
                :options="getRoomList"
                value-field="id"
                text-field="title"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled
                    >เลือกห้อง</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-form-row>

          <b-form-row>
            <div class="col-12">
              <b-form-group
                description="บันทึกข้อมูลโน๊ตเพิ่มเติม เช่น แพทย์ลาพักร้อน เป็นต้น"
              >
                <b-form-textarea
                  :class="{ 'is-invalid': $v.note.$error }"
                  id="note"
                  rows="3"
                  no-resize
                  v-model="$v.note.$model"
                  trim
                  placeholder="รายละเอียดโน๊ตเพิ่มเติม..."
                ></b-form-textarea>
              </b-form-group>
            </div>
          </b-form-row>
          <b-row class="d-flex flex-row-reverse">
            <div>
              <b-button class="ml-2 mr-3" type="submit" variant="primary">{{
                appointment && appointment.id ? "แก้ไขนัดโน๊ต" : "สร้างโน๊ต"
              }}</b-button>
            </div>
            <div v-if="appointment && appointment.id">
              <b-button variant="danger" @click="deleteEventAppointment()"
                >ลบโน๊ต</b-button
              >
            </div>
            <!-- <div v-if="appointment && appointment.id" class="mr-auto ml-3">
              <b-button variant="info" @click="createNewPatient"
                >เปลี่ยนเป็นนัดหมาย</b-button
              >
                <p class="description">คลิกเพื่อสร้างประวัติคนไข้ใหม่</p>
            </div> -->
          </b-row>
        </b-form>
      </div>
    </div>

    <DateTimeSelectFc
      ref="dateTimeSelectFc"
      @dateTimeSelect="dateTimeDefault = $event"
      @roomId="roomId = $event"
      @quickSaveAppointment="quickSaveAppointment"
    ></DateTimeSelectFc>
    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
  </b-modal>
</template>

<script>
import DateTimeSelectFc from "@/components/modal/DateTimeSelectFc";
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import moment from "moment";
import { eventBus } from "@/main";

import { mapActions, mapGetters } from "vuex";

//vuelidate
import { required } from "vuelidate/lib/validators";

import "@/theme/modal/Event.scss";

export default {
  name: "EventModal",
  components: {
    Dialog,
    Loading,
    DateTimeSelectFc,
  },
  data() {
    return {
      isLoading: false,
      appointment: null,
      id: null,
      doctor: null,
      patient: null,
      roomId: null,
      dateTimeDefault: "",
      aptTreatItems: [],
      rmdId: null,
      minMinute: this.duration || 15, //config data
      note: "",
      timeDuration: this.duration * 2 || 30,
    };
  },
  validations: {
    dateTimeDefault: {
      required,
      isBetweenTime(dateTime) {
        const dateTimeStr = moment(dateTime).format("HH:mm");
        const convertTime = (str) => moment(str, "HH:mm");
        return moment(convertTime(dateTimeStr)).isBetween(
          convertTime(this.getBranchInfo.confOpeningTime || "09:00"),
          convertTime(this.getBranchInfo.confClosingTime || "21:00"),
          "minutes",
          "[)"
        );
      },
    },
    doctor: {},
    roomId: { required },
    note: { required },
  },
  computed: {
    ...mapGetters({
      getBranchInfo: "moduleUser/getBranchInfo",
      getRoomList: "moduleUser/getRoomList",
      getRoomListBy: "moduleUser/getRoomListBy",
      getDoctorList: "moduleUser/getDoctorList",

      viewAvatar: "moduleContent/viewAvatar",
    }),
    startToday() {
      return moment().startOf("day").format();
    },
    dateTimeInputStyle() {
      return this.$v.dateTimeDefault.$error
        ? "border-color: #dc3545; border-radius: 0.25rem 0 0 0.25rem"
        : "";
    },
    duration() {
      return moment
        .duration(
          this.getBranchInfo?.confApptDuration
            ? this.getBranchInfo.confApptDuration
            : "00:15"
        )
        .asMinutes();
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions({}),
    init() {},
    show(appointment) {
      this.appointment = { ...appointment };
      if (appointment) {
        var startTime = this.roundedTime(appointment.start);
        this.dateTimeDefault = startTime ?? "";
        this.roomId = appointment.roomId ?? null;
        this.doctor = appointment.doctor ?? null;

        if (appointment.id || appointment.extendedProps) {
          var eventProps = appointment.extendedProps;
          this.id = appointment.id;

          // this.patient = eventProps.patient;
          this.aptTreatItems = eventProps.treatmentList;
          this.doctor = eventProps.doctor;
          this.roomId = eventProps.roomId;
          this.note = eventProps.note;
          this.treatmentList = [];
          this.rmdId = eventProps.rmdId || null;
          this.aptTreatItems?.forEach((res) => {
            this.treatmentList.push(this.getTreatmentBy(res, "id"));
          });
        }
      } else {
        this.dateTimeDefault = this.roundedTime();
      }
      this.$nextTick(() => {
        this.timeDuration =
          appointment?.extendedProps?.timeDuration > this.duration
            ? appointment.extendedProps.timeDuration
            : this.duration * 2;
      });
      this.$bvModal.show("CalendarNoteModal");
      this.$v.$reset();
    },
    saveEventAppointment() {
      const startDt = this.dateTimeDefault;
      const endDt = moment(startDt).add(this.timeDuration, "minutes").format();

      let eventData = {
        id: this.id,
        startDt: startDt,
        endDt: endDt,
        roomId: parseInt(this.roomId),
        aptTreatItems: this.aptTreatItems,
        rmdId: this.rmdId,
        doctorUid: parseInt(this.doctor),
        // patientId: 1,
        note: this.note,
      };

      if (!this.id) {
        delete eventData.id;
        this.isLoading = true;
        this.$store
          .dispatch("moduleAppointment/createQueueAppointment", {
            event: eventData,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .then((res) => {
            if (res.status == "200") {
              this.$emit("blinkAptId", res.data.id);
              if (this.openFrom != "paymentModal") {
                this.$emit("goToDateTime", this.dateTimeDefault);
              }
              eventBus.$emit("nextVisitCreated", res.data);
              this.$bvModal.hide("CalendarNoteModal");
              this.isLoading = false;
              this.$parent.showDialogToast(
                "success",
                `สร้างโน๊ตใหม่ ${moment(startDt).locale("th").format("LLLL")}
                  ที่ ${this.getRoomListBy(eventData.roomId, "id").name}`
              );
              this.setDefaultEvent();
            } else {
              this.isLoading = false;
              this.$parent.showDialogToast("warning", "สร้างโน๊ตไม่สำเร็จ");
            }
          })
          .catch(() => {
            this.isLoading = false;
          });
      } else {
        this.$refs.Dialog.showAlertConfirm(
          "แก้ไขโน๊ต ?",
          "คุณต้องการแก้ไขโน๊ต",
          null,
          "ยืนยันการแก้ไข",
          "ยกเลิก"
        ).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.$store
              .dispatch("moduleAppointment/updateQueueAppointment", {
                event: eventData,
                clinicUrl: this.$route.params.clinicUrl,
                branchUrl: this.$route.params.branchUrl,
              })
              .then((res) => {
                if (res.status == "200") {
                  this.$emit("blinkAptId", res.data.id);
                  if (this.openFrom != "paymentModal") {
                    this.$emit("goToDateTime", this.dateTimeDefault);
                  }
                  eventBus.$emit("nextVisitEdited", res.data);
                  this.$bvModal.hide("CalendarNoteModal");
                  this.isLoading = false;
                  this.$parent.showDialogToast("success", "แก้ไขโน๊ตเรียบร้อย");
                  // this.$parent.showDialogToast(
                  //   "success",
                  //   `แก้ไขโน๊ตไป ${moment(startDt)
                  //     .locale("th")
                  //     .format("LLLL")}
                  // ที่ ${this.getRoomListBy(eventData.roomId, "id").name}`
                  // );
                  this.setDefaultEvent();
                } else {
                  this.isLoading = false;
                  this.$parent.showDialogToast("warning", "แก้ไขโน๊ตไม่สำเร็จ");
                }
              })
              .catch(() => {
                this.isLoading = false;
              });
          }
        });
      }
    },
    quickSaveAppointment() {
      this.$v.$touch();
      if (this.$v.$error) return;
      const startDt = this.dateTimeDefault;
      const endDt = moment(startDt).add(this.timeDuration, "minutes").format();

      let eventData = {
        id: this.id,
        startDt: startDt,
        endDt: endDt,
        roomId: parseInt(this.roomId),
        aptTreatItems: this.aptTreatItems,
        rmdId: this.rmdId,
        doctorUid: parseInt(this.doctor),
        patientId: this.patient.id,
        note: this.note,
      };
      if (!this.id) {
        delete eventData.id;
        this.isLoading = true;
        this.$store
          .dispatch("moduleAppointment/createQueueAppointment", {
            event: eventData,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .then((res) => {
            if (res.status == "200") {
              this.$emit("goToDateTime", this.dateTimeDefault);
              if (this.openFrom != "paymentModal") {
                this.$emit("blinkAptId", res.data.id);
              }
              eventBus.$emit("aptCreated", res.data);
              this.$bvModal.hide("CalendarNoteModal");
              this.isLoading = false;
              this.$parent.showDialogToast(
                "success",
                `สร้างโน๊ตใหม่ ${moment(startDt).locale("th").format("LLLL")}
                  ที่ ${this.getRoomListBy(eventData.roomId, "id").name}`
              );
              this.setDefaultEvent();
            } else {
              this.isLoading = false;
              this.$parent.showDialogToast("warning", "สร้างโน๊ตไม่สำเร็จ");
            }
          })
          .catch(() => {
            this.isLoading = false;
          });
      } else {
        this.isLoading = true;
        this.$store
          .dispatch("moduleAppointment/updateQueueAppointment", {
            event: eventData,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .then((res) => {
            if (res.status == "200") {
              this.$emit("blinkAptId", res.data.id);
              if (this.openFrom != "paymentModal") {
                this.$emit("goToDateTime", this.dateTimeDefault);
              }
              eventBus.$emit("aptCreated", res.data);
              this.$bvModal.hide("CalendarNoteModal");
              this.isLoading = false;
              this.$parent.showDialogToast("success", "แก้ไขโน๊ตเรียบร้อย");

              this.setDefaultEvent();
            } else {
              this.isLoading = false;
              this.$parent.showDialogToast("warning", "แก้ไขโน๊ตไม่สำเร็จ");
            }
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },
    deleteEventAppointment() {
      this.$refs.Dialog.showAlertConfirm(
        "ลบโน๊คนี้ ?",
        "คุณต้องการลบโน็ตนี้"
      ).then((result) => {
        if (result.value) {
          this.isLoading = true;
          let branchId = this.getBranchInfo.id;
          let eventData = {
            branchId,
            id: this.id,
          };
          this.$store
            .dispatch("moduleAppointment/deleteQueueAppointment", {
              event: eventData,
              clinicUrl: this.$route.params.clinicUrl,
              branchUrl: this.$route.params.branchUrl,
            })
            .then((res) => {
              if (res.status == "200") {
                this.$bvModal.hide("CalendarNoteModal");
                this.setDefaultEvent();
              }
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
            });
        }
      });
    },

    createNewPatient() {
      this.$emit("openCustomerModal");
    },
    setDefaultEvent() {
      this.appointment = null;
      this.id = null;
      this.doctor = null;
      this.roomId = null;
      this.dateTimeDefault = "";
      this.note = "";
      this.timeDuration = this.duration;
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$v.$reset();
        });
      });
    },
    openDateTimeSelectFc() {
      this.$refs.dateTimeSelectFc.show(this.dateTimeDefault);
    },
    validateApp() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.saveEventAppointment();
    },
    roundedTime(time) {
      const mins = moment
        .duration(
          this.getBranchInfo?.confApptDuration
            ? this.getBranchInfo.confApptDuration
            : "00:15"
        )
        .asMinutes();
      const rounded = Math.ceil(moment(time).minute() / mins) * mins;
      return moment(time).clone().minute(rounded).second(0).format();
    },
    autoInput() {
      this.timeDuration = 0;
      let selectedOptions = [];
      this.treatmentList.forEach((val) => {
        this.timeDuration += val.durationMin;
        selectedOptions.push(val.id);

        if (!this.rmdId) {
          this.rmdId = val.rmdId;
        }
      });
      this.aptTreatItems = selectedOptions;

      if (this.timeDuration < this.minMinute) {
        this.timeDuration = this.minMinute;
      }
      if (this.treatmentList.length === 0) {
        this.rmdId = null;
      }
    },
  },
  watch: {
    timeDuration(val) {
      this.timeDuration <= 0
        ? (this.timeDuration = this.duration)
        : this.timeDuration;
    },
  },
};
</script>

<style>
</style>