<template>
  <div>
    <div class="container">
      <b-form @submit.prevent="addDrug">
        <fieldset :disabled="mode === 'edit'">
          <b-form-group
            label-cols="3"
            label="ผู้ขาย:"
            label-align="right"
            label-class="font-weight-bold"
          >
            <b-form-select
              :class="{ 'is-invalid': $v.actionUid.$error }"
              v-model="$v.actionUid.$model"
              :options="getUserList"
              value-field="uid"
              text-field="fullName"
              :size="size"
              :disabled="isDashboardRoute"
            ></b-form-select>
          </b-form-group>
          <hr />
          <div class="form-row">
            <div class="col-md-12">
              <b-form-group>
                <b-input-group>
                  <multiselect
                    class="col p-0"
                    :class="{
                      'is-invalid': $v.itemList.$error,
                      'multiselect-black-border': !$v.itemList.$error,
                      'multiselect-md-input': size != 'sm',
                    }"
                    v-model="$v.itemList.$model"
                    :options="getGroupedItemList"
                    :multiple="false"
                    group-values="libs"
                    group-label="category"
                    :group-select="false"
                    placeholder="พิมพ์ค้นหารายการยาและอุปกรณ์"
                    :custom-label="customTxDrugsLabel"
                    track-by="nameTh"
                    selectLabel=""
                    selectedLabel=""
                    deselectLabel=""
                    :disabled="mode === 'edit'"
                    @input="$refs.drugItemQuantity.$el.focus()"
                  >
                    <template #option="props">
                      <span v-if="props.option.$isLabel">{{
                        props.option.$groupLabel
                      }}</span>
                      <span v-else>
                        <b-button
                          class="mr-1 small"
                          size="xs"
                          variant="outline-info"
                          :pressed="props.option.favorite"
                          @click.stop="favItemtoggle(props.option)"
                        >
                          <i class="far fa-star"></i> </b-button
                        >{{ customTxDrugsLabel(props.option) }}
                      </span>
                    </template>
                    <span slot="noResult">ไม่พบรายการ กรุณาเปลี่ยนคำค้นหา</span>
                  </multiselect>
                  <b-input-group-append>
                    <b-button variant="info" @click="openItemModal" :size="size"
                      >Drugs list</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
                <template v-slot:description>
                  <div v-if="$v.itemList.$error" class="error">
                    *กรุณาลงข้อมูลยา และอุปกรณ์
                  </div>
                  <div v-else>ตั้งรายการใช้ประจำได้ใน 'Drugs list'</div>
                </template>
              </b-form-group>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col mb-0">
              <b-input-group append="จำนวน" :size="size">
                <b-form-input
                  ref="drugItemQuantity"
                  :class="{
                    'is-invalid': $v.quantity.$error,
                  }"
                  :size="size"
                  type="number"
                  number
                  :formatter="toNumber"
                  lazy-formatter
                  v-model="$v.quantity.$model"
                ></b-form-input>
              </b-input-group>
            </div>
            <div class="form-group col mb-0">
              <b-input-group append="บาท" :size="size">
                <b-form-input
                  :class="{ 'is-invalid': $v.price.$error }"
                  number
                  placeholder="฿"
                  lazy-formatter
                  :formatter="toNumber"
                  :size="size"
                  v-model="$v.price.$model"
                ></b-form-input>
              </b-input-group>
            </div>
          </div>
          <div
            class="description"
            :class="{
              error: $v.price.$error || $v.quantity.$error,
            }"
          >
            *กรุณาลงจำนวน และราคา
          </div>
        </fieldset>
        <b-form-row
          class="mb-2"
          :class="size == 'sm' ? 'limit-height-col-73' : 'limit-height-col-110'"
        >
          <div class="favorite-text-area-container col pr-1">
            <b-form-textarea
              ref="drugsNoteInput"
              :rows="size === 'sm' ? 3 : 4"
              :size="size"
              v-model="note"
              placeholder="โน๊ตเพิ่มเติม..."
              no-resize
            ></b-form-textarea>
            <FavNoteButton
              storageName="favNoteDrugs"
              @listClicked="handleSelectedFavNote"
            />
          </div>
        </b-form-row>
        <b-row class="mb-2">
          <b-col>
            <span v-if="indexEdit === -1">
              <b-button
                type="submit"
                :size="size"
                variant="primary"
                class="float-right"
                :disabled="mode === 'edit'"
              >
                เพิ่มรายการยา และอุปกรณ์
                <span v-if="writingMode">
                  <span class="d-inline d-md-none d-lg-inline">
                    <i class="fas fa-angle-double-down"></i>
                  </span>
                  <span class="d-none d-md-inline d-lg-none">
                    <i class="fas fa-angle-double-right"></i>
                  </span>
                </span>
                <span v-else>
                  <span class="d-inline d-lg-none">
                    <i class="fas fa-angle-double-down"></i>
                  </span>
                  <span class="d-none d-lg-inline">
                    <i class="fas fa-angle-double-right"></i>
                  </span>
                </span>
              </b-button>
              <!-- <b-button
                class="btn btn-danger float-right mr-2"
                :size="size"
                @click="setDefaultDrugItem"
              >
                รีเซ็ต
              </b-button> -->
            </span>
            <span v-else>
              <b-button
                type="submit"
                :size="size"
                class="btn btn-warning float-right"
              >
                แก้ไขรายการ
              </b-button>
              <b-button
                class="btn btn-danger float-right mr-2"
                :size="size"
                @click="setDefaultDrugItem"
              >
                ยกเลิกแก้ไข
              </b-button>
            </span>
          </b-col>
        </b-row>
      </b-form>
    </div>

    <ItemListModal
      ref="itemListModal"
      @selectedItem="tableSelectedItemList = $event"
    ></ItemListModal>
    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import { eventBus } from "@/main";

import ItemListModal from "@/components/modal/ItemList";
import Loading from "@/components/Loading";
import FavNoteButton from "@/components/button/FavNote";

import numeral from "numeral";

import "@/theme/modal/PaymentHistory.scss";

export default {
  name: "DrugsAndGoodsInputs",
  props: ["appointment", "indexEdit", "size", "writingMode", "mode"],
  components: {
    ItemListModal,
    FavNoteButton,
    Loading,
  },
  data() {
    return {
      actionUid: null,

      tableSelectedItemList: [],
      itemList: null,
      itemId: null,
      name: "",
      nameEn: "",
      quantity: 1,

      price: 0,
      note: "",

      id: null,
      treatId: "",
      branchId: "",
      typeId: 3,
      unitPrice: 0,
      df: null,
      dfType: null,

      // drugItem: {
      //   id: null,
      //   treatId: "",
      //   branchId: "",
      //   typeId: 3,
      //   itemId: "",
      //   price: null,
      //   note: "",
      //   quantity: 1,
      //   unitPrice: 0,
      //   name: "",
      //   actionUid: null,
      //   df: "",
      //   dfType: "",
      // },

      extendedProps: {},
      isLoading: false,
    };
  },
  validations: {
    itemList: { required },
    quantity: { required },
    price: { required },
    actionUid: { required },
  },
  created() {
    this.init();
  },
  mounted() {
    eventBus.$on("setDefaultValuePaymentHistroy", this.setDefaultDrugItem);

    // this.extendedProps = Object.assign(this.appointment.extendedProps);
    // this.patient = Object.assign(this.extendedProps.patient);
    // this.appointmentId = this.appointment.id;
    // this.actionUid = this.extendedProps.doctor;
  },
  watch: {
    appointment: {
      immediate: true,
      handler() {
        this.actionUid = this.appointment?.extendedProps?.doctor;
      },
    },
    tableSelectedItemList() {
      this.itemList = this.tableSelectedItemList;
    },
    itemList() {
      if (this.itemList) {
        this.itemId = this.itemList.id;
        this.price = this.itemList.price;
        this.name = this.itemList.nameTh;
        this.nameEn = this.itemList.nameEn;
        this.df = this.actionUid == 5 ? 0 : this.itemList.df;
        this.dfType = this.itemList.dfType;
      } else {
        this.itemId = null;
        this.price = 0;
        this.name = "";
        this.nameEn = "";
        this.df = null;
        this.dfType = null;
      }
    },
    quantity(val) {
      try {
        if (val && this.itemId) {
          let price = this.getItemBy(this.itemId, "id").price;
          this.price = price * val;
        }
        this.unitPrice = (this.price / this.quantity).toFixed(0);
      } catch (err) {
        console.error(err);
      }
    },
    indexEdit(val) {
      if (this.indexEdit == -1) this.setDefaultDrugItem();
    },
  },
  computed: {
    ...mapGetters({
      getItemList: "moduleAppointment/getItemList",
      getItemBy: "moduleAppointment/getItemBy",
      getGroupedItemList: "moduleAppointment/getGroupedItemList", //ย้าย grouping เข้าไปใน appointment module
      getItemCateList: "moduleAppointment/getItemCateList",
      getItemCateBy: "moduleAppointment/getItemCateBy",

      getUserInfo: "moduleUser/getUserInfo",
      getUserList: "moduleUser/getUserList",
      getUserListBy: "moduleUser/getUserListBy",

      authorization: "moduleUser/getAuthorization",
      isDoctor: "moduleUser/isDoctor",
    }),

    isDashboardRoute() {
      return this.$route.name === "Dashboard";
    },
  },
  methods: {
    ...mapActions({}),
    init() {},

    openItemModal() {
      this.$refs.itemListModal.show(this.itemList);
    },

    //*Drug method
    async addDrug() {
      await this.$nextTick();
      this.$v.$touch();
      if (
        this.$v.itemList.$invalid ||
        this.$v.quantity.$invalid ||
        this.$v.price.$invalid ||
        this.$v.actionUid.$invalid
      )
        return;

      let drugsObj = {
        id: this.id,
        treatId: this.treatId,
        branchId: "",
        typeId: 3,
        itemId: this.itemId,
        price: this.price,
        note: this.note,
        quantity: this.quantity,
        unitPrice: Number(this.unitPrice),
        name: this.name,
        nameEn: this.nameEn,
        actionUid: this.actionUid,
        df: this.df,
        dfType: this.dfType,
      };
      this.$emit("addItem", drugsObj);
      this.resetAll();
    },

    setDefaultDrugItem() {
      this.tableSelectedItemList = [];
      this.itemList = null;
      this.itemId = null;
      this.name = "";

      this.quantity = 1;

      this.price = 0;
      this.note = "";

      this.id = null;
      this.treatId = "";
      this.branchId = "";
      this.typeId = 3;
      this.unitPrice = null;
      this.df = null;
      this.dfType = null;

      this.vReset();
    },

    assignDrugsInputs(item) {
      this.actionUid = item.actionUid;
      this.id = item.id;
      this.treatId = item.treatId;
      this.itemList = Object.assign({}, this.getItemBy(item.itemId, "id"));
      this.quantity = item.quantity;
      this.note = item.note;

      this.$nextTick(() => {
        this.price = item.price;
      });
    },

    resetIndexEdit() {
      this.$emit("update:indexEdit", -1);
    },
    resetAll() {
      this.resetIndexEdit();
      this.setDefaultDrugItem();
    },

    customTxDrugsLabel(option) {
      if (option.nameTh == option.nameEn) {
        return `${option.code ? "[" + option.code + "]-" : ""}${option.nameTh}`;
      } else {
        return `${option.code ? "[" + option.code + "]-" : ""}${option.nameTh}${
          option.nameEn ? " / " + option.nameEn : ""
        }`;
      }
    },

    checkDoctorColumn() {
      var index = this.fields.findIndex(function (event) {
        return event.key == "actionUid";
      });
      if (index >= 0) {
        this.fields[index].thClass = "d-none";
        this.fields[index].tdClass = "d-none";
      } else {
        this.fields[index].thClass = "";
        this.fields[index].tdClass = "";
      }
    },

    vReset() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$v.$reset();
        });
      });
    },
    // formatNumber(val) {
    //   return numeral(val).format("0,0");
    // },

    favItemtoggle(data) {
      this.isLoading = true;
      if (data.favorite == false) {
        this.$store
          .dispatch("moduleAppointment/updateItemFevorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else if (data.favorite == true) {
        this.$store
          .dispatch("moduleAppointment/deleteItemFevorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    toNumber(e) {
      return parseFloat(e) || 0;
    },
    handleSelectedFavNote(e) {
      if (!this.note.trim()) {
        this.note = e;
      } else {
        this.note += "\n" + e;
      }
      this.$refs.drugsNoteInput.focus();
    },
  },
};
</script>

<style>
</style>