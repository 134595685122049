<template>
  <div v-show="treatmentPlanItems.length > 0" class="w-100">
    <h6 class="small-text mb-1">
      Treatment Plan
      <b-badge href="#" variant="danger">{{ planningLength }}</b-badge>
      <b-badge href="#" variant="warning">{{ inProgressLength }}</b-badge>
      <b-badge href="#" variant="success">{{ completeLength }}</b-badge>

      <b-form-checkbox
        class="d-inline ml-1"
        style="top: -3px"
        v-model="tableVisible"
        name="check-button"
        switch
        small
        @change="toggleTableVisible"
      >
      </b-form-checkbox>
    </h6>
    <b-collapse v-model="tableVisible">
      <b-table
        class="mb-0 h-150px"
        table-class="w-100 table-condensed"
        responsive
        :items="treatmentPlanItems"
        :fields="fields"
        hover
        small
      >
        <template v-slot:cell(status)="data">
          <div v-if="data.item.isUpdating" class="text-center">
            <b-spinner small></b-spinner>
          </div>
          <b-dropdown
            v-else
            boundary="window"
            size="xs"
            :text="data.value"
            :variant="dropdownVariant(data.value)"
          >
            <b-dropdown-item
              v-for="status in statuses"
              :key="status"
              class="table-condensed"
              href="#"
              @click="updateStatus({ item: data.item, status })"
              >{{ status }}</b-dropdown-item
            >
          </b-dropdown>
        </template>
        <template v-slot:cell(button)="data">
          <b-button
            v-show="data.item.value !== 'completed'"
            :disabled="isDisableAssignButton(data.item.treatment)"
            class="show-when-hovered"
            size="xs"
            variant="primary"
            @click="assignItem(data.item)"
          >
            <i class="fa fa-plus"></i>
          </b-button>
        </template>
      </b-table>
    </b-collapse>
    <hr />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/main";

export default {
  name: "TreatmentPlanTable",
  props: ["isNhso"],
  data() {
    return {
      planList: [],
      planSelected: {},
      fields: [
        {
          key: "toothNo",
          label: "ซี่ฟัน",
          visible: true,
          formatter: this.formatToothNo,
        },
        {
          key: "diagnosis",
          label: "Dx",
          visible: true,
          formatter: value => {
            return this.getTreatDiagBy(value, "id").nameEn;
          },
        },
        {
          key: "treatment",
          label: "Tx",
          visible: true,
          formatter: value => {
            return this.getTreatBy(value, "id").nameTh;
          },
        },

        {
          key: "price",
          label: "ราคา",
          visible: true,
        },
        {
          key: "status",
          label: "",
          visible: true,
        },
        {
          key: "button",
          label: "",
          visible: true,
        },
      ],
      statuses: ["Complete", "In-progress", "Planning"],
      tableVisible: true,
    };
  },
  async mounted() {
    this.tableVisible =
      localStorage.getItem("TreatmentPlanTableVisible") !== "false";
  },
  computed: {
    ...mapGetters({
      getTreamentPlanItems: "moduleTreatmentPlan/getTreamentPlanItems",
      getToothBy: "moduleMaster/getToothBy",
      getToothSurfaceBy: "moduleMaster/getToothSurfaceBy",
      getTreatDiagBy: "moduleAppointment/getTreatDiagBy",
      getTreatBy: "moduleAppointment/getTreatBy",
      getNhsoMtreatIds: "moduleAppointment/getNhsoMtreatIds",
    }),
    treatmentPlanItems() {
      return this.getTreamentPlanItems || [];
    },
    inProgressLength() {
      return (
        this.treatmentPlanItems.filter(i => i.status === "In-progress")
          .length || null
      );
    },
    planningLength() {
      return (
        this.treatmentPlanItems.filter(i => i.status === "Planning").length ||
        null
      );
    },
    completeLength() {
      return (
        this.treatmentPlanItems.filter(i => i.status === "Complete").length ||
        null
      );
    },
  },
  methods: {
    ...mapActions({
      updateTreatmentPlanItem: "moduleTreatmentPlan/updateTreatmentPlanItem",
    }),
    assignItem(item) {
      this.$emit("clickItem", item);
    },
    dropdownVariant(status) {
      switch (status) {
        case "Planning":
          return "danger";
        case "In-progress":
          return "warning";
        case "Complete":
          return "success";
      }
    },
    async updateStatus({ item, status }) {
      this.$set(item, "isUpdating", true);
      const updateItem = { ...item };
      delete updateItem.deletedAt;
      delete updateItem.creationUid;
      delete updateItem.creationDt;
      delete updateItem.modifyUid;
      delete updateItem.modifyDt;
      delete updateItem.isUpdating;

      const result = await this.updateTreatmentPlanItem({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        data: { ...updateItem, status },
      });
      return result;
      //remove isUpdating in Vuex
    },
    formatToothNo(value, key, { toothNo, toothSurfaces }) {
      const no = this.getToothBy(toothNo, "id").name;

      let surfaces = [
        { id: 5, label: "O", order: 1 },
        { id: 3, label: "M", order: 2 },
        { id: 4, label: "D", order: 3 },
        { id: 1, label: "B", order: 4 },
        { id: 2, label: "Li", order: 5 },
      ];
      let surfacesTx =
        toothSurfaces
          ?.map(i => surfaces.find(j => j.id === i))
          .sort((a, b) => a.order - b.order)
          .map(i => i.label)
          .join("") || "";

      return no + surfacesTx;
    },
    async toggleTableVisible(e) {
      await this.$nextTick();
      localStorage.setItem("TreatmentPlanTableVisible", this.tableVisible);
    },
    isDisableAssignButton(id) {
      const isNhsoTx = this.getNhsoMtreatIds.includes(id);
      if (this.isNhso) {
        return !isNhsoTx;
      }
      return isNhsoTx;
    },
  },
};
</script>

<style>
.table-condensed {
  font-size: 0.8rem;
}
.table-condensed caption {
  padding: 0;
}
.h-150px {
  max-height: 170px;
}
</style>