<template>
  <b-modal
    modal-class="component-modal-edit-fav-note"
    ref="EditFavNoteModal"
    size="md"
    hide-header
    hide-footer
    @hidden="defaultValue"
  >
    <Draggable
      class="list-group"
      tag="ul"
      v-model="list"
      v-bind="dragOptions"
      @start="drag = true"
      @end="dragEnd"
    >
      <transition-group type="transition" :name="!drag ? 'flip-list' : null">
        <li
          class="list-group-item ws-pre"
          v-for="(element, index) in list"
          :key="element.order"
        >
          {{ element.name }}
          <b-button
            class="show-when-hovered float-right"
            size="sm"
            variant="outline-danger"
            @click="deleteList(index)"
          >
            <i class="fas fa-trash-alt"></i>
          </b-button>
        </li>
      </transition-group>
    </Draggable>
    <b-input-group class="mt-2">
      <b-form-textarea
        v-model="newList"
        trim
        placeholder="เพิ่มโน๊ตใช้ประจำ..."
        rows="3"
      ></b-form-textarea>

      <template #append>
        <b-button variant="success" @click="addList">
          <i class="fas fa-plus"></i>
        </b-button>
      </template>
    </b-input-group>

    <Loading v-if="isLoading"></Loading>
  </b-modal>
</template>

<script>
import Draggable from "vuedraggable";

import Loading from "@/components/Loading";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "EditFavNoteModal",
  components: {
    Draggable,
    Loading,
  },
  props: {
    storageName: String,
  },

  data() {
    return {
      drag: false,
      isLoading: false,
      arrayList: [],
      newList: "",
    };
  },

  computed: {
    ...mapGetters({}),
    list: {
      get() {
        return this.arrayList.map((name, index) => {
          return { name, order: index + 1 };
        });
      },
      set(val) {
        this.arrayList = val.map((i) => i.name);
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  created() {
    this.init();
  },
  watch: {},
  methods: {
    ...mapActions({}),
    init() {},
    show() {
      this.$refs["EditFavNoteModal"].show();
      let list = localStorage.getItem(this.storageName);
      this.arrayList = list ? JSON.parse(list) : [];
    },
    dragEnd() {
      this.drag = false;
      this.save();
    },
    addList() {
      if (!this.newList) return;
      this.arrayList.push(this.newList);
      this.save();
      this.newList = "";
    },
    deleteList(e) {
      this.arrayList.splice(e, 1);
      this.save();
    },
    save() {
      localStorage.setItem(this.storageName, JSON.stringify(this.arrayList));
      this.$emit("updateList", this.arrayList);
    },

    defaultValue() {
      this.arrayList = [];
      this.newList = "";
    },
  },
  mounted() {},
};
</script>

<style scoped>
.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
li:not(:hover) .show-when-hovered {
  visibility: hidden;
}
</style>