<template>
  <b-modal
    ref="UnnamedAptListModal"
    size="lg fluid"
    title="รายการนัดหมายคนไข้ใหม่ไม่มีประวัติ"
    hide-footer
    no-close-on-backdrop
  >
    <AppointmentHistoryTable :patient="patient" :mode="mode" />
  </b-modal>
</template>

<script>
import AppointmentHistoryTable from "@/components/table/AppointmentHxTable.vue";

export default {
  name: "UnnamedAptListModal",
  components: {
    AppointmentHistoryTable,
  },
  data() {
    return {
      patient: { id: 1 },
      mode: "unnamed",
    };
  },
  methods: {
    show() {
      this.$refs["UnnamedAptListModal"].show();
    },
  },
};
</script>

<style>
</style>