<template>
  <b-modal
    modal-class="component-modal-item-list"
    id="itemListModal"
    ref="itemListModal"
    size="lg"
    title="รายการยา และอุปกรณ์"
    ok-only
    ok-title="เลือก"
    scrollable
    no-close-on-backdrop
    @ok="saveSelectedItemes"
    @hidden="setDefault"
  >
    <b-form-group>
      <b-form-input
        autofocus
        v-model="filter"
        size=""
        type="search"
        id="filterInput"
        ref="filterInput"
        placeholder="ค้นหารายการยา และอุปกรณ์ เช่น Paracetamol, แปรงสีฟัน เป็นต้น"
        @input="searchFilter"
      ></b-form-input>
    </b-form-group>
    <b-table
      id="itemListTable"
      ref="itemListTable"
      hover
      selectable
      small
      sort-icon-left
      select-mode="single"
      :fields="fields"
      :items="itemList"
      :filter="filter"
      @row-clicked="onRowSelected"
    >
      <template v-slot:cell(index)="row">{{ row.index + 1 }}.</template>
      <template v-slot:cell(favourite)="row">
        <b-button
          size="sm"
          variant="outline-info"
          :pressed="row.item.favorite"
          @click="favToggle(row.item)"
        >
          <i class="far fa-star"></i>
        </b-button>
      </template>
    </b-table>
    <Loading v-if="isLoading"></Loading>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "@/components/Loading";
// import "@/theme/modal/ItemList.scss";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "nameTh",
          label: "ชื่อยา และอุปกรณ์",
          sortable: true,
        },
        {
          key: "nameEn",
          label: "Name",
          sortable: true,
        },
        {
          key: "price",
          label: "ราคา",
        },
        {
          key: "category",
          label: "ประเภท",
          sortable: true,
        },
        {
          key: "favourite",
          label: "ใช้ประจำ",
          class: "text-center",
        },
      ],
      item: null,
      filter: null,
      fav: false,
    };
  },
  computed: {
    ...mapGetters({
      getItemList: "moduleAppointment/getItemList",
      getGroupedItemList: "moduleAppointment/getGroupedItemList",
      getItemCateList: "moduleAppointment/getItemCateList",
      getItemCateBy: "moduleAppointment/getItemCateBy",
    }),
    itemList() {
      return this.getItemList.map((i) => {
        return { ...i, category: this.getItemCateBy(i.cateId, "id").nameTh };
      });
    },
  },
  methods: {
    show(data) {
      this.item = data;
      if (data != null) {
        this.setSelectRow(data);
      }
      this.$bvModal.show("itemListModal");
    },
    onRowSelected(item) {
      this.item = item;
    },

    setSelectRow(data) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          let index = this.$refs.itemListTable.filteredItems.findIndex(
            (filteredItem) => {
              filteredItem.id == data.id;
            }
          );
          if (index >= 0) {
            this.$refs.itemListTable1.selectRow(index);
          }
        });
      });
    },
    saveSelectedItemes() {
      this.$emit("selectedItem", this.item);
      this.setDefault();
    },
    favToggle(data) {
      this.isLoading = true;
      if (data.favorite == false) {
        this.$store
          .dispatch("moduleAppointment/updateItemFevorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else if (data.favorite == true) {
        this.$store
          .dispatch("moduleAppointment/deleteItemFevorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    setDefault() {
      this.filter = "";
    },
    searchFilter() {
      this.setSelectRow(this.item);
    },
  },
  mounted() {},
};
</script>

<style>
</style>