<template>
  <b-modal
    modal-class="component-modal-appointmentList"
    id="AppointmentListModal"
    ref="AppointmentListModal"
    size="lg"
    title="รายการนัดหมาย"
    ok-only
    ok-title="เลือก"
    scrollable
    @ok="saveSelectedItemes"
    @hidden="setDefault"
  >
    <b-form-group>
      <b-form-input
        autofocus
        v-model="filter"
        size=""
        type="search"
        id="filterInput"
        ref="filterInput"
        placeholder="ค้นหารายการนัดหมาย เช่น Recall 6 เดือน, ติดเครื่องมือจัดฟัน เป็นต้น"
        @input="searchFilter"
      ></b-form-input>
    </b-form-group>
    <b-table
      id="appointmentListTable"
      ref="appointmentListTable"
      hover
      selectable
      small
      sort-icon-left
      select-mode="multi"
      :fields="fields"
      :items="aptList"
      :filter="filter"
      @row-clicked="onRowSelected"
    >
      <template v-slot:cell(index)="row">{{ row.index + 1 }}.</template>
      <template v-slot:cell(cateId)="row">{{
        getTreatmentCateBy(row.item.cateId, "id").nameTh
      }}</template>
      <template v-slot:cell(favourite)="row">
        <b-button
          size="sm"
          variant="outline-info"
          :pressed="row.item.favorite"
          @click="favToggle(row.item)"
        >
          <i class="far fa-star"></i>
        </b-button>
      </template>
    </b-table>

    <Loading v-if="isLoading"></Loading>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "@/components/Loading";
// import "@/theme/modal/AppointmentList.scss";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      fields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "nameTh",
          label: "รายการนัดหมาย",
        },
        {
          key: "nameEn",
          label: "รายการนัดหมาย(Eng)",
        },
        {
          key: "cateId",
          label: "ประเภทการรักษา",
        },
        {
          key: "durationMin",
          label: "ระยะเวลา(นาที)",
          class: "text-center",
        },
        {
          key: "Favourite",
          label: "ใช้ประจำ",
          class: "text-center",
        },
      ],
      treatmentList: [],
      filter: null,
      fav: false,
    };
  },
  computed: {
    ...mapGetters({
      getTreatmentList: "moduleAppointment/getTreatmentList",
      getTreatmentCateList: "moduleAppointment/getTreatmentCateList",
      getTreatmentCateBy: "moduleAppointment/getTreatmentCateBy",
    }),
    aptList() {
      return this.getTreatmentList;
    },
  },
  methods: {
    show(data) {
      this.treatmentList = Object.assign([], data);
      if (this.treatmentList.length > 0) {
        this.setSelectRow(this.treatmentList);
      }
      this.$bvModal.show("AppointmentListModal");
    },
    onRowSelected(item) {
      let index = this.treatmentList.findIndex(function (event) {
        return event.id == item.id;
      });
      if (index >= 0) {
        this.treatmentList.splice(index, 1);
      } else {
        this.treatmentList.push(item);
      }
      this.setSelectRow(this.treatmentList);
    },
    setSelectRow(data) {
      this.$nextTick()
        .then(() => this.$nextTick())
        .then(() => {
          data.forEach((item) => {
            let index = this.$refs.appointmentListTable.filteredItems.findIndex(
              function (filteredItem) {
                return filteredItem.id == item.id;
              }
            );
            if (index >= 0) {
              this.$refs.appointmentListTable.selectRow(index);
            }
          });
        });
    },
    saveSelectedItemes() {
      this.$emit("selectedTreatment", Object.assign([], this.treatmentList));
      this.setDefault();
    },
    favToggle(data) {
      this.isLoading = true;
      if (data.favorite == false) {
        this.$store
          .dispatch("moduleAppointment/updateTreatmentFavorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else if (data.favorite == true) {
        this.$store
          .dispatch("moduleAppointment/deleteTreatmentFavorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    setDefault() {
      this.filter = "";
    },
    searchFilter() {
      this.setSelectRow(this.treatmentList);
    },
  },
  mounted() {},
};
</script>

<style>
</style>